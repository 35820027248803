import { Checkbox, Modal } from "flowbite-react";
import { YMButton, YMLoader, YMTable } from "../../../ym-components";
import React, { useEffect, useState } from "react";
import { ProjectDimension } from "../../../app_client";
import { ProjectApi, useProjectApi } from "../State";
import { useMutation, useQuery } from "@tanstack/react-query";

export const DimensionTagPatchModal = ({
  dimension,
  show,
  onClose,
  onPatched,
}: {
  dimension: ProjectDimension;
  show: boolean;
  onClose: () => void;
  onPatched: () => void;
}) => {
  const projectApi = useProjectApi() as ProjectApi;
  const [selectedPatches, setSelectedPatches] = useState([] as string[]);

  const apply = useMutation(
    () => {
      return projectApi.applyTagPatch(dimension.project_id, selectedPatches);
    },
    {
      onSuccess: () => {
        onPatched();
      },
    }
  );

  const patchs = useQuery({
    queryKey: ["dimension-patches", dimension.id],
    queryFn: () => {
      return projectApi.getTagPatches(dimension.project_id, dimension.name);
    },
    initialData: [],
    staleTime: 0, // Disable cache
  });

  useEffect(() => {
    setSelectedPatches(patchs.data.map((patch) => patch.id as string));
  }, [patchs.data]);

  return (
    <>
      <Modal root={document.body} size="5xl" show={show} onClose={onClose}>
        <Modal.Header>
          <div className={"font-bold text-indigo-800 title"}>
            {" "}
            Correctifs disponibles pour {dimension.name}
          </div>
        </Modal.Header>
        <Modal.Body>
          <YMLoader
            message={
              apply.isLoading
                ? "Correctifs en cours"
                : "Recherche des correctifs en cours"
            }
            loading={patchs.isFetching || apply.isLoading}
          >
            {patchs.data.length > 0 ? (
              <YMTable
                header={
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      Tag
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Correctif
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Sélectionné
                    </th>
                  </tr>
                }
                rows={patchs.data.map((patch) => (
                  <tr key={patch.id}>
                    <td className="px-6 pt-1 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{patch.value}</div>
                    </td>
                    <td className="px-6 pt-1 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{patch.patch}</div>
                    </td>
                    <td className="px-6 pt-1 whitespace-nowrap">
                      <Checkbox
                        checked={selectedPatches.includes(patch.id as string)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPatches((prev) => [
                              ...prev,
                              patch.id as string,
                            ]);
                          } else {
                            setSelectedPatches((prev) =>
                              prev.filter((id) => id !== patch.id)
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              />
            ) : (
              <div className="text-center text-sm text-gray-500">
                Aucun correctif disponible.
              </div>
            )}
          </YMLoader>
        </Modal.Body>
        <Modal.Footer>
          <YMButton
            text={"Appliquer les correctifs"}
            onClick={() => {
              apply.mutate();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
