/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum JobType {
    SUMMARIZATION = 'summarization',
    TEXT_CLASSIFICATION = 'text-classification',
    TOKEN_CLASSIFICATION = 'token-classification',
    PATTERN_MATCHING = 'pattern_matching',
    NER_TAGGING = 'ner_tagging',
    DOCUMENT_PARSING = 'document_parsing',
    SPACY_COMPLETE_ANALYSE = 'spacy_complete_analyse',
    VERBATIMS_SEGMENTATION = 'verbatims_segmentation',
    TOPIC_MODELING = 'topic_modeling',
    MANUAL = 'manual',
    DOC_SOURCE_METADATA = 'doc_source_metadata',
    FEWSHOT_TAGGING = 'fewshot_tagging',
}
