import { SiDatabricks } from "react-icons/si";
import { YMButton, YMCard, YMInput } from "../../../ym-components";
import { Button } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import React, { useRef } from "react";
import { ProjectFewShotLearningModels } from "../Model";
import { ModalConfiguration } from "./ModalConfiguration";

export const NewModelForm = (props: ProjectFewShotLearningModels) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetFile = () => {
    if (inputRef !== null && inputRef.current !== null) {
      inputRef.current.value = "";
    }
  };

  return (
    <YMCard>
      <ModalConfiguration {...props} />
      <div className="flex flex-col w-100">
        <div className="format">
          <h2 className="text-indigo-900">
            <SiDatabricks className="inline" /> Few Shot Learning Models
          </h2>
          <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
            Blabla...
          </p>
        </div>
        <div className="format mt-3 pt-3 border-t w-100 border-gray-200">
          <h4 className={"text-indigo-800"}> Créer un nouveau modèle </h4>
          <form className="flex flex-col gap-4">
            <div className={"flex flex gap-4"}>
              <div className={"w-1/2"}>
                <YMInput
                  value={props.state.modelName}
                  label={"Nom du modèle"}
                  onChange={(e) =>
                    props.dispatch({ type: "NAME_SET", payload: e })
                  }
                />
              </div>
            </div>
            <div className={"flex flex-col gap-2"}>
              <h4 className={"text-indigo-800"}> Données d'entraînement </h4>
              <span className={"font-normal text-md"}>
                {" "}
                sélectionnez un fichier au format xlsx{" "}
              </span>
              <div className={"flex items-end gap-2"}>
                <input
                  multiple={true}
                  ref={inputRef}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file"
                  onChange={(e) => props.actions.selectFile(e.target.files)}
                />
                <Button size="xs" color="gray" onClick={resetFile}>
                  <HiTrash className="h-4 w-4" />
                </Button>
              </div>
            </div>
            <div className={"w-60"}>
              <YMButton
                text={"Créer un nouveau modèle"}
                onClick={() => {
                  props.actions.onOpenCreateModel();
                  resetFile();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </YMCard>
  );
};
