import {
  PatterOccurrenceWithTags,
  ProjectView,
  TagType,
  VerbatimTag,
} from "../../../app_client";
import { YMNavBar } from "../components/components";
import { YMLoader, YMTable } from "../../../ym-components";
import { VerbatimTagBadge } from "../components/verbatims/VerbatimTag";
import AddATagMenu from "../components/verbatims/AddTagMenu";
import { Tooltip } from "flowbite-react";
import { HiSearch } from "react-icons/hi";
import React, { useState } from "react";
import { getFrenchPOSName, LexiquePatterns, usePatternQuery } from "./State";
import { useProject } from "../State";
import { POS } from "../utils";
import { VerbatimsModal } from "../components/verbatims/VerbatimModal";

const LexiconTable = (props: {
  searchParams: {
    posPatterns: typeof LexiquePatterns;
    term: string;
    minMaxOccurrence: [number, number];
    enableParsing: boolean;
    semanticSearch: boolean;
  };
}) => {
  const { _project, projectId } = useProject();

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { _ProjectPatterns, handleDeleteTag, handleTagAPattern } =
    usePatternQuery({
      projectId: projectId,
      searchParams: {
        ...props.searchParams,
        page: currentPage,
      },
    });

  const handleTagAPatternAndRefreshProject = async (
    pattern: PatterOccurrenceWithTags,
    dimension: string,
    classname: string
  ) => {
    let dimension_o = _project.data.dimensions.find((d) => d.name == dimension);
    const color = dimension_o?.color || "indigo";
    await handleTagAPattern(pattern, dimension, classname, color);
    if (!dimension_o) {
      await _project.refetch(); // creating new dimension, reload project
    }
  };

  const [displayVerbatimModal, setDisplayVerbatimModal] = useState(false);
  const [verbatimLemmaFilter, setVerbatimLemmaFilter] = useState("");
  const [verbatimDimensionFilter, setVerbatimDimensionFilter] = useState("");
  const [verbatimClassFilter, setVerbatimClassFilter] = useState("");
  const [verbatimPOSFilter, setVerbatimPOSFilter] = useState("");

  const handleSearchVerbatims = (
    lemma: string,
    pos: POS[],
    tags: VerbatimTag[]
  ) => {
    const posToLexiqueLabel = (pos: POS) => {
      if (pos === "ADJ") return "Adjectifs";
      if (pos === "VERB") return "Verbes";
      if (pos === "NOUN") return "Noms";
    };
    setVerbatimLemmaFilter(lemma);
    setVerbatimDimensionFilter("");
    setVerbatimClassFilter("");
    setVerbatimPOSFilter("");
    setDisplayVerbatimModal(true);
  };

  return (
    <>
      <YMLoader
        message={"Chargement des résultats"}
        loading={_ProjectPatterns.isLoading}
      >
        <div className={"flex gap-2 mb-2 items-end"}>
          {(_ProjectPatterns.data?.page_count || 0) > 1 && (
            <>
              <YMNavBar
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={_ProjectPatterns.data?.page_count || 1}
                previousLabel={"Précédent"}
                nextLabel={"Suivant"}
              />
              <div className={"text-indigo-900 text-sm"}>
                {" "}
                {_ProjectPatterns.data?.total} résultats /{" "}
                {_ProjectPatterns.data?.page_count} pages
              </div>
            </>
          )}
          <div className={"grow"}></div>
          {_ProjectPatterns.isFetching && (
            <div className={"text-gray-500 text-sm justify-self-end "}>
              {" "}
              mise à jours des données...{" "}
            </div>
          )}
        </div>

        <div className="relative overflow-x-auto rounded-lg border border-indigo-200 bg-white shadow-lg">
          <YMTable
            header={
              <tr>
                <th scope="col" className="px-6 py-3">
                  Forme
                </th>
                <th scope="col" className="px-6 py-3">
                  Texte
                </th>
                <th scope="col" className="px-6 py-3">
                  Occurrences
                </th>
                {props.searchParams.semanticSearch && (
                  <th scope="col" className="px-6 py-3">
                    Distance
                  </th>
                )}
                <th scope="col" className="px-6 py-3">
                  Champ Lexical
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only"> x </span>
                </th>
              </tr>
            }
            rows={(_ProjectPatterns.data?.data || []).map((p, i) => (
              <tr
                key={p.pos_pattern + p.lemma + i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
              >
                <td
                  scope="row"
                  className="px-6 py-4 text-indigo-900 hover:text-indigo-900 dark:text-white"
                >
                  {p.pos_pattern.map((piece) => (
                    <span key={piece}>{getFrenchPOSName(piece)} </span>
                  ))}
                </td>
                <td
                  scope="row"
                  className="px-6 text-md font-bold py-4 text-indigo-800 hover:text-indigo-900 dark:text-white"
                >
                  <div style={{ width: "fit-content" }}>{p.lemma}</div>
                </td>
                <td
                  scope="row"
                  className="px-6 py-4 text-gray-900 max-w-[48px] hover:text-indigo-900 dark:text-white"
                >
                  {p.occurrence}
                </td>
                {props.searchParams.semanticSearch && (
                  <td
                    scope="row"
                    className="px-6 py-4 text-gray-900 max-w-[48px] hover:text-indigo-900 dark:text-white"
                  >
                    {p.semantic_distance_score
                      ? p.semantic_distance_score.toFixed(2)
                      : "-"}
                  </td>
                )}
                <td>
                  <div className={"flex flex-wrap gap-2"}>
                    {p.tags
                      ?.filter((t) => t.dimension != "lexique")
                      .filter((t) => t.dimension != "ngrammes")
                      .filter((t) => t.dimension != "blacklist_frequencygraph")
                      .map((t) => (
                        <VerbatimTagBadge
                          key={t.id}
                          tag={t}
                          project={_project.data as ProjectView}
                          onDelete={() => handleDeleteTag(p, t)}
                        />
                      ))}
                    <div>
                      <AddATagMenu
                        project={_project.data as ProjectView}
                        handleAddTag={(dim, classname) =>
                          handleTagAPatternAndRefreshProject(p, dim, classname)
                        }
                        tagType={TagType.TOKEN_CLASSIFICATION}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <Tooltip content={"Rechercher les verbatims"}>
                    <div
                      onClick={() =>
                        handleSearchVerbatims(
                          p.lemma,
                          p.pos_pattern,
                          p.tags || []
                        )
                      }
                      className={
                        "cursor-pointer flex items-center justify-center bg-indigo-200 text-indigo-800 hover:bg-indigo-800 hover:text-indigo-100 rounded h-8 w-8"
                      }
                    >
                      <HiSearch className={""} />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          />
        </div>
        {(_ProjectPatterns.data?.page_count || 0) > 1 && (
          <div className={"flex gap-2 mb-2 items-end"}>
            <YMNavBar
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={_ProjectPatterns.data?.page_count || 1}
              previousLabel={"Précédent"}
              nextLabel={"Suivant"}
            />
            <div className={"text-indigo-900 text-sm"}>
              {" "}
              {_ProjectPatterns.data?.total} résultats /{" "}
              {_ProjectPatterns.data?.page_count} pages
            </div>
          </div>
        )}
      </YMLoader>
      <VerbatimsModal
        show={displayVerbatimModal}
        onClose={() => setDisplayVerbatimModal(false)}
        lemmasFilter={verbatimLemmaFilter}
        dimensionFilter={verbatimDimensionFilter}
        classNameFilter={verbatimClassFilter}
        posFilter={verbatimPOSFilter}
      />
    </>
  );
};

export default LexiconTable