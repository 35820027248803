/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ChartType {
    BAR = 'bar',
    TREEMAP = 'treemap',
    SUNBURST = 'sunburst',
    ICICLE = 'icicle',
    POLAR = 'polar',
    PIE = 'pie',
}
