import React, {useEffect, useState} from "react";
import {useProject} from "../../State";
import {YMIcons, YMLightButton, YMMultiSelect, YMSelect, YMToggle, YMTutorialTips,} from "../../../../ym-components";
import {downloadPngImage} from "./download";
import {BiMinus, BiPlus} from "react-icons/bi";
import {GoSettings} from "react-icons/go";
import {ProjectDimensionStatus} from "../../../../app_client";
import {FrequencyGraphCanvas, GraphUpdateData} from "./FrequencyGraphCanvas";
import {UseQueryResult} from "@tanstack/react-query";
import {useDebounce} from "usehooks-ts";

export const FrequencyGraphPage = (props: any) => {
    const {_project} = useProject();
    const [nbCluster, setNbCluster] = useState(8);
    const [nbNodes, setNbNodes] = useState(80);
    const [computedCluster, setComputedCluster] = useState(8);
    const [excludeOrphans, setExcludeOrphans] = useState(false);
    const [lemmas, setLemmas] = useState([] as string[]);
    const [blacklist, setBlacklist] = useState([] as string[]);
    const [query, setQuery] = useState<UseQueryResult<any, unknown>>();
    const [theme, setTheme] = useState("youmean" as string);

    const [graphDimensions, setGraphDimensions] = useState([
        "lexique",
    ] as string[]);

    const nbNodesDebounced = useDebounce(nbNodes, 1500);

    useEffect(() => {
        // At the beginning of a project, the graph is empty, so we wait a bit before trying again
        const isLexiqueGraph =
            graphDimensions.length === 1 && graphDimensions[0] === "lexique";
        const lexiqueDim = _project.data?.dimensions.find(
            (d) => d.name === "lexique"
        );
        const LexiqueIsReady =
            lexiqueDim && lexiqueDim.status == ProjectDimensionStatus.TAGGED;
        const graphIsEmpty = query && query.data?.empty;
        if (graphIsEmpty && isLexiqueGraph && LexiqueIsReady) {
            const interval = setInterval(() => {
                query.refetch();
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [_project.data?.dimensions, query, graphDimensions]);

    return (
        <>
            <div className={"flex justify-between items-end"}>
                <div className={"flex flex-row gap-1"}>
                    <div>
                        <h3 className={"text-indigo-900 text-2xl font-bold"}>
                            {" "}
                            Graphe sémantique{" "}
                        </h3>
                    </div>
                    <div className={"text-indigo-900 text-sm w-100 pr-20"}>
                        <YMTutorialTips
                            content={
                                <p>
                                    Ce graphe donne les principaux concepts de votre corpus et les
                                    liens qui les associent.
                                    <br/> Plus un concept est important, plus sa taille est
                                    grande.
                                    <br/> Plus les concepts sont reliés par des liens épais, plus
                                    ils sont souvent cités ensemble.
                                    <br/> Les différentes couleurs indiquent des champs
                                    d’expression (clusters) présents dans le corpus.
                                </p>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className={"w-100 flex justify-between mt-1"}>
                <div className="pb-3">
                    <p className="text-indigo-900 text-sm">
                        Déplacer les mots pour optimiser leur position.
                        <br/>
                        Cliquer sur le mot d'un groupe pour consulter les principales
                        phrases.
                    </p>
                </div>
                <div>
                    <YMLightButton
                        onClick={downloadPngImage}
                        icon={<YMIcons.Download className={"h-4 w-4 mr-2"}/>}
                        label={"Exporter le Graph en .png"}
                    />
                </div>
            </div>
            <FrequencyGraphCanvas
                projectId={_project.data.id}
                height={800}
                dimensions={graphDimensions}
                nbCluster={nbCluster}
                excludeOrphans={excludeOrphans}
                nbNodes={nbNodesDebounced}
                blacklist={blacklist}
                classes={[]}
                theme={theme}
                onGraphUpdate={({
                                    computedNbClusters,
                                    lemmas,
                                    query,
                                }: GraphUpdateData) => {
                    setComputedCluster(computedNbClusters);
                    setLemmas(lemmas);
                    setQuery(query);
                }}
            />
            <div className="pb-4 text-left -mt-20">
                <div className={"flex gap-2"}>
                    <div>
                        <GoSettings className={"h-6 w-6 text-indigo-900"}/>
                    </div>
                    <h3 className={"text-indigo-900 text-xl font-bold"}>
                        {" "}
                        Configurer le graphe{" "}
                    </h3>
                </div>
                <div className="flex flex-row space-x-5">
                    <div className={"pr-5 border-r border-gray-400"}>
                        <div className="flex space-x-5 items-center">
                            <div className="flex items-center">
                                <div className="mt-5 font-bold text-indigo-900 text-xs">
                                    Nombre de noeuds souhaité
                                </div>
                            </div>
                            <div className="flex flex-row space-x-3 items-center mt-5">
                                <div
                                    onClick={() =>
                                        setNbNodes(nbNodes - 20 < 40 ? 40 : nbNodes - 20)
                                    }
                                    className="text-indigo-900 bg-white-500 border border-indigo-300 hover:bg-indigo-200 cursor-pointer h-5 w-5 rounded-full flex items-center justify-center"
                                >
                                    <BiMinus className="h-4 w-4"/>
                                </div>
                                <div className="font-bold text-indigo-500"> {nbNodes} </div>
                                <div
                                    onClick={() =>
                                        setNbNodes(nbNodes + 20 > 400 ? 400 : nbNodes + 20)
                                    }
                                    className="text-indigo-900 bg-white-500 border border-indigo-300 hover:bg-indigo-200 cursor-pointer h-5 w-5 rounded-full flex items-center justify-center"
                                >
                                    <BiPlus className="h-4 w-4"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-5 items-center">
                            <div className="flex items-center">
                                <div className="mt-5 font-bold text-indigo-900 text-xs">
                                    Nombre de clusters souhaité
                                </div>
                            </div>
                            <div className="flex flex-row space-x-3 items-center mt-5">
                                <div
                                    onClick={() =>
                                        setNbCluster(nbCluster - 1 < 1 ? 1 : nbCluster - 1)
                                    }
                                    className="text-indigo-900 bg-white-500 border border-indigo-300 hover:bg-indigo-200 cursor-pointer h-5 w-5 rounded-full flex items-center justify-center"
                                >
                                    <BiMinus className="h-4 w-4"/>
                                </div>
                                <div className="font-bold text-indigo-500"> {nbCluster} </div>
                                <div
                                    onClick={() =>
                                        setNbCluster(nbCluster + 1 > 12 ? 12 : nbCluster + 1)
                                    }
                                    className="text-indigo-900 bg-white-500 border border-indigo-300 hover:bg-indigo-200 cursor-pointer h-5 w-5 rounded-full flex items-center justify-center"
                                >
                                    <BiPlus className="h-4 w-4"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-5 items-center mt-1">
                            <div className="flex items-center">
                                <div className="text-gray-600 text-sm">
                                    <b>{computedCluster}</b> clusters calculés
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center -ml-2 mt-4">
                            <div className="mt-3 mb px-2">
                                <YMToggle
                                    label={"Ignorer les orphelins"}
                                    value={excludeOrphans}
                                    onChange={setExcludeOrphans}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className={""}>
                                <div className="flex items-center">
                                    <div className="mt-5 font-bold text-indigo-900 text-xs">
                                        Mots black listés
                                    </div>
                                </div>
                                <YMMultiSelect
                                    wordList={lemmas}
                                    values={blacklist}
                                    onListChange={setBlacklist}
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{width: 750}}>
                                <div className="flex items-center">
                                    <div className="mt-5 font-bold text-indigo-900 text-xs">
                                        Dimensions
                                    </div>
                                </div>
                                <YMMultiSelect
                                    wordList={_project.data.dimensions.map((d) => d.name)}
                                    values={graphDimensions}
                                    onListChange={setGraphDimensions}
                                />
                            </div>
                        </div>
                        <div className={"mt-5"}>
                            <div style={{width: 750}}>
                                 <YMSelect
                                    label={"Couleurs"}
                                    value={theme}
                                    options={[{id: "youmean", name: "youmean"}, {id: "g2a", name: "g2a"}]}
                                    onChange={setTheme}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"h-96"}></div>
        </>
    );
};
