/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InfraServiceStatus {
    UP = 'up',
    DOWN = 'down',
}
