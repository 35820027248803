import React, {useContext, useEffect} from "react";
import {Badge, Modal} from "flowbite-react";
import {YMButton, YMTinyButton} from "../../../ym-components";
import {CiFilter} from "react-icons/ci";
import {ProjectFilterContext} from "../ProjectFilterContext";
import {DimensionFilter, ProjectFilter, useProject} from "../State";


// api to retrieve the project conditions from the local storage

export const getProjectConditionsFromLocalStorage = (projectId: string): ProjectFilter => {
    const conditions = localStorage.getItem("filterConditions-" + projectId);
    if (conditions) {
        return JSON.parse(conditions) as ProjectFilter;
    }
    return [];
}

const setProjectConditionsToLocalStorage = (projectId: string, conditions: ProjectFilter) => {
    localStorage.setItem("filterConditions-" + projectId, JSON.stringify(conditions));
}

export const ButtonProjectFilterModal = () => {

    const { projectId } = useProject();
    const filter = useContext(ProjectFilterContext);
    const [showModal, setShowModal] = React.useState(false);
    const [filterConditions, setFilterConditions] = React.useState([...filter.conditions] as ProjectFilter);

    useEffect(() => {setFilterConditions([...filter.conditions])}, [filter.conditions]);

    // retrieve the filter conditions from the local storage
    useEffect(() => {
        setFilterConditions(getProjectConditionsFromLocalStorage(projectId));
    }, [projectId]);


    const toggle = (condition: DimensionFilter) => () => {
        condition.active = !condition.active;
        setFilterConditions([...filterConditions])
        setProjectConditionsToLocalStorage(projectId, filterConditions)
    }

    const validate = () => {
        filter.setConditions(filterConditions)
    }

    return (
        <>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <Modal.Header>Filtre du project</Modal.Header>
                <Modal.Body>
                    <p className={"text-indigo-900 text-sm"}>
                        Activer les filtres pour afficher les données qui vous intéressent. <br/>
                        Par exemple si le filtrer "Thème : agriculture" est activé, seules les données liées à
                        l'agriculture seront affichées.<br/>
                        Plusieurs filtres peuvent être combinés, par exemple "Thème : agriculture" et "Pays :
                        France", <br/> alors seules les données liées à l'agriculture en France seront affichées.
                    </p>
                    <div>

                        {Array.from(new Set(filterConditions.map(c => c.dimension))).map(dim =>

                            <div id={dim}>
                                <h3 className={"text-indigo-800 font-bold mt-4 capitalize"}>{dim}</h3>
                                <div className={"flex flex-wrap gap-2"}>
                                    {filterConditions.filter(c => c.dimension === dim).map((condition, index) => <
                                        Badge id={condition.dimension + condition.label} className={"cursor-pointer"}
                                              onClick={toggle(condition)}
                                              color={condition.active ? "green" : "indigo"}>{condition.label} </Badge>)}
                                </div>
                            </div>
                        )}


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <YMButton
                        text={"Valider"}
                        onClick={() => {
                            validate();
                            setShowModal(false)
                        }}
                    />
                </Modal.Footer>
            </Modal>
            <YMTinyButton
                onClick={() => setShowModal(true)}
                icon={<CiFilter className={"h-4 w-4 text-white mr-2"}/>}
                text={"Configurer les filtres "}
            />
        </>
    );
};
