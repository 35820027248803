/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExportStatus {
    PENDING = 'PENDING',
    DONE = 'DONE',
    FAILED = 'FAILED',
}
