/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InfraService {
    PSQL = 'psql',
    RABBITMQ = 'rabbitmq',
    REDIS = 'redis',
}
