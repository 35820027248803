/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ModelType {
    FEWSHOOT_TAGGING = 'FEWSHOOT_TAGGING',
    PATTERN_MATCHING = 'PATTERN_MATCHING',
}
