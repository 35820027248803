import {
  JobType,
  ProjectDimension,
  ProjectDimensionStatus,
  TagType,
  VerbatimPaginated,
  VerbatimViewWithTagTokens,
} from "../../../app_client";

export interface FewShotLearningComponentState {
  projectId?: string;
  projectTotalVerbatims?: number;
  selectedDimension?: ProjectDimension;
  selectedDimensionName?: string;
  selectedDimensionClasses: string[];
  selectedDimensionTaggingProgress?: ProjectDimensionStatus;
  dimensions: ProjectDimension[];
  dimensionsOptions: { id: string; name: string }[];
  activeClasses: string[];
  verbatims?: VerbatimPaginated;
  activeVerbatim?: VerbatimViewWithTagTokens;
  modalNewClass: boolean;
  modalNewModel: boolean;
}

type projectSet = {
  type: "PROJECT_SET";
  payload: { projectId: string; projectTotalVerbatims: number; dimensions: ProjectDimension[] };
};
type dimensionSelected = {
  type: "DIMENSION_SELECTED";
  payload: { dimensionName: string };
};
type verbatimsFetched = {
  type: "VERBATIMS_FETCHED";
  payload: VerbatimPaginated;
};
type verbatimSelected = {
  type: "VERBATIM_SELECTED";
  payload: { verbatimId: string };
};
type keyPressed = {
  type: "KEY_PRESSED";
  payload: { key: string };
};
type classToggled = {
  type: "CLASS_TOGGLED";
  payload: { className: string };
};
type verbatimTagged = {
  type: "VERBATIM_TAGGED";
};
type classAdded = {
    type: "CLASS_ADDED";
    payload: string
}
type modalNewClassClosed = {
  type: "MODAL_NEW_CLASS_CLOSED";
}
type dimensionAnalysisStatsUpdated = {
  type: "DIMENSION_ANALYSIS_STATS_UPDATED";
  payload: {dimension: string, p: Number}[]
}
type newModelModalToggled = {
    type: "NEW_MODEL_MODAL_TOGGLED";
}
type modelCreatedOrUpdate = {
    type: "MODEL_CREATED_OR_UPDATED";
}
export type FewShotLearningComponentAction =
  | projectSet
  | dimensionSelected
  | verbatimsFetched
  | verbatimSelected
  | modalNewClassClosed
  | classAdded
  | classToggled
  | keyPressed
  | verbatimTagged
  | dimensionAnalysisStatsUpdated
  | newModelModalToggled
  | modelCreatedOrUpdate;

export type FewShotLearningComponentDispatch = (
  action: FewShotLearningComponentAction
) => void;


export const FewShotLearningReducer = (
  state: FewShotLearningComponentState,
  action: FewShotLearningComponentAction
) => {
  if (action.type === "PROJECT_SET") {
    let dimensions = fewShotCompatibleDimensions(action.payload.dimensions);
    return {
      ...state,
      projectId: action.payload.projectId,
      dimensions,
      projectTotalVerbatims: action.payload.projectTotalVerbatims,
      dimensionsOptions: asDimensionOptions(dimensions),
    };
  }

  if (action.type === "DIMENSION_SELECTED") {
    let selectedDimension = findDimension(
      state.dimensions,
      action.payload.dimensionName
    );
    return {
      ...state,
      verbatims: undefined,
      selectedDimension,
      selectedDimensionName: action.payload.dimensionName,
      selectedDimensionClasses: selectedDimension
        ? selectedDimension.class_names || []
        : [],
    };
  }

  if (action.type === "CLASS_ADDED") {
    return {
      ...state,
      selectedDimensionClasses: state.selectedDimensionClasses.concat([action.payload]),
      modalNewClass: false
    };
  }

  if (action.type === "VERBATIMS_FETCHED") {
    let nextState = {
      ...state,
      verbatims: action.payload,
    };
    if (action.payload.data.length > 0) {
      return selectVerbatim(nextState, action.payload.data[0].id);
    }

    return nextState;
  }

  if (action.type === "VERBATIM_SELECTED") {
    return selectVerbatim(state, action.payload.verbatimId);
  }

  if (action.type === "KEY_PRESSED") {
    let keyboardShortcut = "azertyuiop";
    let activeClasses = [] as string[];

    if (action.payload.key === "x") {
      activeClasses = ["out"];
    }

    if (action.payload.key === "+") {
        return {
            ...state,
            modalNewClass: true
        }
    }

    if (keyboardShortcut.includes(action.payload.key)) {
      let classSelected =
        state.selectedDimensionClasses[
          keyboardShortcut.indexOf(action.payload.key)
        ];
      activeClasses = toggleClass(
        state.activeClasses,
        classSelected
      );
    }

    return {
      ...state,
      activeClasses,
    };
  }

  if (action.type === "VERBATIM_TAGGED") {
    return selectNextVerbatim({
      ...state,
      verbatims: removeVerbatimFrom(state.verbatims, state.activeVerbatim!.id),
    });
  }

  if (action.type === "CLASS_TOGGLED") {
    return {
      ...state,
      activeClasses: toggleClass(state.activeClasses, action.payload.className),
    };
  }

  if (action.type == "MODAL_NEW_CLASS_CLOSED") {
    return {
      ...state,
      modalNewClass: false
    }
  }

  if (action.type == "DIMENSION_ANALYSIS_STATS_UPDATED") {

    const currentDimensionProgress = action.payload.find(d => d.dimension === state.selectedDimensionName)
    if (currentDimensionProgress) {
      return {
        ...state,
        selectedDimensionTaggingProgress: currentDimensionProgress.p < 100 ? ProjectDimensionStatus.WAITING : ProjectDimensionStatus.TAGGED
      }
    } else {
        return {
            ...state,
            selectedDimensionTaggingProgress: undefined
        }
    }

  }

  if (action.type == "NEW_MODEL_MODAL_TOGGLED") {
    return {
      ...state,
      modalNewModel: !state.modalNewModel
    }
  }

  if (action.type == "MODEL_CREATED_OR_UPDATED") {
    return {
      ...state,
      selectedDimensionTaggingProgress: ProjectDimensionStatus.WAITING,
      modalNewModel: false
    }
  }

  return state;
};
const selectVerbatim = (
  state: FewShotLearningComponentState,
  verbatimId: string
) => ({
  ...state,
  activeVerbatim: state.verbatims?.data
    ? findVerbatim(state.verbatims!, verbatimId)
    : undefined,
  activeClasses: activeClassesOf(
    findVerbatim(state.verbatims!, verbatimId),
    state.selectedDimensionName
  ),
});
const selectNextVerbatim = (state: FewShotLearningComponentState) => {
  let nextVerbatim = state.verbatims?.data[0];
  if (!nextVerbatim) return state;
  return selectVerbatim(state, nextVerbatim.id);
};
const removeVerbatimFrom = (
  verbatims: VerbatimPaginated | undefined,
  verbatimId: string
) => {
  if (!verbatims) return undefined;
  return {
    ...verbatims,
    data: verbatims.data.filter((v) => v.id !== verbatimId),
    total: verbatims.total - 1,
  };
};
const findVerbatim = (verbatims: VerbatimPaginated, verbatimId: string) => {
  return verbatims.data.find((v) => v.id === verbatimId);
};
const activeClassesOf = (
  verbatim: VerbatimViewWithTagTokens | undefined,
  dimension: string | undefined
) => {
  if (!verbatim || !dimension) return [];
  return (verbatim.tags || [])
    .filter((t) => t.dimension === dimension)
    .map((t) => t.label);
};
const toggleClass = (classes: string[], className: string) => {
  if (classes.includes(className)) {
    return classes.filter((c) => c !== className);
  } else {
    return classes.concat([className]);
  }
};
const fewShotCompatibleDimensions = (dimensions: ProjectDimension[]) => {
  return dimensions
    .filter((d) => d.tag_type == TagType.TEXT_CLASSIFICATION)
    .filter((d) => d.job_type !== JobType.TOPIC_MODELING)
    .map((d) => ({
      ...d,
      class_names: (d.class_names || [])
        .sort()
        .filter((c) => c !== "out")
        .concat(["out"]),
    }));
};
const findDimension = (dimensions: ProjectDimension[], name: string) => {
  return dimensions.find((d) => d.name === name);
};
const asDimensionOptions = (dimensions: ProjectDimension[]) => {
  return [
    {
      id: "",
      name: "-- Sélectionnez une dimension --",
    },
  ].concat(dimensions.map((d) => ({ id: d.name, name: d.name })));
};
export const initialComponentState: FewShotLearningComponentState = {
  dimensions: [],
  dimensionsOptions: [
    {
      id: "",
      name: "-- Sélectionnez une dimension --",
    },
  ],
  selectedDimensionName: "",
  selectedDimensionClasses: [],
  activeClasses: [],
  modalNewClass: false,
  modalNewModel: false,
};

