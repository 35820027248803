import React, { useEffect, useState } from "react";
import {
  AnnotationLearningScope,
  PatternMatchingManualAnnotationProjection,
} from "../../app_client";
import { useProject, useProjectApi } from "./State";
import { YMLoader, YMTable, YMTutorialTips } from "../../ym-components";
import { Badge } from "flowbite-react";
import { VscDiffAdded, VscDiffRemoved } from "react-icons/vsc";

type Correction = PatternMatchingManualAnnotationProjection;

export default function ProjectManualAnnotations(props: any) {
  const [corrections, setCorrections] = useState(
    [] as "loading" | Correction[]
  );
  const { projectId, _project } = useProject();
  const api = useProjectApi();

  // load corrections
  useEffect(() => {
    if (api && projectId) {
      api.getManualCorrection(projectId).then((c: Correction[]) => {
        setCorrections(c);
      });
    }
  }, [api, projectId]);

  const handleSetAnnotationSpecific = async (annotationId: string) => {
    if (api && corrections != "loading") {
      await api.updateManualCorrection(
        annotationId,
        AnnotationLearningScope.PROJECT_SPECIFIC
      );
      setCorrections(
        corrections.map((c) => {
          if (c.id === annotationId) {
            return {
              ...c,
              learning_scope: AnnotationLearningScope.PROJECT_SPECIFIC,
            };
          }
          return c;
        })
      );
    }
  };

  const handleLearnAnnotation = async (annotationId: string) => {
    if (api && corrections != "loading") {
      await api.updateManualCorrection(
        annotationId,
        AnnotationLearningScope.MODEL_LEARNING
      );
      setCorrections(
        corrections.map((c) => {
          if (c.id === annotationId) {
            return {
              ...c,
              learning_scope: AnnotationLearningScope.MODEL_LEARNING,
            };
          }
          return c;
        })
      );
    }
  };

  return (
    <>
      <YMLoader
        loading={corrections === "loading"}
        message={
          "Chargement du récapitulatif des actions de " + _project.data.name
        }
      >
        <CorrectionTable
          title="Récapitulatif des actions"
          corrections={corrections as Correction[]}
          handleSetAnnotationSpecific={handleSetAnnotationSpecific}
          handleLearnAnnotation={handleLearnAnnotation}
        />
      </YMLoader>
    </>
  );
}

function CorrectionTable(props: {
  title: string;
  corrections: Correction[];
  handleSetAnnotationSpecific: (annotationId: string) => void;
  handleLearnAnnotation: (annotationId: string) => void;
}) {
  const {
    title,
    corrections,
    handleSetAnnotationSpecific,
    handleLearnAnnotation,
  } = props;

  const rows = corrections.map((c) => (
    <tr
      key={c.id}
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
    >
      <td
        scope="row"
        className="px-6 py-4 text-gray-900 hover:text-indigo-900 dark:text-white"
      >
        <div className={"flex gap-2"}>
          <div>
            {c.type === "ADD" ? (
              <VscDiffAdded className={"mt-1"} />
            ) : (
              <VscDiffRemoved className={"mt-1"} />
            )}
          </div>
          <div>{c.type === "ADD" ? "Ajout de" : "Suppression de"}</div>
          <div>
            <Badge>
              {c.match_rule
                ? c.match_rule.map((_) => _["LEMMA"]).join(" ")
                : "?"}
            </Badge>
          </div>
          <div>à</div>
          <div>
            <Badge>
              {c.dimension}:{c.model_class}
            </Badge>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 flex gap-2">
        {c.learning_scope === "PROJECT_SPECIFIC" && (
          <Badge color="gray">Spécifique à ce projet</Badge>
        )}
        {c.learning_scope === "MODEL_LEARNING" && (
          <Badge color="gray">Appris au modèle</Badge>
        )}
        {c.learning_scope === "UNDETERMINED" && (
          <div>
            <a
              className={"text-indigo-800 cursor-pointer hover:underline"}
              onClick={() => handleSetAnnotationSpecific(c.id)}
            >
              Spécifique au projet
            </a>
            <a
              className={"ml-10 text-indigo-800 cursor-pointer hover:underline"}
              onClick={() => handleLearnAnnotation(c.id)}
            >
              Apprendre
            </a>
          </div>
        )}
      </td>
    </tr>
  ));

  return (
    <div>
      <YMTable
        header={
          <tr>
            <th scope="col" className="px-6 py-3 flex gap-2">
              <div> {title} </div>
              <div className={"-mt-1"}>
                <YMTutorialTips
                  content={
                    <p className={"normal-case"}>
                      Retrouvez ici le journal des modifications et
                      apprentissages réalisés au cours de votre analyse de
                      corpus.
                      <br />
                      Choisissez de rendre ces changements permanents ou non
                      pour de futures analyses.
                    </p>
                  }
                />
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only"> x </span>
            </th>
          </tr>
        }
        rows={rows}
      />
      {rows.length === 0 && (
          <div className={"text-gray-400 text-center mt-10"}>
            Aucune actions pour le moment{" "}
          </div>
      )}
    </div>
  );
}
