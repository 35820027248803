import React, { useState } from "react";
import { LexiquePatterns } from "./State";
import LexiconTable from "./LexiconTable";
import ProjectSemanticFilter from "./SemanticFilter";

export default function ProjectSemanticSearch(props: any) {
  const [searchParams, setSearchParams] = useState({
    posPatterns: LexiquePatterns,
    term: "",
    minMaxOccurrence: [1, 1000000] as [number, number],
    enableParsing: false,
    semanticSearch: true,
  });
  return (
    <>
      <div className="relative overflow-x-auto">
        <ProjectSemanticFilter
          onSearch={(term: string) => {
            setSearchParams({
              posPatterns: [],
              term,
              minMaxOccurrence: [1, 1000000],
              enableParsing: false,
              semanticSearch: true,
            });
          }}
        />
        {searchParams.term != "" && (
          <LexiconTable searchParams={searchParams} />
        )}
        {searchParams.term == "" && (
          <div className="text-center text-gray-400 mt-10">
            Recherchez un terme pour afficher les résultats
          </div>
        )}
      </div>
    </>
  );
}
