/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AnnotationType {
    ADD = 'ADD',
    DELETE = 'DELETE',
}
