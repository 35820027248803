import {
  ProjectDimension,
  TaggedToken,
  TagType,
  VerbatimTag,
  VerbatimViewWithTagTokens,
} from "../../app_client";
import React, { useEffect, useState } from "react";
import {
  HiArrowLeft,
  HiChevronDoubleRight,
  HiChevronRight,
  HiPlus,
  HiTrash,
  HiX,
} from "react-icons/hi";
import { DropDownMenu, DropDownMenuItem } from "./components/components";
import { Modal, Spinner } from "flowbite-react";
import { YMButton, YMInput, YMToggle } from "../../ym-components";
import { useProject, useProjectApi } from "./State";
import { MdCreateNewFolder } from "react-icons/md";

export interface onTagParams {
  tokenIndex: number;
  dimension: string;
  className: string;
  extendToSimilarToken: boolean;
  color: string;
  token_text: string;
}

export interface onDeleteParams {
  tagId: string;
  extendToSimilarToken: boolean;
}

interface VerbatimTokenProps {
  verbatim: VerbatimViewWithTagTokens;
  token: TaggedToken;
  tokenIndex: number;
  previous: TaggedToken | null;
  open: boolean;
  onActive: any;
  onLeave: any;
  onDelete: (params: onDeleteParams) => void;
  onTag: (params: onTagParams) => void;
  dimensions: ProjectDimension[];
}

export function VerbatimToken({
  token,
  tokenIndex,
  verbatim,
  onActive,
  onLeave,
  open,
  onDelete,
  onTag,
  previous,
  dimensions,
}: VerbatimTokenProps) {
  const shouldFollowASpace =
    previous && // do not add whitespace at the beginning
    [",", ".", ";", "-"].indexOf(token.token_text) == -1 && // do not add whitespace before a comma or point
    !(previous && previous.token_text.endsWith("'")) && // do not ad whitespace after a quote
    !(previous && previous.token_text.endsWith("-")); // do not ad whitespace after a union

  const tokenTagged: TaggedToken & { tags: Array<VerbatimTag> } = {
    ...token,
    tags: token.tag_index?.map(
      (tag_index) => (verbatim.tags || [])[tag_index]
    ) as Array<VerbatimTag>,
  };

  return (
    <div
      className={" relative " + (shouldFollowASpace && "ml-1")}
      style={{ paddingTop: 0.5 }}
    >
      <Token
        key={tokenIndex}
        token_text={token.token_text}
        tokenIndex={tokenIndex}
        tags={tokenTagged.tags}
        onActive={onActive}
        onLeave={onLeave}
        open={open}
        onDelete={onDelete}
        onTag={onTag}
        dimensions={dimensions}
      />
    </div>
  );
}

interface TokenProps {
  token_text: string;
  tags: VerbatimTag[];
  onActive: () => void;
  onLeave: () => void;
  open: boolean;
  onDelete: (params: onDeleteParams) => void;
  onTag: (tag: onTagParams) => void;
  dimensions: ProjectDimension[];
  tokenIndex: number;
  autoSelectAllOccurrenceTagging?: boolean;
}

export function Token({
  token_text,
  tags,
  tokenIndex,
  onActive,
  onLeave,
  open,
  onDelete,
  onTag,
  dimensions,
  autoSelectAllOccurrenceTagging,
}: TokenProps) {
  const { projectId } = useProject();

  let [displayNewTagMenu, setDisplayNewTagMenu] = useState(false);
  let [menu, setMenu] = useState("default");
  let [dimension, setDimension] = useState<ProjectDimension | null>(null);
  let [className, setClassName] = useState<string | null>(null);
  let [tagOpen, setTagOpen] = useState<null | VerbatimTag>(null);

  let [displayNewClassNameModal, setDisplayNewClassNameModal] = useState(false);
  let [newClassDimension, setNewClassDimension] = useState(
    null as ProjectDimension | null
  );

  const _autoSelectAllOccurrenceTagging =
    autoSelectAllOccurrenceTagging || false;
  const _tags = (tags || []).sort((a, b) =>
    (a.match_id || "") > (b.match_id || "") ? 1 : -1
  );

  const [tokenClassificationDimensions, setTokenClassificationDimensions] =
    useState([] as ProjectDimension[]);
  const [singletonClassDimensions, setSingletonClassDimensions] = useState(
    [] as ProjectDimension[]
  );
  const projectApi = useProjectApi();

  useEffect(() => {
    const isSingletonClass = (dim: ProjectDimension) => dim.mono_class
    const isKeyWordClass = (dim: ProjectDimension) => dim.name == "lexique";
    const isNGrammesClass = (dim: ProjectDimension) => dim.name == "ngrammes";
    const isBlackListedClass = (dim: ProjectDimension) =>
      dim.name == "blacklist_frequencygraph";
    setSingletonClassDimensions(
      dimensions.filter(
        (dimension) =>
          dimension.tag_type == "token-classification" &&
          isSingletonClass(dimension) &&
          !isKeyWordClass(dimension) &&
          !isNGrammesClass(dimension) &&
          !isBlackListedClass(dimension)
      )
    );
    setTokenClassificationDimensions(
      dimensions.filter(
        (dimension) =>
          dimension.tag_type == "token-classification" &&
          !isSingletonClass(dimension) &&
          !isKeyWordClass(dimension) &&
          !isNGrammesClass(dimension) &&
          !isBlackListedClass(dimension)
      )
    );
  }, [dimensions]);

  const handleSelectDimension = (
    dimension: ProjectDimension,
    class_name: string
  ) => {
    setDimension(dimension);
    setClassName(class_name);
    if (_autoSelectAllOccurrenceTagging) {
      onTag({
        tokenIndex: tokenIndex,
        dimension: dimension?.name || "",
        className: class_name,
        extendToSimilarToken: true,
        color: dimension?.color || "blue",
        token_text: token_text,
      });
    } else {
      setMenu("MenuSelectDimensionTagScope");
    }
  };

  const handleAddClassname = (dimension: ProjectDimension | null) => {
    setDisplayNewClassNameModal(true);
    setNewClassDimension(dimension);
  };

  const handleClickOnToken = () => {
    onActive();
    setDisplayNewTagMenu(true);
    setMenu("default");
  };

  const handleAddClassRequest = (
    classname: string,
    allOccurrences: boolean
  ) => {
    if (newClassDimension) {
      onTag({
        tokenIndex: tokenIndex,
        dimension: newClassDimension?.name || "",
        className: classname,
        extendToSimilarToken: allOccurrences,
        color: newClassDimension?.color || "blue",
        token_text: token_text,
      });
      setDisplayNewClassNameModal(false);
    }
  };

  // TODO : avoid duplicate code to create new class / dimension in the project
  const handleAddDimensionRequest = async (
    dimension: string,
    classname: string,
    allOccurrences: boolean
  ) => {
    if (projectApi && projectId) {
      const dimId = await projectApi.createPatternMatchingDimension(
        projectId,
        dimension,
        classname
      );
      onTag({
        tokenIndex: tokenIndex,
        dimension: dimension,
        className: classname,
        extendToSimilarToken: allOccurrences,
        color: "blue",
        token_text: token_text,
      });
      setDisplayNewClassNameModal(false);
    }
  };

  return (
    <div
      className={
        "cursor-pointer flex flex-col relative cursor-pointer group/item"
      }
    >
      {displayNewClassNameModal && (
        <ModalNewClass
          dimension_type={TagType.TOKEN_CLASSIFICATION}
          display={displayNewClassNameModal}
          dimension={newClassDimension}
          onAddClass={(classname, allOccurrences) =>
            handleAddClassRequest(classname, allOccurrences)
          }
          onAddDimension={(dimension, className, allOccurrences) =>
            handleAddDimensionRequest(dimension, className, allOccurrences)
          }
          onClose={() => setDisplayNewClassNameModal(false)}
          displayAllOccurrences={true}
        />
      )}

      <div
        onClick={() => {
          setTagOpen(null);
          handleClickOnToken();
        }}
        style={{ fontSize: 16 }}
        className={"text-indigo-900 hover:text-indigo-800 hover:underline"}
      >
        {token_text}
      </div>

      {/* Underline the token with the dimension color */}
      {/* TODO: remove hack for lexique */}
      {Object.values(
        _tags
          .filter((tag) => tag.dimension != "lexique")
          .filter((tag) => tag.dimension != "blacklist_frequencygraph")
          .reduce(
            (acc, tag) => ({ ...acc, [tag.color || "blue"]: tag }),
            {} as Map<string, VerbatimTag>
          )
      ).map(
        (tag, index) =>
          tag && (
            <>
              <div
                className={"h-1 bg-" + tag.color + "-300"}
                key={tag.id}
                onClick={() => {
                  setTagOpen(null);
                  handleClickOnToken();
                }}
                style={{
                  marginTop: index,
                  width: tag.has_many_tokens ? "110%" : "100%",
                }}
              ></div>
            </>
          )
      )}

      {open && tagOpen && (
        <div className={"absolute top-6 z-20"}>
          <a onClick={onLeave}>
            <HiX />
          </a>
          <DropDownMenu
            key={tagOpen.id}
            color={tagOpen.color || "blue"}
            label={tagOpen.dimension + ":" + tagOpen.label}
            items={
              <>
                {_tags
                  .filter((tag) => tag.dimension != "lexique")
                  .filter((tag) => tag.dimension != "blacklist_frequencygraph")
                  .map((tag, index) => (
                    <DropDownMenuItem
                      key={tag.id}
                      color={tag.color || "blue"}
                      label={tag.dimension + ":" + tag.label}
                      onClick={() => {}}
                    />
                  ))}
              </>
            }
          />
        </div>
      )}

      {open && tagOpen === null && (
        <div className={"absolute top-6 z-20"}>
          <a onClick={onLeave}>
            <HiX />
          </a>
          {displayNewTagMenu && (
            <>
              <DropDownMenu
                color={"purple"}
                label={"Ajouter un tag"}
                items={
                  <>
                    {menu === "default" && (
                      <SelectADimensionClassName
                        color={"purple"}
                        onMenuSelect={setMenu}
                        displayBackItem={false}
                        handleSelectDimension={handleSelectDimension}
                        handleAddClassname={handleAddClassname}
                        tokenClassificationDimensions={
                          tokenClassificationDimensions
                        }
                        singletonClassDimensions={singletonClassDimensions}
                        tokenText={token_text}
                      />
                    )}
                    {menu === "MenuSelectDimensionTagScope" && (
                      <MenuSelectDimensionTagScope
                        color={"purple"}
                        tokenIndex={tokenIndex}
                        tagClassName={className || ""}
                        dimension={dimension}
                        onMenuSelect={setMenu}
                        onTag={onTag}
                        tokenText={token_text}
                      />
                    )}
                  </>
                }
              />
              {_tags
                .filter((tag) => tag.dimension != "lexique")
                .filter((tag) => tag.dimension != "blacklist_frequencygraph")
                .sort((a, b) => a.id.localeCompare(b.id))
                .map((tag, index) => (
                  <DropDownMenu
                    key={tag.id}
                    color={tag.color || "blue"}
                    label={tag.dimension + ":" + tag.label}
                    items={
                      (menu === "default" && (
                        <TagDeleteOrAddToModel
                          tag={tag}
                          onMenuSelect={setMenu}
                          onDelete={onDelete}
                        />
                      )) ||
                      (menu === "MenuSelectDimension" && (
                        <SelectADimensionClassName
                          color={tag.color || "blue"}
                          onMenuSelect={setMenu}
                          displayBackItem={true}
                          handleSelectDimension={handleSelectDimension}
                          handleAddClassname={handleAddClassname}
                          tokenClassificationDimensions={
                            tokenClassificationDimensions
                          }
                          singletonClassDimensions={singletonClassDimensions}
                          tokenText={token_text}
                        />
                      )) ||
                      (menu === "MenuSelectDimensionTagScope" && (
                        <MenuSelectDimensionTagScope
                          color={tag.color || "blue"}
                          tokenIndex={tokenIndex}
                          tagClassName={className || ""}
                          dimension={dimension}
                          onMenuSelect={setMenu}
                          tag={tag}
                          onTag={onTag}
                          tokenText={token_text}
                        />
                      )) || <li></li>
                    }
                  />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

type ModalNewTagClassProps = {
  display: boolean;
  dimension_type: TagType;
  dimension: ProjectDimension | null;
  displayAllOccurrences?: boolean;
  onAddClass: (className: string, allOccurrences: boolean) => void;
  onAddDimension: (
    dimensionName: string,
    className: string,
    allOccurrences: boolean
  ) => void;
  onClose: () => void;
};

export const ModalNewClass: React.FC<ModalNewTagClassProps> = ({
  display,
  dimension_type,
  dimension,
  onAddClass,
  onAddDimension,
  onClose,
  displayAllOccurrences,
}) => {
  const [newClassName, setNewClassName] = useState<string>("");
  const [newDimension, setNewDimension] = useState<string>("");
  const [allOccurences, setAllOccurences] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let modalTitle = {
    [TagType.TOKEN_CLASSIFICATION]: "Nouveau modèle à champ lexical",
    [TagType.TEXT_CLASSIFICATION]: "Nouveau modèle verbatim",
    [TagType.META_DATA]: "Nouveau modèle métadonnées",
  }[dimension_type];

  const handleAdd = () => {
    setLoading(true);
    if (dimension) {
      onAddClass(newClassName, allOccurences);
    } else {
      onAddDimension(newDimension, newClassName, allOccurences);
    }
    setLoading(false);
  };

  return (
    <Modal
      root={document.body}
      size="5xl"
      show={display}
      onClose={() => onClose()}
    >
      <Modal.Header>
        <div className={"font-bold text-indigo-800 title"}>
          {" "}
          {dimension ? dimension.name : modalTitle}{" "}
        </div>
      </Modal.Header>
      <Modal.Body>
        {dimension === null && (
          <YMInput
            value={newDimension}
            label={`Nouveau modèle`}
            onChange={(e) => setNewDimension(e)}
          />
        )}
        <div className={"mt-5"} />
        <YMInput
          value={newClassName}
          label={`Nouvelle classe`}
          onChange={(e) => setNewClassName(e)}
        />
        <div className={"mt-5"} />
        {displayAllOccurrences && (
          <YMToggle
            label={"Ajouter toutes les occurrences"}
            value={allOccurences}
            onChange={(e) => setAllOccurences(e)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading && (
          <div className={"mr-5"}>
            <Spinner />
          </div>
        )}
        {!loading && <YMButton text={"Ajouter"} onClick={handleAdd} />}
      </Modal.Footer>
    </Modal>
  );
};

const TagDeleteOrAddToModel = ({
  tag,
  onMenuSelect,
  onDelete,
}: {
  tag: VerbatimTag;
  onMenuSelect: any;
  onDelete: any;
}) => (
  <>
    <DropDownMenuItem
      icon={<HiTrash className={"w-3 h-3"} />}
      color={tag?.color || "blue"}
      label={"Supprimer cette occurence uniquement"}
      onClick={() => onDelete({ tagId: tag.id, extendToSimilarToken: false })}
    />
    <DropDownMenuItem
      icon={<HiTrash className={"w-3 h-3"} />}
      color={tag?.color || "blue"}
      label={"Supprimer toutes les occurences"}
      onClick={() => onDelete({ tagId: tag.id, extendToSimilarToken: true })}
    />
    <DropDownMenuItem
      icon={<HiPlus className={"w-3 h-3"} />}
      color={tag?.color || "blue"}
      label={"Ajouter à un modèle"}
      onClick={() => onMenuSelect("MenuSelectDimension")}
    />
  </>
);

const SelectADimensionClassName = ({
  color,
  onMenuSelect,
  displayBackItem,
  handleSelectDimension,
  handleAddClassname,
  tokenClassificationDimensions,
  singletonClassDimensions,
  tokenText,
}: {
  color: string;
  onMenuSelect: any;
  displayBackItem: boolean | undefined;
  handleSelectDimension: any;
  handleAddClassname: any;
  tokenClassificationDimensions: ProjectDimension[];
  singletonClassDimensions: ProjectDimension[];
  tokenText: string;
}) => (
  <>
    {displayBackItem !== false && (
      <DropDownMenuItem
        icon={<HiArrowLeft className={"w-3 h-3"} />}
        color={color || "blue"}
        label={"Retour"}
        onClick={() => onMenuSelect("default")}
      />
    )}

    <DropDownMenuItem
      icon={<MdCreateNewFolder className={"w-3 h-3"} />}
      color={color || "blue"}
      label={"créer un nouveau modèle"}
      onClick={() => handleAddClassname(null)}
    />

    {tokenClassificationDimensions.map((d) => (
      <DropDownMenu
        key={d.name}
        color={color || "blue"}
        label={`${d.name}`}
        items={
          <>
            {d.class_names?.map((c) => (
              <DropDownMenuItem
                key={c}
                icon={<HiPlus className={"ml-3 w-3 h-3"} />}
                color={color || "blue"}
                label={"Ajouter à " + c}
                onClick={() => handleSelectDimension(d, c)}
              />
            ))}
            <DropDownMenuItem
              icon={<MdCreateNewFolder className={"ml-3 w-3 h-3"} />}
              color={color || "blue"}
              label={"créer une classe"}
              onClick={() => handleAddClassname(d)}
            />
          </>
        }
      />
    ))}
    {singletonClassDimensions.map((d) => (
      <DropDownMenuItem
        key={d.id}
        icon={<HiPlus className={"w-3 h-3"} />}
        color={color || "blue"}
        label={"Ajouter à " + d.name}
        onClick={() => handleSelectDimension(d, tokenText)}
      />
    ))}
  </>
);

const MenuSelectDimensionTagScope = ({
  color,
  onMenuSelect,
  tokenIndex,
  tagClassName,
  dimension,
  tag,
  onTag,
  tokenText,
}: {
  color: string;
  tokenIndex: number;
  onMenuSelect: any;
  tagClassName: string;
  dimension: ProjectDimension | null;
  tag?: VerbatimTag;
  onTag: any;
  tokenText: string;
}) => (
  <>
    <DropDownMenuItem
      icon={<HiArrowLeft className={"w-3 h-3"} />}
      color={color || "blue"}
      label={"Retour"}
      onClick={() => onMenuSelect("default")}
    />
    <DropDownMenuItem
      icon={<HiChevronRight className={"w-3 h-3"} />}
      color={color || "blue"}
      label={"Ajouter cette occurrence"}
      onClick={() =>
        onTag({
          tokenIndex: tokenIndex,
          dimension: dimension?.name || "",
          className: tagClassName,
          extendToSimilarToken: false,
          color: color,
          token_text: (tag && tag.token_lemma) || tokenText,
        })
      }
    />
    <DropDownMenuItem
      icon={<HiChevronDoubleRight className={"w-3 h-3"} />}
      color={color || "blue"}
      label={"Ajouter toutes les occurrences"}
      onClick={() =>
        onTag({
          tokenIndex: tokenIndex,
          dimension: dimension?.name || "",
          className: tagClassName,
          extendToSimilarToken: true,
          color: color,
          token_text: (tag && tag.token_lemma) || tokenText,
        })
      }
    />
  </>
);
