import React, {useContext, useEffect, useRef, useState} from "react";
import {Badge, Breadcrumb, Button, Dropdown, Spinner, Tooltip,} from "flowbite-react";
import {useNavigate} from "react-router-dom";
import {HiCubeTransparent, HiDocument, HiDownload} from "react-icons/hi";
import {AiFillTags, AiOutlineFieldTime} from "react-icons/ai";
import {SiDatabricks} from "react-icons/si";
import {FaQuestionCircle} from "react-icons/fa";
import {AuthUserContext} from "./AuthUserContext";
import {useWorkspaceApi} from "./pages/workspace/Api";
import {DimensionProcessInfo, WorkspaceView} from "./app_client";
import {useAuth0} from "@auth0/auth0-react";
import {formatNumber} from "./util";

type YMSelectProps = {
  value: string | undefined;
  label: string;
  options: { id: string; name: string }[];
  onChange: (newValue: string) => void;
};

export const YMSelect: React.FC<YMSelectProps> = ({
  value,
    label,
  options,
  onChange,
}) => (
  <label className="relative">
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block bg-opacity-50 bg-white mr-2 px-2.5 pb-2.5  pt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-opacity-50 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      {options.map((option: any) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
    <label
        htmlFor="floating_outlined"
        className="absolute text-lg text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-purple-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {label}
      </label>
  </label>
);

type YMToogle = {
  value: boolean;
  onChange: (newValue: boolean) => void;
  label: string;
};

export const YMToggle: React.FC<YMToogle> = ({ value, onChange, label }) => {
  return (
    <label className="relative inline-flex items-center mb-4 cursor-pointer">
      <input
        type="checkbox"
        onClick={() => onChange(!value)}
        onChange={() => {}} // to avoid warning
        checked={value}
        className="sr-only peer"
      />
      <div
        style={{ zoom: 0.8 }}
        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-600"
      ></div>
      <span className="ml-3 font-bold text-indigo-900 text-xs">{label}</span>
    </label>
  );
};

type YMInputProps = {
  type?: string;
  value: string | number;
  label: string;
  onChange: (newValue: string) => void;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;
};

export const YMInput: React.FC<YMInputProps> = ({
  value,
  label,
  onChange,
  type,
    min,
    max,
    step,
    placeholder
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const _type = type || "text";

  const handleInputChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <div
      className={`relative ${
        isFocused ? "border-blue-500" : "border-gray-300"
      }`}
    >
      <input
        type={_type}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(e) => handleInputChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-purple-600 peer"
      />
      <label
        htmlFor="floating_outlined"
        className="absolute text-lg text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-purple-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {label}
      </label>
    </div>
  );
};

type YMLabelProps = {
  text: string;
};

export const YMLabel: React.FC<YMLabelProps> = ({ text }) => (
  <div className="block mb-2 text-sm font-bold text-indigo-900 dark:text-white">
    {text}
  </div>
);

type YMButtonProps = {
  text: string;
  icon?: React.ReactNode | undefined;
  onClick: () => void;
  size?: string;
  disabled?: boolean | undefined;
  className?: string;
  href?: string;
  color?: "default" | "light" | "white";
  loading?: boolean | undefined;
};

export const YMButton: React.FC<YMButtonProps> = ({
  size,
  text,
  icon,
  onClick,
  disabled,
  className,
  href,
  color,
}) => {
  let classNameColor =
    "bg-indigo-700 hover:bg-indigo-900 text-white rounded-md ";
  let buttonColor = "default";
  if (color == "light") {
    classNameColor =
      "bg-indigo-500 hover:bg-indigo-900 text-indigo-800 rounded-md ";
  }

  if (color == "white") {
    classNameColor = "bg-white text-indigo-800 rounded-md ";
    buttonColor = "gray";
  }

  return (
    <Button
      size={size || "sm"}
      className={classNameColor + className}
      onClick={onClick}
      disabled={disabled}
      href={href}
      color={buttonColor}
    >
      {icon}
      {text}
    </Button>
  );
};

export const YMButtonLight: React.FC<YMButtonProps> = ({
  size,
  text,
  icon,
  onClick,
}) => (
  <Button
    size={size || "sm"}
    className="bg-indigo-500 hover:bg-indigo-900 text-indigo-800"
    onClick={onClick}
  >
    {icon}
    {text}
  </Button>
);

export const YMTinyButton: React.FC<YMButtonProps> = ({
  size,
  text,
  icon,
  onClick,
  color,
    loading
}) => {
  let classColor =
    "text-white bg-indigo-700 hover:bg-indigo-800 focus:bg-indigo-700";
  if (color == "light") {
    classColor =
      "bg-indigo-100 text-indigo-800 hover:bg-indigo-300 focus:bg-indigo-300";
  }
  if (color == "white") {
    classColor =
        "bg-white text-indigo-800 hover:bg-indigo-100 focus:bg-indigo-100 border border-indigo-500";
  }

  return (
    <button
      onClick={onClick}
      className={`text-xs ${classColor} relative group flex flex-row px-2 py-1 items-center rounded-md transition duration-300 ease-in-out hover:no-underline focus:no-underline  static`}
    >
      {loading ?
          (<div className={"pr-2"}>
              <Spinner size={"sm"} />
            </div>)
          : icon
      }
      <span className="hover:no-underline focus:no-underline"> {text} </span>
    </button>
  );
};

type YMCardProps = {
  children: React.ReactNode;
  className?: string;
};

export const YMCard: React.FC<YMCardProps> = ({ className, children }) => (
  <div
    className={
      (className || "") +
      " backdrop-blur-md bg-opacity-100 bg-white text-gray-900 dark:bg-opacity-60 dark:bg-black p-4 rounded-lg shadow-md border border-purple-200"
    }
  >
    {children}
  </div>
);

type YMTableProps = {
  tableClassName?: string | undefined;
  header: React.ReactNode;
  rows: React.ReactNode[];
};

export const YMTableTh = ({ children }: { children: React.ReactNode }) => (
  <th
    scope="row"
    className="px-6 py-4 font-bold text-indigo-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
  >
    {" "}
    {children}{" "}
  </th>
);

export const YMTable: React.FC<YMTableProps> = ({
  header,
  rows,
  tableClassName,
}) => (
  <table
    className={
      "w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-10 " +
      tableClassName
    }
  >
    <thead className="text-xs text-indigo-900 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
      {header}
    </thead>
    <tbody>{rows}</tbody>
  </table>
);

type YMLoaderProps = {
  message: string;
  loading: boolean;
  children: React.ReactNode;
  style?: any;
};

export const YMLoader: React.FC<YMLoaderProps> = ({
  style,
  message,
  loading,
  children,
}) =>
  loading ? (
    <div className="w-100 mt-10 flex justify-center items-center" style={style}>
      <div className={"text-center"}>
        <Spinner color="gray" size="xl" />
        <div className={"mt-4 text-gray-400"}> {message} </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );

export interface BreadcrumbLink {
  title: string;
  link: string;
}

type YMBreadcrumbProps = {
  links: BreadcrumbLink[];
};

export const YMBreadCrumb: React.FC<YMBreadcrumbProps> = ({ links }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb className="py-3 px-5 dark:bg-gray-900">
      {links.map((link, index) => (
        <Breadcrumb.Item
          key={index}
          className="cursor-pointer hover:text-indigo-900"
          onClick={() => navigate(link.link)}
        >
          {link.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export const YMIcons = {
  Document: HiDocument,
  Tag: AiFillTags,
  History: AiOutlineFieldTime,
  Model: SiDatabricks,
  Dimension: HiCubeTransparent,
  Download: HiDownload,
};

type YMMultiSelectProps = {
  wordList: string[];
  values: string[];
  onListChange: (words: string[]) => void;
  max?: number;
  hideDisableButton?: boolean;
};

export const YMMultiSelect: React.FC<YMMultiSelectProps> = ({
  wordList,
  values,
  onListChange,
  max,
  hideDisableButton,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredWorldList, setFilteredWorldList] = useState([] as string[]);
  const [selectedWorlds, setSelectedWorlds] = useState([...values] as string[]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setFilteredWorldList(wordList);
  }, [wordList]);

  useEffect(() => {
    setSelectedWorlds([...values]);
  }, [values]);

  useEffect(() => {

  }, [selectedWorlds]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
    const filtered = wordList
      .filter((world) =>
        world.toLowerCase().startsWith(event.target.value.toLowerCase())
      )
      .filter((world) => !selectedWorlds.includes(world));
    setFilteredWorldList(filtered as string[]);
  };

  const handleSelect = (world: string) => {
    if (!selectedWorlds.includes(world)) {
      const data = [...selectedWorlds, world];
      if (max && data.length > max) {
        data.shift();
      }
      setSelectedWorlds(data);
      setInputValue("");
      onListChange(data);
    }
  };

  const handleDeselect = (world: string) => {
    setSelectedWorlds(selectedWorlds.filter((item) => item !== world));
    onListChange(selectedWorlds.filter((item) => item !== world));
  };

  return (
    <div className="">
      <div
        className="inline-flex text-indigo-900 min-h-10 border border-gray-400 appearance-none rounded w-full px-3 py-1 focus focus:outline-none active:outline-none  filled focus:border-2 focus:border-indigo-400 active:border-indigo-400"
        onBlur={(e) => setTimeout(() => setIsFocused(false), 500)}
        onClick={() => {
          if (inputRef !== null) {
            // @ts-ignore
            inputRef.current.focus();
            setIsFocused(true);
          }
        }}
      >
        {selectedWorlds.map((world, index) => (
          <div
            key={index}
            className="h-6 mt-0.5 ml-1 bg-indigo-900 text-sm text-white px-2 py-1 rounded-md flex items-center w-max"
          >
            {world}
            {!hideDisableButton && (
              <button
                className="ml-1 focus:outline-none"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeselect(world);
                }}
              >
                &times;
              </button>
            )}
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          className={
            "bg-transparent h-8 border-0 outline-none shadow-none flew-grow border-transparent focus:border-transparent focus:ring-0 -mt-1"
          }
          value={inputValue}
          onChange={handleChange}
          style={{ minWidth: "8rem" }}
        />
      </div>
      <div className="absolute w-full mt-1 z-10">
        {isFocused && filteredWorldList.length > 0 && (
          <div className="bg-gray-50 border border-gray-300 rounded py-2 shadow-lg">
            {filteredWorldList.map((world, index) => (
              <div
                key={index}
                className={`cursor-pointer inline ml-2 mt-1 hover:bg-gray-100 text-sm text-indigo-800 px-1 rounded-md flex items-center w-max`}
                onClick={() => handleSelect(world)}
              >
                {world}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface YmLightButtonProps {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
  color?: string;
}

export function YMLightButton({ label, onClick, icon, color }: YmLightButtonProps) {

  let colorClass = 'bg-indigo-200 text-indigo-800 hover:bg-indigo-800 hover:text-white focus:text-white focus:bg-indigo-500'

  if (color === 'green') {
    colorClass = 'bg-green-700 text-white hover:bg-green-800 hover:text-white focus:text-white focus:bg-green-900'
  }
  
  return (
    <button
      onClick={onClick}
      className={` ${colorClass} relative group flex flex-row px-2 py-1 items-center hover:text-white text-sm focus:text-white rounded-lg transition duration-300 ease-in-out hover:no-underline focus:no-underline static`}
    >
      {icon}
      <span className="hover:no-underline focus:no-underline">{label}</span>
    </button>
  );
}

interface YMTutorialTips {
  content: any;
}

export function YMTutorialTips({ content }: YMTutorialTips) {
  return (
    <Tooltip style="light" placement="bottom" content={content}>
      <FaQuestionCircle
        className={"mt-1 text-indigo-500"}
        data-tooltip-target="tooltip-light"
      />
    </Tooltip>
  );
}

export function YMSqueleton() {
  return (
    <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
      <div className="flex items-center w-full space-x-2">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[480px]">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[400px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[480px]">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[440px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[360px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export const YMWorkspaceInfo: React.FC = () => {
  const { workspaceName, workspaceToken, setWorkspaceId } =
    useContext(AuthUserContext);
  const workspaceApi = useWorkspaceApi();
  const navigate = useNavigate();

  const [workspaceList, setWorkspaceList] = useState<WorkspaceView[]>([]);
  useEffect(() => {
    if (workspaceApi)
      workspaceApi.getWorkspaces().then((workspaceList) => {
        setWorkspaceList(workspaceList);
      });
  }, [workspaceApi]);

  const handleSelect = (workspace: WorkspaceView) => {
    setWorkspaceId(workspace.id);
    navigate("/");
  };

  return (
    <div className={"flex flex-col z-100"}>
      <div className="text-gray-800 uppercase text-xs font-bold font-sans pt-2 mb-2">
        {" "}
        Espaces de travail
      </div>
      <Dropdown
        className={"bg-white"}
        inline={true}
        label={<Badge color={"gray"}> {workspaceName} </Badge>}
      >
        {workspaceList.map((workspace) => (
          <Dropdown.Item
            onClick={() => handleSelect(workspace)}
            key={workspace.id}
          >
            {" "}
            {workspace.owner_email}{" "}
          </Dropdown.Item>
        ))}
      </Dropdown>
      <div className="flex flex-wrap mt-2">
        <Badge color={"gray"}> {formatNumber(workspaceToken || 0)} Mots </Badge>
      </div>
    </div>
  );
};

export const YMTopBanner: React.FC = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  return (
    <div className="h-12 border-b border-gray-200 bg-white w-full z-50 fixed bg-white flex justify-between pr-10">
      <div className="flex items-center gap-2 text-2xl text-indigo-800 font-bold font-sans pl-5 pt-2">
        <div> Youmean</div>
        <Badge className={""} color={"red"}>
          {" "}
          VERSION BETA{" "}
        </Badge>
      </div>
      <div className="mt-1 flex gap-2 items-center">
        <Dropdown
          inline={true}
          label={
            <div className="bg-indigo-500 hover:bg-indigo-800 cursor-pointer text-white text-sm font-bold flex items-center justify-center h-8 w-8 rounded rounded-full">
              {user?.name?.charAt(0).toUpperCase()}
            </div>
          }
        >
          <Dropdown.Item>
            <div className="flex flex-col">
              <div className="text-gray-500 text-sm">{user?.name}</div>
              <div className="text-gray-500 text-sm">{user?.email}</div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/workspaces/me")}>
            Mon Compte
          </Dropdown.Item>
          <Dropdown.Item onClick={() => logout()}>Se déconnecter</Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
};

const ColorMap = {
  DONE: "success",
  EXPECTED: "indigo",
  PENDING: "warning",
  WAITING: "warning",
  PROCESSING_LOCALLY: "warning",
  PROCESSING_ON_LAMBDA: "warning",
  COMPLETED: "success",
  FAILED: "failure",
  TIMEOUT: "failure",
};

export const YMDimensionBadge = ({ dimension }: { dimension: DimensionProcessInfo }) => {
  // @ts-ignore
  const color = ColorMap[dimension.status] || "gray";
  return <Badge color={color}><a href={"/events/"+dimension.job_id} target={"_blank"} rel="noreferrer">{dimension.dimension}:{dimension.status}</a></Badge>;
};

export const YMStatusBadge = ({ status }: { status: string }) => {
  // @ts-ignore
  const color = ColorMap[status] || "gray";

  return <Badge color={color}>{status}</Badge>;
};
