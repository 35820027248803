/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobId } from '../models/JobId';
import type { NewTopicModelingJob } from '../models/NewTopicModelingJob';
import type { TopicModelingJobView } from '../models/TopicModelingJobView';
import type { TopicModelingVisualizationType } from '../models/TopicModelingVisualizationType';
import type { UpdateStopWords } from '../models/UpdateStopWords';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TopicModelingJobService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Jobs
     * @returns TopicModelingJobView Successful Response
     * @throws ApiError
     */
    public getJobsTopicModelingJobGet(): CancelablePromise<Array<TopicModelingJobView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topic_modeling_job/',
        });
    }

    /**
     * New Topic Modeling
     * @param requestBody
     * @returns JobId Successful Response
     * @throws ApiError
     */
    public newTopicModelingTopicModelingJobPost(
        requestBody: NewTopicModelingJob,
    ): CancelablePromise<JobId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/topic_modeling_job/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Stopwords
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStopwordsTopicModelingJobStopwordsGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topic_modeling_job/stopwords',
        });
    }

    /**
     * Update Stopwords
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateStopwordsTopicModelingJobStopwordsPost(
        requestBody: UpdateStopWords,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/topic_modeling_job/stopwords',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job
     * @param jobId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getJobTopicModelingJobJobIdGet(
        jobId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topic_modeling_job/{job_id}/',
            path: {
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Topic Csv
     * @param jobId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTopicCsvTopicModelingJobJobIdCsvGet(
        jobId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topic_modeling_job/{job_id}/csv',
            path: {
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Topic Visualization
     * @param jobId
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTopicVisualizationTopicModelingJobJobIdTypeHtmlGet(
        jobId: string,
        type: TopicModelingVisualizationType,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topic_modeling_job/{job_id}/{type}.html',
            path: {
                'job_id': jobId,
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
