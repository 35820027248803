import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  FileInput,
  Label,
  Spinner,
  TextInput,
} from "flowbite-react";
import { parse } from "papaparse";
import { useApi } from "../api"; // @ts-ignore
import { useNavigate } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";

function Inference() {
  const { api, isAuthenticated } = useApi();
  const [jobs, setJobs] = useState(null as null | Array<any>);
  const navigate = useNavigate();
  const [loadingAnalyse, setLoadingAnalyse] = useState(false);
  const [displayOlderJobs, setDisplayOlderJobs] = useState(false);
  const [hideProjectJobs, setHideProjectJobs] = useState(true);

  let refresh_jobs = useCallback(async () => {
    const jobs = await api.get_inference_jobs({
      recent_jobs_only: !displayOlderJobs,
    });
    setJobs((_) => [...jobs].reverse());
  }, [api, displayOlderJobs]);

  useEffect(() => {
    let loop = true;
    const getUserJobs = async () => {
      try {
        let refresh_job_loop = async () => {
          const jobs = await api.get_inference_jobs({
            recent_jobs_only: !displayOlderJobs,
          });
          setJobs((_) => [...jobs].reverse());
          await new Promise((r) => setTimeout(r, 10000));
          if (loop) await refresh_job_loop();
        };
        await refresh_job_loop();
      } catch (e: any) {
        console.log(e);
      }
    };
    if (isAuthenticated) {
      getUserJobs();
    }
    return () => {
      loop = false;
    };
  }, [api, isAuthenticated, displayOlderJobs]);

  const [err, setErr] = useState("");
  const [modelName, setModelName] = useState(
    "champsully/autotrain-1000_question-jean-62660135184"
  );
  const [csvfile, setCsvFile] = useState("" as any);

  const handleFileChange = (e: any) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!["csv"].includes(fileExtension)) {
        alert("Please input a csv file");
        throw Error("csv file expected");
      }
      setCsvFile(inputFile);
    }
  };

  const handleUpload = async () => {
    if (!csvfile) {
      alert("no file selected !");
      throw Error("select a file");
    }

    if (!modelName) {
      alert("no model selected !");
      throw Error("select a model");
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      // @ts-ignore
      const csv = parse(event.target.result, { header: false });
      // @ts-ignore
      const parsedData = csv?.data;
      if (parsedData.length === 0) {
        alert("The filed is empty");
      } else {
        // @ts-ignore
        let csvData = parsedData.map((row: any) => row[0]);
        try {
          setLoadingAnalyse(true);
          await api.new_inference_job(csvData, csvfile.name, modelName);
          await refresh_jobs();
          setLoadingAnalyse(false);
        } catch (err: any) {
          setErr(err.message);
        }
      }
    };
    reader.readAsText(csvfile);
  };

  return (
    <div className="w-100 mx-auto mt-28">
      {/* input model name */}
      <div>
        <div className="mb-2 block">
          <Label htmlFor="modelname" color="green" value="The model name" />
        </div>
        <TextInput
          id="modelname"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
          placeholder="BaptisteDoyen/camembert-base-xnli"
          required={true}
          color="green"
        />
      </div>

      {/* file upload */}
      <div id="fileUpload" className="mt-10">
        <div className="mb-2 block">
          <Label htmlFor="file" value="Upload a .csv file" />
        </div>
        <FileInput
          id="file"
          accept=".csv"
          helperText="Upload a csv file containing a list of verbatims"
          onChange={handleFileChange}
        />
      </div>

      {!loadingAnalyse && (
        <div>
          <Button
            className="mt-10"
            gradientMonochrome="info"
            onClick={handleUpload}
          >
            Analyser
          </Button>
          {err && (
            <Alert color="failure" className="mt-5">
              <span>
                <span className="font-medium"> {err} </span>
              </span>
            </Alert>
          )}
        </div>
      )}

      {loadingAnalyse && <Spinner />}

      <div className="mt-10 text-2xl">Jobs</div>

      <div className="my-5">
        <fieldset className="flex flex-col gap-4" id="display-options">
          <div className="flex items-center gap-2">
            <Checkbox
              id="display-all-jobs"
              name="display-options"
              value="display-all-jobs"
              onChange={(e) => setDisplayOlderJobs(e.target.checked)}
              defaultChecked={displayOlderJobs}
            />
            <Label htmlFor="united-state">View old jobs</Label>
          </div>
        </fieldset>
        <fieldset className="flex flex-col gap-4" id="display-options">
          <div className="flex items-center gap-2">
            <Checkbox
              id="display-all-jobs"
              name="display-options"
              value="display-all-jobs"
              onChange={(e) => setHideProjectJobs(e.target.checked)}
              defaultChecked={hideProjectJobs}
            />
            <Label htmlFor="united-state">
              Cacher les analyses issues de projets
            </Label>
          </div>
        </fieldset>
      </div>

      {jobs === null && <p> Loading jobs ... </p>}
      {jobs && jobs.length === 0 && <p> Anys jobs for the moment .. </p>}

      {jobs &&
        jobs
          .filter(
            (job) => !job.analyse_id.includes("project") || !hideProjectJobs
          )
          .map((j) => (
            <Card key={j.id} className="mt-2">
              <div className="flex justify-between">
                <div>
                  {j.status === "WAITING" && (
                    <div>
                      <Badge color="info">Waiting...</Badge>
                    </div>
                  )}
                  {j.status === "LAMBDA_STARTING" && (
                    <div>
                      <Badge color="info">Starting gpu server ...</Badge>
                    </div>
                  )}
                  {j.status === "PROCESSING_ON_LAMBDA" && (
                    <Badge color="success">Processing on gpu ...</Badge>
                  )}
                  {j.status === "PROCESSING_LOCALLY" && (
                    <Badge color="success">
                      Processing on local server ...
                    </Badge>
                  )}
                  {j.status === "MODEL_LOADING" && (
                    <Badge color="info">Loading model...</Badge>
                  )}
                  {j.status === "COMPLETED" && (
                    <Badge color="success">Completed</Badge>
                  )}
                  {j.status === "FAILED" && (
                    <Badge color="failure">Failed </Badge>
                  )}
                  {j.status === "TIMEOUT" && (
                    <Badge color="failure">Failed </Badge>
                  )}
                </div>
                <div className="flex space-x-4">
                  {/* <Button color="gray" onClick={() => api.download_verbatims_csv(j.id, "verbatims_" + j.name)}
                                    size="xs">Download
                                verbatims </Button> */}
                  {j.status === "COMPLETED" && (
                    <Button
                      onClick={() => api.download_tags_csv(j.id, j.name)}
                      size="xs"
                    >
                      Download results{" "}
                    </Button>
                  )}
                </div>
              </div>

              <span className="text-sm text-gray-900">
                <span className="text-xs text-gray-500">{j.created_at} </span>
                <br />
                <span
                  className="text-xs text-gray-500 hover:text-blue-500 cursor-pointer"
                  onClick={() => navigate("/events/" + j.id)}
                >
                  # {j.id}
                </span>
                <br />
                <span className="text-xs text-gray-500">{j.name} </span>
                <br />
                <span className="text-xs text-gray-500">{j.model_name}</span>
                <br />
                <span className="text-xs text-gray-500">
                  📝 {j.verbatim_count} verbatims{" "}
                </span>
                <br />

                {j.run_duration && (
                  <span className="text-xs text-gray-500">
                    ⏱ {Math.round(j.run_duration / 60)} minutes{" "}
                    {j.run_duration % 60} seconds{" "}
                  </span>
                )}

                {j.fail_reason && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    {" "}
                    {j.fail_reason}{" "}
                  </Alert>
                )}

                <br />
                {typeof j.params === "object" &&
                  j.params !== null &&
                  Object.entries(j.params).map(
                    ([k, v]: [string, any]) =>
                      v && (
                        <>
                          <span className="text-gray-500">
                            <b>{k}</b>: {JSON.stringify(v)}
                          </span>
                          <br />
                        </>
                      )
                  )}
              </span>
            </Card>
          ))}
    </div>
  );
}

export default Inference;
