import buildNodesAndLinks from "./buildNodesAndLinks";
import clusterGraph from "./clustering";

export default function frequencyGraph(
  gnodes,
  gedges,
  gnbClusters,
  excludeOrphans,
  blacklist
) {

  const { nodes, edges } = buildNodesAndLinks(
    gnodes,
    gedges,
    blacklist,
    excludeOrphans
  );

  const { nbClusters, cluster, nodesWithIdAndGroup } = clusterGraph({
    nodes: nodes.filter((node) => !node.hidden).map((node) => node.lemma),
    edges: edges.filter((edge) => !edge.hidden),
    ExpectedNbClusters: gnbClusters,
    initialCluster: null,
  });

  // nodesWithIdAndGroup is an array of {id, group}
  const findGroup = (lemma) => {
    const searchedNode = nodesWithIdAndGroup.find((node) => node.id === lemma);
    if (searchedNode) {
      return searchedNode.group;
    }
    return 0;
  };

  // Add the cluster group id to the nodes
  const updatedNodes = nodes.map((node) => ({
    ...node,
    group: findGroup(node.lemma),
  }));
  // Add the cluster group id to the edges
  const updatedEdges = edges.map((link) => {
    const sourceGroup = findGroup(link.source);
    const targetGroup = findGroup(link.target);
    return {
      ...link,
      group: sourceGroup,
      sameGroup: sourceGroup === targetGroup,
    };
  });

  return {
    nodes: updatedNodes,
    edges: updatedEdges,
    computedNbClusters: nbClusters,
  };
}
