/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AnnotationLearningScope {
    UNDETERMINED = 'UNDETERMINED',
    PROJECT_SPECIFIC = 'PROJECT_SPECIFIC',
    MODEL_LEARNING = 'MODEL_LEARNING',
}
