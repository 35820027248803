import { FrequencyGraphPage } from "./components/frequencyGraph/FrequencyGraphPage";
import React from "react";
import { ProjectBreadcrumb } from "./components/components";
import { useLocation } from "react-router-dom";

export const ProjectFrequencyGraphPage = () => {
  const location = useLocation();

  return (
    <>
      <ProjectBreadcrumb
        breadcrumb={[{ title: "Graphe sémantique", link: location.pathname }]}
      />
      <div className={"pl-4"}>
        <FrequencyGraphPage />
      </div>
    </>
  );
};
