import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFewShotTaggingModelApi } from "./Api";
import { Breadcrumb } from "flowbite-react";
import { YMIframe } from "../project/components/components";
import { YMCard, YMLoader } from "../../ym-components";

const useModelId = () => {
  const [searchParams, _] = useSearchParams();
  const [modelId, setModelId] = useState("" as string | undefined);
  const [modelName, setModelName] = useState("" as string);
  // moke model name to display in breadcrumb
  useEffect(() => {
    if (searchParams.get("name")) {
      setModelName(searchParams.get("name") as string);
    }
    if (searchParams.get("id")) {
      setModelId(searchParams.get("id") as string);
    }
  }, [searchParams]);

  return { modelId, modelName };
};

export default function FewShotLearningModelView(props: any) {

  const {modelId, modelName} = useModelId();
  const api = useFewShotTaggingModelApi();
    const navigate = useNavigate();


  const model = useQuery({
    queryKey: ["fewshot-learning-model", modelId],
    queryFn: async () => {
      if (modelId) {
        return api.getModel(modelId);
      }
    },
    enabled: !!modelId,
  })

  return (
      <div>
      <Breadcrumb
        className="bg-gray-50 py-3 px-5 dark:bg-gray-900"
      >
        <Breadcrumb.Item>
          <a
              className={"cursor-pointer hover:text-indigo-900"}
              onClick={() => navigate("/few_shot_learning_models")}
          >
            {" "}
            Few Shot Learning Models
            {" "}
          </a>
        </Breadcrumb.Item>
        {modelId && (
          <Breadcrumb.Item className={"capitalize"}>
            {modelName}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
        <YMLoader message={"Chargement du modèle"} loading={model.isFetching && !model.isFetched}>
        <div>
          <YMCard>
              <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900"> Carte des verbatims </h3>
              <p className={"text-indigo-800 max-w-4xl"}>
Cette carte permet de se représenter visuellement les verbatims d'entraînement tel que vu par le modèle de classification.
              </p>
              <YMIframe
                  src={model.data?.trainingDataMap}
                  style={{ width: "100%", height: "700px" }}
              />
            </YMCard>
        </div>
        </YMLoader>
    </div>
  )
}
