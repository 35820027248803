/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InfraService } from '../models/InfraService';
import type { InfraServiceStatus } from '../models/InfraServiceStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InfraApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Status
     * @param service
     * @returns InfraServiceStatus Successful Response
     * @throws ApiError
     */
    public getStatusInfraStatsServicePost(
        service: InfraService,
    ): CancelablePromise<InfraServiceStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/infra/stats/{service}',
            path: {
                'service': service,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restarts
     * @param service
     * @returns any Successful Response
     * @throws ApiError
     */
    public restartsInfraRestartsServicePost(
        service: InfraService,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/infra/restarts/{service}',
            path: {
                'service': service,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
