import { useApi } from "../api";
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { YMTable } from "../ym-components";

export default function Events(props: any) {
  const { api } = useApi();
  const { aggregateId } = useParams();
  const [events, setEvents] = useState(null as null | Array<any>);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!api) return;
    if (aggregateId) {
      api.get_events(aggregateId).then((data: any[]) => {
        setEvents(data);
        setLoading(false);
      });
    } else {
      api.get_last_events().then((data: any[]) => {
        setEvents(data);
        setLoading(false);
      });
    }
  }, [api, aggregateId]);

  if (loading) {
    return <div className="w-96 mx-auto mt-28">loading events..</div>;
  }

  const humanTitle = (camelcase: string) => {
    const result = camelcase.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  return (
    <div>
      <Breadcrumb
        aria-label="Solid background breadcrumb example"
        className="bg-gray-50 py-3 px-5 dark:bg-gray-900"
      >
        <Breadcrumb.Item>Events</Breadcrumb.Item>
        {aggregateId && <Breadcrumb.Item>{aggregateId}</Breadcrumb.Item>}
      </Breadcrumb>
      <div className="mx-auto mt-28">
        <div className="format mb-10">
          <h3> Last Events </h3>
        </div>
        <YMTable
          header={
            <tr>
              <th scope="col" className="px-6 py-3">
                Event
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Data
              </th>
            </tr>
          }
          rows={(events || []).map((e, i) => (
            <tr
              key={e.id}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
            >
              <td
                scope="row"
                className="w-44 px-6 py-4 text-gray-900  hover:text-indigo-900 dark:text-white"
              >
                {humanTitle(e.kind)}
              </td>
              <td className="px-6 py-4 flex gap-2">
                #{e.version} - {e.date}
              </td>
              <td className={"pt-4"}>
                <div className={"flex flex-col"}>
                  {Object.keys(e).map((k, i) => (
                    <div>
                      <b>{k}</b>: {JSON.stringify(e[k]).substring(0, 340)}
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        />
      </div>
    </div>
  );
}
