import React, { useState } from "react";
import { POS } from "../utils";
import { LexiquePatterns } from "./State";
import ProjectPatternsFilter from "./PatternsFilter";
import LexiconTable from "./LexiconTable";

export default function ProjectPatterns(props: any) {
  const [searchParams, setSearchParams] = useState({
    posPatterns: LexiquePatterns,
    term: "",
    minMaxOccurrence: [1, 10000] as [number, number],
    enableParsing: false,
    semanticSearch: false,
  });
  return (
    <>
      <div className="relative overflow-x-auto">
        <ProjectPatternsFilter
          onSearch={(
            patterns: POS[][],
            minMaxOccurrence: [number, number],
            term: string,
            enableParsing: boolean
          ) => {
            setSearchParams({
              posPatterns: patterns,
              term,
              minMaxOccurrence,
              enableParsing,
              semanticSearch: false,
            });
          }}
        />
        <LexiconTable searchParams={searchParams} />
      </div>
    </>
  );
}
