/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ColorName {
    SLATE = 'slate',
    GRAY = 'gray',
    ZINC = 'zinc',
    NEUTRAL = 'neutral',
    STONE = 'stone',
    RED = 'red',
    ORANGE = 'orange',
    AMBER = 'amber',
    YELLOW = 'yellow',
    LIME = 'lime',
    GREEN = 'green',
    EMERALD = 'emerald',
    TEAL = 'teal',
    CYAN = 'cyan',
    BLUE = 'blue',
    INDIGO = 'indigo',
    VIOLET = 'violet',
    PURPLE = 'purple',
    FUCHSIA = 'fuchsia',
    PINK = 'pink',
    ROSE = 'rose',
}
