import { GiArmoredBoomerang } from "react-icons/gi";
import React from "react";

export const CardHeader = () => {
  return (
    <div className={"flex justify-between pr-20"}>
      <div className={""}>
        <h2 className={"text-indigo-800 mb-1 text-2xl font-bold"}>
          <GiArmoredBoomerang className={"inline h-6 w-6"} /> Few Shot Tagging
        </h2>
        <p className={"text-indigo-900"}>
          Tagguer les verbatims de votre projet en utilisant des raccourcis
          clavier. Une fois quelques verbatims taggués, vous pourrez entraîner
          un modèle de few-shot learning pour généraliser les tags à l'ensemble
          des verbatims. Vous pouvez ensuite corriger les résultats du modèle et
          les ré-entraîner pour améliorer la qualité des tags.
        </p>
      </div>
    </div>
  );
};