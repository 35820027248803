/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocumentState {
    UPLOADED = 'UPLOADED',
    READ = 'READ',
    PARSED = 'PARSED',
}
