/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExportType {
    DOCUMENT = 'DOCUMENT',
    FST_2D_MAP = 'FST_2D_MAP',
}
