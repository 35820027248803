import { useReducer } from "react";
import { FewShotLearningReducer, initialComponentState } from "./Reducer";
import {
  ClassificationMethod,
  ProjectDimension,
  VerbatimPaginated,
} from "../../../app_client";
import { useQuery } from "@tanstack/react-query";

interface projectFewShotLearningModeDependencies {
  fetchVerbatims: (
    projectId: string,
    dimension: string
  ) => Promise<VerbatimPaginated>;
  triggerFewShotLearning: (
    projectId: string,
    dimension_id: string,
    model_name: string | undefined,
    model_type: ClassificationMethod | undefined,
    embedding_model: string | undefined,
    updateDimensionModel: boolean
  ) => Promise<void>;
  putVerbatimTags: (
    projectId: string,
    verbatimId: string,
    dimension: string,
    tags: string[]
  ) => Promise<void>;
}

export const useProjectFewShotLearningModel = (
  deps: projectFewShotLearningModeDependencies
) => {
  const [state, dispatch] = useReducer(
    FewShotLearningReducer,
    initialComponentState
  );

  let _ProjectVerbatims = useQuery({
    queryKey: [
      "_ProjectVerbatimsForFewShotTagging",
      state.projectId,
      state.selectedDimensionName,
    ],
    queryFn: ({ queryKey }) => {
      let [_, projectId, dimension] = queryKey;
      if (!projectId || !dimension) return Promise.resolve(null);
      return deps.fetchVerbatims(projectId, dimension);
    },
    onSuccess: (data) => {
      if (data) dispatch({ type: "VERBATIMS_FETCHED", payload: data });
    },
    enabled: !!state.projectId && !!state.selectedDimensionName,
  });

  const projectSet = (projectId: string, dimensions: ProjectDimension[], projectTotalVerbatims: number) => {
    if (state.dimensions.length != dimensions.length) 
      dispatch({ type: "PROJECT_SET", payload: { projectId, projectTotalVerbatims, dimensions } });
  };

  const updateDimensionAnalysisStats = (
    dimAnalysisStats: { dimension: string; p: Number }[]
  ) => {
    dispatch({
      type: "DIMENSION_ANALYSIS_STATS_UPDATED",
      payload: dimAnalysisStats,
    });
  };

  const selectDimension = (
    dimensionName: string,
    dimAnalysisStats: { dimension: string; p: Number }[]
  ) => {
    dispatch({ type: "DIMENSION_SELECTED", payload: { dimensionName } });
    dispatch({
      type: "DIMENSION_ANALYSIS_STATS_UPDATED",
      payload: dimAnalysisStats,
    });
  };

  const onTagAVerbatim = () => {
    if (!state.activeVerbatim) return;
    if (!state.projectId) return;
    if (!state.selectedDimensionName) return;

    dispatch({ type: "VERBATIM_TAGGED" });
    deps.putVerbatimTags(
      state.projectId,
      state.activeVerbatim.id,
      state.selectedDimensionName,
      state.activeClasses
    );

    if (state.verbatims?.data.length === 1) {
      _ProjectVerbatims.refetch();
    }
  };

  const onKeyPress = (key: string) => {
    if (key === "Enter") {
      return onTagAVerbatim();
    }
    dispatch({ type: "KEY_PRESSED", payload: { key } });
  };

  const toggleClass = (classe: string) => {
    dispatch({ type: "CLASS_TOGGLED", payload: { className: classe } });
  };

  const createFewShotModel = (modelName: string, modelType: ClassificationMethod, embeddingModel: string) => {
    if (!state.projectId) return;
    if (!state.selectedDimension) return;

    deps.triggerFewShotLearning(
        state.projectId,
        state.selectedDimension.id,
        modelName,
        modelType,
        embeddingModel,
        false
    )

    dispatch({ type: "MODEL_CREATED_OR_UPDATED" });
  }

  const updateFewShotModel = () => {
    if (!state.projectId) return;
    if (!state.selectedDimension) return;

    deps.triggerFewShotLearning(
        state.projectId,
        state.selectedDimension.id,
        undefined,
        undefined,
        undefined,
        true
    )

    dispatch({ type: "MODEL_CREATED_OR_UPDATED" });
  }

  const actions = {
    projectSet,
    updateDimensionAnalysisStats,
    selectDimension,
    onKeyPress,
    toggleClass,
    createFewShotModel,
    updateFewShotModel
  };

  return {
    state: {
      ...state,
      verbatimsIsFetching: _ProjectVerbatims.isFetching,
      verbatimsIsFetched: _ProjectVerbatims.isFetched,
    },
    dispatch,
    actions,
  };
};

export type FewShotLearningComponentModel = ReturnType<typeof useProjectFewShotLearningModel>;