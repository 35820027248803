import React, {useContext, useEffect, useState} from "react";
import {ModelType, WorkspaceModel} from "../../../app_client";
import {useNavigate} from "react-router-dom";
import {YMButton, YMCard, YMInput, YMTable} from "../../../ym-components";
import {Spinner, Tabs} from "flowbite-react";
import {SiDatabricks} from "react-icons/si";
import {useProject, useProjectApi} from "../State";
import {notificationDismiss, notificationError, notificationInfo,} from "../../../toast-notification";
import {AuthUserContext} from "../../../AuthUserContext";
import {tabStyle} from "../../../style";
import ConfigureGliner, {GlinerDimension} from "./ConfigureGliner";

type importableModel =
    | WorkspaceModel
    | { model_id: string; name: string; type: "PRESET", model_status: "ready" };

export default function ImportModels(props: any) {
    const {projectId, _ProjectAnalysisStats} = useProject();
    const projectApi = useProjectApi();
    const {workspaceId} = useContext(AuthUserContext) as {
        workspaceId: string;
    };
    const [models, setModels] = useState<importableModel[] | "loading">(
        "loading"
    );
    const navigate = useNavigate();

    const [fstParams, setFstParams] = useState({
        threshold: 0.5,
        dimName: "",
     } as { threshold: number; dimName: string })


    // Fetch models on component mount
    useEffect(() => {
        if (projectApi && workspaceId) {
            projectApi.getWorkspaceModels(workspaceId).then(setModels);
        }
    }, [projectApi, workspaceId]);

    const handleImport = async (model: importableModel) => {
        if (projectApi && projectId) {
            if (model.type === ModelType.PATTERN_MATCHING)
                await notifyAndImportModel(
                    model,
                    projectApi.importPatternMatchingModel
                );
            if (model.type === ModelType.FEWSHOOT_TAGGING)

                // @ts-ignore
                if (model.model_status !== 'READY') {
                    notificationError(
                `Le modèle ${model.name} ne peut pas être importé car il n'est entraîné. Veuillez réessayer plus tard. Ou bien relancer l'entraînement du modèle dans l'onglet modèle.`,
                {duration: 10000}
                    );
                } else {
                    await notifyAndImportModel(model, projectApi.importFewShotTaggingModel, {
                    threshold: fstParams.threshold,
                    dimName: fstParams.dimName,
                });
                }


            if (model.type === "PRESET")
                await notifyAndImportModel(model, projectApi.importPresetModel);
        }
    };

    const handleGlinerTrigger = async (dimensions: GlinerDimension[]) => {
        if (projectApi && projectId) {
            await notifyAndImportModel(
                {
                    model_id: "finetuned_gliNER",
                    name: "Finetuned gliNER",
                    type: "PRESET",
                    model_status: "ready",
                },
                projectApi.importPresetModel,
                {
                    gliNER: {
                        dimensions: dimensions,
                    },
                }
            );
        }
    };

    const notifyAndImportModel = async (
        model: importableModel,
        call: (
            projectId: string,
            modelId: string,
            modelName: string,
            modelParams?: any
        ) => Promise<any>,
        params?: any
    ) => {
        if (projectApi && projectId) {
            let notificationid = notificationInfo(
                `L'analyse du project avec le modèle ${model.name} va bientôt démarrer..`,
                {duration: 10000}
            );
            await call(projectId, model.model_id, model.name, params);
            notificationDismiss(notificationid);
            notificationInfo(
                `L'analyse du project avec le modèle ${model.name} est en cours.`
            );
            setTimeout(() => {
                navigate("/project/" + projectId + "/");
                _ProjectAnalysisStats.refetch();
            }, 1000);
        }
    };

    return (
        <div>
            <YMCard>
                <div className="flex flex-col w-100">
                    <div className="format">
                        <h2 className="text-indigo-900">
                            <SiDatabricks className="inline"/> Modèles
                        </h2>
                        <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
                            Ajouter une description ici ....
                        </p>
                    </div>
                </div>
            </YMCard>
            {models === "loading" ? (
                <div className="w-100 mt-10 text-center">
                    <Spinner color="gray" size="xl" aria-label="Default status example"/>
                    <div className="mt-4 text-gray-400">fetching models</div>
                </div>
            ) : models.length === 0 ? (
                <div className="w-100 mt-5 text-center">
                    <p className="text-gray-500 mb-4">No models available.</p>
                </div>
            ) : (
                <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
                    <Tabs aria-label="Default tabs" theme={tabStyle}>
                        <Tabs.Item active title="Modèles Pattern Matching">
                             <YMCard>
                                    <div className="flex flex-col w-100">
                                        <div className="format mb-4">
                                            <h2 className="text-indigo-900">
                                                <SiDatabricks className="inline"/> Modèles historiques
                                            </h2>
                                        </div>
                                        <ModelTable
                                            models={[
                                                { model_id: "commons-ym-opinion_1", name: "sentiments", type: ModelType.PATTERN_MATCHING},
                                                { model_id: "commons-ym-personnaes_1", name: "publics", type: ModelType.PATTERN_MATCHING},
                                                { model_id: "commons-ym-univers_1", name: "univers", type: ModelType.PATTERN_MATCHING},
                                                { model_id: "commons-ym-values_1", name: "valeurs", type: ModelType.PATTERN_MATCHING},
                                            ]}
                                            onImport={handleImport}
                                        />
                                    </div>
                                </YMCard>
                            <ModelTable
                                models={models.filter(
                                    (m) => m.type === ModelType.PATTERN_MATCHING
                                )}
                                onImport={handleImport}
                            />

                        </Tabs.Item>
                        <Tabs.Item active title="Modèles FST">
                            <YMCard>
                                <div className="mb-2 font-bold text-indigo-900 text-2xl">Configuration FST</div>
                                <p className={"text-indigo-900 mb-8"}>
                                    Les modèles FST sont des modèles de classification de texte qui retournent une probabilité
                                    pour chaque classe prédite. Vous pouvez définir un seuil pour la classification.
                                </p>
                                    <div className={"flex flex-row gap-4"}>
                                        <div>
                                        <YMInput
                                            value={fstParams.threshold}
                                            label={"Threshold"}
                                            onChange={(e) => setFstParams({...fstParams, threshold: parseFloat(e)})}
                                            type={"number"}
                                            min={0}
                                            max={1}
                                            step={0.01}/>
                                            </div>
                                        <div>
                                        <YMInput value={fstParams.dimName} label={"Nom"} onChange={(e) => setFstParams({...fstParams, dimName: e})}/>
                                            <p className={"text-gray-500 text-xs"}> Laissez vide pour utiliser le nom du modèle </p>
                                            </div>
                                    </div>
                            </YMCard>
                            <ModelTable
                                models={models.filter(
                                    (m) => m.type === ModelType.FEWSHOOT_TAGGING
                                )}
                                onImport={handleImport}
                            />
                        </Tabs.Item>
                        <Tabs.Item active title="Autres Modèles">
                            <div className={"m-4"}>
                                <YMCard>
                                    <div className="flex flex-col w-100">
                                        <div className="format mb-4">
                                            <h2 className="text-indigo-900">
                                                <SiDatabricks className="inline"/> FineTuned GliNER
                                            </h2>
                                            <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
                                                Lancer une analyse gliNER avec un prompt personnalisé
                                            </p>
                                        </div>
                                    </div>
                                    <ConfigureGliner onTrigger={handleGlinerTrigger}/>
                                </YMCard>

                                <div className={"mt-4"}></div>

                                <YMCard>
                                    <div className="flex flex-col w-100">
                                        <div className="format mb-4">
                                            <h2 className="text-indigo-900">
                                                <SiDatabricks className="inline"/> Modèles
                                                préconfigurés
                                            </h2>
                                            <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
                                                Les modèles préconfigurés sont prêts à l'emploi
                                            </p>
                                        </div>
                                        <ModelTable
                                            models={[
                                                {
                                                    model_id: "polarity_1",
                                                    name: "Sentiments",
                                                    type: "PRESET",
                                                    model_status: "ready",
                                                },
                                                {
                                                    model_id: "jugement_1",
                                                    name: "Jugement",
                                                    type: "PRESET",
                                                    model_status: "ready",
                                                },
                                                {
                                                    model_id: "aspiration_1",
                                                    name: "Aspirations",
                                                    type: "PRESET",
                                                    model_status: "ready",
                                                },
                                                {
                                                    model_id: "intensité_1",
                                                    name: "Intensité",
                                                    type: "PRESET",
                                                    model_status: "ready",
                                                },
                                                {
                                                    model_id: "default_nerFR_gliNER",
                                                    name: "GliNER (Personnes, Lieux, Organisations)",
                                                    type: "PRESET",
                                                    model_status: "ready",
                                                },
                                            ]}
                                            onImport={handleImport}
                                        />
                                    </div>
                                </YMCard>
                            </div>
                        </Tabs.Item>
                    </Tabs>
                </div>
            )}
        </div>
    );
}

const ModelTable = ({
                        models,
                        onImport,
                    }: {
    models: importableModel[];
    onImport: (model: importableModel) => void;
}) => {
    const {projectId} = useProject();
    const navigate = useNavigate();
    const handleOnClickModel = (modelId: string, modelName: string) => {
        navigate(
            (projectId ? `/project/${projectId}` : "") +
            `/pattern_matching_models/edit?id=${modelId}&name=${modelName}`
        );
    };

    if (models.length === 0)
        return (
            <div className={"text-gray-500 pl-4 pt-2"}>Aucun modèle disponible</div>
        );

    return (
        <YMTable
            header={
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Models
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only"> x </span>
                    </th>
                </tr>
            }
            rows={models.map((m) => (
                <tr
                    key={m.model_id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                >
                    <td
                        scope="row"
                        className="px-6 py-4 text-indigo-900 cursor-pointer capitalize font-bold max-w-[48px] hover:text-indigo-900 dark:text-white"
                        onClick={() => handleOnClickModel(m.model_id, m.name)}
                    >
                        <div className="flex flex-row space-x-4">
                            <div>{m.name} </div>
                        </div>
                    </td>
                    <td className="px-6 py-4 flex gap-2">
                        <YMButton size="xs" text={"importer"} onClick={() => onImport(m)}/>
                    </td>
                </tr>
            ))}
        />
    );
};
