import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProjectBreadcrumb } from "./components/components";
import { VerbatimList } from "./components/verbatims/VerbatimLists";

export default function ProjectVerbatimsPage(props: any) {
  const location = useLocation();

  const [lemmasFilter, setLemmasFilter] = useState("");
  const [posFilter, setPosFilter] = useState("");
  const [dimensionFilter, setDimensionFilter] = useState("");
  const [classNameFilter, setClassNameFilter] = useState("");
  const [subsetFilter, setSubsetFilter] = useState("");
  const [graphDimensionsFilter, setGraphDimensionsFilter] = useState("");
  const [graphNodesFilter, setGraphNodesFilter] = useState("");

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    setLemmasFilter(params.get("lemmas") || "");
    setPosFilter(params.get("pos") || "");
    setClassNameFilter(params.get("classname") || "");
    setDimensionFilter(params.get("dimension") || "");
    setSubsetFilter(params.get("subset") || "");
    setGraphNodesFilter(params.get("graph_nodes") || "");
    setGraphDimensionsFilter(params.get("graph_dimensions") || "");
  }, [location]);

  return (
    <div className="relative overflow-x-auto">
      <ProjectBreadcrumb
        breadcrumb={[{ title: "Verbatims", link: location.pathname }]}
      />
      <VerbatimList
        lemmasFilter={lemmasFilter}
        posFilter={posFilter}
        classNameFilter={classNameFilter}
        dimensionFilter={dimensionFilter}
        subsetFilter={subsetFilter}
        graphNodesFilter={graphNodesFilter}
        graphDimensionsFilter={graphDimensionsFilter}
      />
    </div>
  );
}
