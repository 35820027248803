import {useWorkspaceApi} from "./Api";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Permissions, WorkspaceTokenMove, WorkspaceTokenMoveReason, WorkspaceView} from "../../app_client";
import {Badge, Spinner} from "flowbite-react";
import {YMBreadCrumb, YMButton, YMCard, YMInput, YMTable} from "../../ym-components";
import {useParams} from "react-router-dom";
import {AuthUserContext} from "../../AuthUserContext";
import {AiOutlineMail, AiOutlineShoppingCart} from "react-icons/ai";


export default function Workspace() {

    const tokenContext = useContext(AuthUserContext);
    const { permissions } = tokenContext;
    const workspaceApi = useWorkspaceApi();

    // Workspace information
    const [workspace, setWorkspace] = useState<WorkspaceView | "loading">("loading");
    const {workspaceId} = useParams();

    // Workspace credit
    const [workspaceTokenCredit, setWorkspaceTokenCredit] = useState<number>(0);
    const [workspaceCreditLoading, setWorkspaceCreditLoading] = useState<boolean>(false);
    const handleCreditWorkspace = useCallback(() => {
        if (!workspaceApi || !workspaceId) return;
        setWorkspaceCreditLoading(true);
        workspaceApi.creditWorkspace(workspaceId, workspaceTokenCredit).then(() => {
            setTimeout(() => {
                setWorkspaceTokenCredit(0);
                setWorkspaceCreditLoading(false);
                workspaceApi.getWorkspace(workspaceId).then(setWorkspace);
                workspaceApi.getWorkspaceTokenMoves(workspaceId).then(setWorkspaceTokenMoves);
            }, 5000);
        });
    }, [workspaceApi, workspaceId, workspaceTokenCredit]);

    // Workspace token moves
    const [workspaceTokenMoves, setWorkspaceTokenMoves] = useState<WorkspaceTokenMove[]>([]);
    const [workspaceTokenMovesLoading, setWorkspaceTokenMovesLoading] = useState<boolean>(false);

    // Workspace portal url
    const [workspacePortalUrl, setWorkspacePortalUrl] = useState<string>("");

    // Workspace invitation
    const [workspaceInvitationUrl, setWorkspaceInvitationUrl] = useState<string>("");
    const handleCopyInvitationUrl = useCallback(() => {
        navigator.clipboard.writeText(workspaceInvitationUrl);
    }, [workspaceInvitationUrl]);

    useEffect(() => {
        if (workspaceApi && workspaceId) {
            workspaceApi.getWorkspace(workspaceId).then(setWorkspace);
            workspaceApi.getWorkspaceTokenMoves(workspaceId).then(moves => {
                setWorkspaceTokenMoves(moves);
                setWorkspaceTokenMovesLoading(false);
            })
            workspaceApi.getPortalUrl(workspaceId).then(setWorkspacePortalUrl);
            workspaceApi.getInvitationUrl(workspaceId).then(setWorkspaceInvitationUrl);
        }
    }, [workspaceApi, workspaceId]);
    const isWorkspaceAdmin = permissions?.includes(Permissions.WORKSPACE_FULL)



    return <div>

        { isWorkspaceAdmin &&  <YMBreadCrumb links={[
            {title:"Workspaces", link:"/workspaces"},
            {title:workspace == 'loading' ? '...' : workspace.owner_name, link:"/workspaces"}
        ]}/> }

        {workspace === "loading" && <div className="w-100 mt-10 text-center">
          <Spinner color="gray" size="xl"/>
          <div className="mt-4 text-gray-400"> Chargement du workspace ... </div>
        </div>}

        {workspace != 'loading' && <div className="w-100 mt-5">

            <YMCard>
                <div className={"format max-w-4xl"}>
                    <h2 className={"text-left text-indigo-900"}>
                        Espace de travail de {workspace.owner_name}
                    </h2>
                </div>

                <div className={"text-indigo-900 mt-2"}>
                    <div> Lien d'invitation à l'espace de travail : </div>
                    <div className={"flex flex-wrap"}>
                        <Badge icon={AiOutlineMail} onClick={handleCopyInvitationUrl}>
                            {workspaceInvitationUrl}
                        </Badge>
                    </div>
                </div>

                <div className={"w-96 mt-8 flex items-end gap-2"}>
                    <div className={"text-indigo-900 font-bold"}> Nombre de tokens disponibles</div>
                    <div> {workspace.token_balance.toLocaleString()} </div>
                </div>

                <div className={"w-96 mt-8 flex items-end gap-2"}>
                    <YMButton
                        text={"Gérer mon abonnement"}
                        href={workspacePortalUrl}
                        onClick={() => {
                        }}
                        icon={<AiOutlineShoppingCart className={"h-5 w-5 mr-2"}/>}
                    />
                </div>

                {isWorkspaceAdmin && <div className={"w-96 mt-8 flex items-end gap-2"}>

                    <YMInput
                        label={"Tokens"}
                        type={"number"}
                        value={workspaceTokenCredit}
                        onChange={e => setWorkspaceTokenCredit(parseInt(e))}
                    />

                    <YMButton
                        text={workspaceCreditLoading ? "Opération en cours ..." : "Créditer"}
                        disabled={workspaceCreditLoading}
                        onClick={handleCreditWorkspace}
                    />

                </div> }

            {workspaceTokenMovesLoading && <div className="w-100 mt-10 text-center">
                <Spinner color="gray" size="xl"/>
                <div className="mt-4 text-gray-400"> Chargement de votre consommation détaillée </div>
            </div>}

            {!workspaceTokenMovesLoading && <div className={"mt-10"}>
                <h3 className={"text-indigo-900 text-xl text-left mb-5 font-bold"}> Consommation détaillée </h3>
                <YMTable
                header={
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                           Origine
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Montant
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date
                        </th>
                    </tr>
                }
                rows={workspaceTokenMoves.map(move => <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100">
                    <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-900 flex">
                            {move.reason == WorkspaceTokenMoveReason.TOKEN_CREDITED && <Badge color={"green"}>Crédit</Badge>}
                            {move.reason == WorkspaceTokenMoveReason.CHUNK_DEBITED && <Badge color={"red"}>Débit</Badge>}
                        </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{move.source}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{move.count.toLocaleString()}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{(new Date(move.date)).toLocaleDateString()}</div>
                    </td>
                </tr>)
                }
            /></div>}
                        </YMCard>

        </div>}


    </div>

}