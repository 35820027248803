import React, { useState } from "react";
import { Textarea, TextInput } from "flowbite-react";
import { YMButton, YMTinyButton } from "../../../ym-components";
import { HiPlus, HiTrash } from "react-icons/hi";

export type GlinerDimension = {
  name: string;
  labels: string;
};

export const ConfigureGliner = ({onTrigger}: {
  onTrigger: (dimensions: GlinerDimension[]) => void
}) => {
  const [dimensions, setDimensions] = useState<GlinerDimension[]>([
    { name: "", labels: "" },
  ]);

  const handleAddDimension = () => {
    setDimensions([...dimensions, { name: "", labels: "" }]);
  };

  const handleDimensionChange = (index: number, value: string) => {
    const updatedDimensions = dimensions.map((dim, i) =>
      i === index ? { ...dim, name: value } : dim
    );
    setDimensions(updatedDimensions);
  };

  const handleLabelsChange = (index: number, value: string) => {
    const updatedDimensions = dimensions.map((dim, i) =>
      i === index ? { ...dim, labels: value } : dim
    );
    setDimensions(updatedDimensions);
  };

  const handleDeleteDimension = (index: number) => {
    const updatedDimensions = dimensions.filter((_, i) => i !== index);
    setDimensions(updatedDimensions);
  };

  const handleSubmit = () => {
    onTrigger(dimensions.filter((dim) => dim.name.length > 0 && dim.labels.length > 0));
  };

  return (
    <div>
      {dimensions.length > 0 && <div className="my-4 font-bold text-indigo-900 text-2xl">Dimensions</div> }
      {dimensions.length == 0 && <div className="my-4 text-gray-500">Créez une dimension pour commencer</div> }
      {dimensions.length > 0  && dimensions.map((dimension, index) => (
        <div key={index} className="flex flex-col mt-2 mb-4">
          <TextInput
            className="mb-2"
            placeholder="Dimension (Lieux)"
            value={dimension.name}
            onChange={(e) => handleDimensionChange(index, e.target.value)}
          />
          <Textarea
            className="mb-2"
            placeholder="Prompt (Théâtre, Cinéma, Musée))"
            value={dimension.labels}
            onChange={(e) => handleLabelsChange(index, e.target.value)}
          />
          <div className={"flex justify-end"}>
            <YMTinyButton
              text={"Supprimer"}
              color={"light"}
              onClick={() => handleDeleteDimension(index)}
              icon={<HiTrash className="h-5 w-5 mr-2" />}
            />
          </div>
        </div>
      ))}
      <div className={"flex justify-between"}>
        <YMButton
          size="xs"
          text="Ajouter une dimension"
          icon={<HiPlus className="h-5 w-5 mr-2" />}
          onClick={handleAddDimension}
        />
        <YMButton onClick={handleSubmit} disabled={dimensions.length === 0 && dimensions[0].name.length > 0 && dimensions[0].labels.length > 0} text={"Lancer l'analyse GliNER"} />
      </div>
    </div>
  );
};

export default ConfigureGliner;
