import { useApi } from "../../../api";
import { ProjectApi } from "../State";
import { ClassificationMethod } from "../../../app_client";

export const useFewShotTaggingApi = (
  api: ReturnType<typeof useApi>["api"],
  projectApi: ProjectApi
) => {
  return {
    fetchVerbatims: (projectId: string, dimension: string) => {
      return api.list_verbatims(
        projectId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        dimension,
        1
      );
    },
    triggerFewShotLearning: async (
      projectId: string,
      dimension_id: string,
      model_name: string | undefined,
      model_type: ClassificationMethod | undefined,
      embedding_model: string | undefined,
      updateDimensionModel: boolean
    ) => {
      await projectApi.fewShotTag(
        projectId,
        dimension_id,
        model_name,
        model_type,
        embedding_model,
        updateDimensionModel
      );
    },
    putVerbatimTags: async (
      projectId: string,
      verbatimId: string,
      dimension: string,
      tags: string[]
    ) => {
      await projectApi.putVerbatimTags(projectId, verbatimId, dimension, tags);
    },
  };
};
