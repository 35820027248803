import { ProjectFewShotLearningModels } from "../Model";
import { Modal } from "flowbite-react";
import { YMButton } from "../../../ym-components";
import React from "react";
import { ConfigureFile } from "./ConfigureFile";
import { ConfigureModel } from "./ConfigureModel";

export const ModalConfiguration = (props: ProjectFewShotLearningModels) => {
  return (
    <Modal
      size={"8xl"}
      show={props.state.createModalOpened}
      onClose={() => props.dispatch({ type: "CREATE_MODAL_CLOSED" })}
    >
      <Modal.Header>
        {" "}
        Configuration de {props.state.modelName} {props.state.configurationStep}
      </Modal.Header>
      <Modal.Body>
        {props.state.configurationStep === "file" && (
          <ConfigureFile {...props} />
        )}

        {props.state.configurationStep === "model" && (
          <ConfigureModel {...props} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.state.configurationStep === "file" && (
          <YMButton
            text={"Importer les données d'entraînement"}
            onClick={() => props.actions.onConfirmFileConfiguration()}
          />
        )}
        {props.state.configurationStep === "model" && (
          <YMButton
            text={"Entraîner le modèle"}
            onClick={() => props.actions.trainModel()}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
