import React from "react";
import { FewShotLearningComponentModel } from "../Model";

export const InfoMessage = ({ message }: { message: string | null }) => {
  return (
    <div className={"text-gray-500 text-sm justify-self-end "}>
      <div className={"text-center"}>
        <div className={"mt-4 text-gray-400"}>{message}</div>
      </div>
    </div>
  );
};


 export const message = (state: FewShotLearningComponentModel["state"]) => {
    let userHasNotSelectedADimension = !state.selectedDimensionName;
    let fewShotTaggingProcessRunningOnSelectedDimension =
      state.selectedDimensionTaggingProgress === "WAITING" ||
      state.selectedDimensionTaggingProgress === "PROCESSING";
    let taggingIsDoneForThisDimension =
      state.verbatimsIsFetched && state.verbatims?.total === 0;

    if (userHasNotSelectedADimension) {
      return "Veuillez sélectionner une dimension pour tagguer les verbatims.";
    }

    if (fewShotTaggingProcessRunningOnSelectedDimension) {
      return `Le processus de tagging est en cours pour la dimension ${state.selectedDimensionName}.`;
    }

    if (state.verbatimsIsFetching) {
      return `Chargement des verbatims...`;
    }

    if (taggingIsDoneForThisDimension) {
      return `Tous les verbatims ont été taggués.`;
    }

    return null;
  };