import { FewShotLearningComponentModel } from "../Model";
import { YMTinyButton } from "../../../../ym-components";
import { RxMagicWand } from "react-icons/rx";
import {
  JobType,
  ProjectDimension,
  ProjectDimensionStatus,
} from "../../../../app_client";
import React from "react";

export const TrainNewModelButton = ({
  state,
  dispatch,
}: Pick<FewShotLearningComponentModel, "state" | "dispatch">) => {
  return (
    <div>
      <YMTinyButton
        text={" Entraîner un nouveau modèle de few shot tagging"}
        icon={<RxMagicWand className={"mr-2"} />}
        onClick={() => dispatch({ type: "NEW_MODEL_MODAL_TOGGLED" })}
        disabled={
          state.selectedDimensionTaggingProgress !==
          ProjectDimensionStatus.TAGGED
        }
      />
    </div>
  );
};

export const UpdateDimensionModelButton = ({
  state,
  actions,
}: Pick<FewShotLearningComponentModel, "state" | "actions">) => {
  const dimensionWasTaggedByAFewShotModel = (
    dimension: ProjectDimension | undefined
  ): boolean => {
    return Boolean(
      dimension &&
        dimension.job_type == JobType.FEWSHOT_TAGGING &&
        dimension.model_id
    );
  };

  if (!dimensionWasTaggedByAFewShotModel(state.selectedDimension)) return null;

  return (
    <div>
      <YMTinyButton
        text={"Mettre à jours le modèle avec les tags appris"}
        icon={<RxMagicWand className={"mr-2"} />}
        onClick={actions.updateFewShotModel}
        disabled={
          state.selectedDimensionTaggingProgress !==
          ProjectDimensionStatus.TAGGED
        }
      />
    </div>
  );
};
