/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrGetWorkspace } from '../models/CreateOrGetWorkspace';
import type { FewShotTaggingModelView } from '../models/FewShotTaggingModelView';
import type { JoinWorkspaceRequest } from '../models/JoinWorkspaceRequest';
import type { ModelId } from '../models/ModelId';
import type { ModelType } from '../models/ModelType';
import type { NewWorkspaceFewShotTaggingModels } from '../models/NewWorkspaceFewShotTaggingModels';
import type { NewWorkspaceModel } from '../models/NewWorkspaceModel';
import type { NewWorkspaceProject } from '../models/NewWorkspaceProject';
import type { ProjectId } from '../models/ProjectId';
import type { WorkspaceAddCreditToken } from '../models/WorkspaceAddCreditToken';
import type { WorkspaceIdResponse } from '../models/WorkspaceIdResponse';
import type { WorkspaceModel } from '../models/WorkspaceModel';
import type { WorkspaceProject } from '../models/WorkspaceProject';
import type { WorkspaceTokenMove } from '../models/WorkspaceTokenMove';
import type { WorkspaceView } from '../models/WorkspaceView';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkspaceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Workspaces
     * @returns WorkspaceView Successful Response
     * @throws ApiError
     */
    public getWorkspacesWorkspaceGet(): CancelablePromise<Array<WorkspaceView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/',
        });
    }

    /**
     * Create Or Get Workspace
     * @param requestBody
     * @returns WorkspaceIdResponse Successful Response
     * @throws ApiError
     */
    public createOrGetWorkspaceWorkspacePost(
        requestBody: CreateOrGetWorkspace,
    ): CancelablePromise<WorkspaceIdResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Join Workspace
     * @param requestBody
     * @returns WorkspaceIdResponse Successful Response
     * @throws ApiError
     */
    public joinWorkspaceWorkspaceJoinWorkspacePost(
        requestBody: JoinWorkspaceRequest,
    ): CancelablePromise<WorkspaceIdResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/join_workspace',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project
     * @param workspaceId
     * @param requestBody
     * @returns ProjectId Successful Response
     * @throws ApiError
     */
    public createProjectWorkspaceWorkspaceIdProjectPost(
        workspaceId: string,
        requestBody: NewWorkspaceProject,
    ): CancelablePromise<ProjectId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/{workspace_id}/project',
            path: {
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pattern Matching Model
     * @param workspaceId
     * @param requestBody
     * @returns ModelId Successful Response
     * @throws ApiError
     */
    public createPatternMatchingModelWorkspaceWorkspaceIdPatternMatchingModelPost(
        workspaceId: string,
        requestBody: NewWorkspaceModel,
    ): CancelablePromise<ModelId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/{workspace_id}/pattern_matching_model',
            path: {
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Credit Workspace Token
     * @param workspaceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public creditWorkspaceTokenWorkspaceWorkspaceIdCreditTokenPost(
        workspaceId: string,
        requestBody: WorkspaceAddCreditToken,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/{workspace_id}/credit_token',
            path: {
                'workspace_id': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Few Shot Tagging Model
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelPost(
        requestBody: NewWorkspaceFewShotTaggingModels,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/workspace/{workspace_id}/few_shot_tagging_model',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Few Shot Tagging Model
     * @param workspaceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelModelIdDelete(
        workspaceId: string,
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/workspace/{workspace_id}/few_shot_tagging_model/{model_id}/',
            path: {
                'workspace_id': workspaceId,
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace
     * @param workspaceId
     * @returns WorkspaceView Successful Response
     * @throws ApiError
     */
    public getWorkspaceWorkspaceWorkspaceIdGet(
        workspaceId: string,
    ): CancelablePromise<WorkspaceView> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace Name
     * @param workspaceToken
     * @returns string Successful Response
     * @throws ApiError
     */
    public getWorkspaceNameWorkspaceTokenWorkspaceTokenNameGet(
        workspaceToken: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/token/{workspace_token}/name',
            path: {
                'workspace_token': workspaceToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace Invitation Link
     * @param workspaceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getWorkspaceInvitationLinkWorkspaceWorkspaceIdInvitationLinkGet(
        workspaceId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/invitation_link',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace Token Moves
     * @param workspaceId
     * @returns WorkspaceTokenMove Successful Response
     * @throws ApiError
     */
    public getWorkspaceTokenMovesWorkspaceWorkspaceIdTokenMovesGet(
        workspaceId: string,
    ): CancelablePromise<Array<WorkspaceTokenMove>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/token_moves',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Portal
     * @param workspaceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getCustomerPortalWorkspaceWorkspaceIdPaymentPortalGet(
        workspaceId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/payment_portal',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace Projects
     * @param workspaceId
     * @returns WorkspaceProject Successful Response
     * @throws ApiError
     */
    public getWorkspaceProjectsWorkspaceWorkspaceIdProjectsGet(
        workspaceId: string,
    ): CancelablePromise<Array<WorkspaceProject>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/projects',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace Models
     * @param workspaceId
     * @param modeltype
     * @returns WorkspaceModel Successful Response
     * @throws ApiError
     */
    public getWorkspaceModelsWorkspaceWorkspaceIdModelsGet(
        workspaceId: string,
        modeltype?: ModelType,
    ): CancelablePromise<Array<WorkspaceModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/models',
            path: {
                'workspace_id': workspaceId,
            },
            query: {
                'modeltype': modeltype,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Few Shot Tagging Models
     * @param workspaceId
     * @returns FewShotTaggingModelView Successful Response
     * @throws ApiError
     */
    public getFewShotTaggingModelsWorkspaceWorkspaceIdModelsFewShotTaggingGet(
        workspaceId: string,
    ): CancelablePromise<Array<FewShotTaggingModelView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/workspace/{workspace_id}/models/few_shot_tagging',
            path: {
                'workspace_id': workspaceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
