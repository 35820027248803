/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RateLimitInfoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Rate Limit Tokens Timechart
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRateLimitTokensTimechartRateLimitInfoModelPlotRemainingTokensHtmlGet(
        model: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rate_limit_info/{model}/plot_remaining_tokens.html',
            path: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Rate Limit Requests Timechart
     * @param model
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRateLimitRequestsTimechartRateLimitInfoModelPlotRemainingRequestsHtmlGet(
        model: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rate_limit_info/{model}/plot_remaining_requests.html',
            path: {
                'model': model,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
