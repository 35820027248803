import {
  ClassificationMethod,
  FewShotTaggingModelStatus,
  FewShotTaggingModelView,
} from "../../app_client";

export interface NewShotLearningModelsComponentState {
  modelName: string | "";
  selectedFile: File | null;
  selectedFileName?: string;
  selectedFileHasHeader: boolean;
  firstLines: string[][];
  trainingData: string[][];
  selectedFileHeaders: string[];
  formIsValid: boolean;
  createModalOpened: boolean;
  columnVerbatim: string;
  columnLabel: string;
  detectedLabels: string[];
  WorkspaceModels: FewShotTaggingModelView[];
  WorkspaceModelsComputed: FewShotTaggingModelView[];
  configurationStep: "file" | "model";
  modelClassificationMethod: ClassificationMethod;
  modelEmbeddingModel: string;
  disabledModelIds: string[];
  lastModelCreated: FewShotTaggingModelView | null;
}

type workspaceModelFetched = {
  type: "WORKSPACE_MODEL_FETCHED";
  payload: FewShotTaggingModelView[];
};

type nameSet = {
  type: "NAME_SET";
  payload: string;
};

type fileSelected = {
  type: "FILE_SELECTED";
  payload: File;
};

type fileRead = {
  type: "FILE_READ";
  payload: string[][];
};

type createModalOpened = {
  type: "CREATE_MODAL_OPENED";
};

type createModalClosed = {
  type: "CREATE_MODAL_CLOSED";
};

type verbatimColumnSelected = {
  type: "VERBATIM_COLUMN_SELECTED";
  payload: string;
};

type LabelColumnSelected = {
  type: "LABEL_COLUMN_SELECTED";
  payload: string;
};

type fileHasHeaderToggled = {
  type: "FILE_HAS_HEADER_TOGGLED";
};

type configurationStepPassed = {
  type: "CONFIGURATION_STEP_PASSED";
};

type modelClassificationTypeSelected = {
  type: "MODEL_CLASSIFICATION_TYPE_SELECTED";
  payload: ClassificationMethod;
};

type embeddingModelSelected = {
  type: "EMBEDDING_MODEL_SELECTED";
  payload: string;
};

type modelCreated = {
  type: "MODEL_CREATED";
  payload: string;
};

type modelDisabled = {
  type: "MODEL_DISABLED";
  payload: string;
};

type triggerModelTraining = {
  type: "MODEL_TRAINING_TRIGGERED";
  payload: string;
};

export type NewShotLearningModelsComponentAction =
  | workspaceModelFetched
  | nameSet
  | fileSelected
  | fileRead
  | createModalOpened
  | createModalClosed
  | fileHasHeaderToggled
  | verbatimColumnSelected
  | LabelColumnSelected
  | configurationStepPassed
  | embeddingModelSelected
  | modelClassificationTypeSelected
  | modelCreated
  | modelDisabled
  | triggerModelTraining;

export const newShotLearningModelsComponentReducer = (
  state: NewShotLearningModelsComponentState,
  action: NewShotLearningModelsComponentAction
): NewShotLearningModelsComponentState => {
  const reducer = (
    state: NewShotLearningModelsComponentState,
    action: NewShotLearningModelsComponentAction
  ): NewShotLearningModelsComponentState => {
    switch (action.type) {
      case "WORKSPACE_MODEL_FETCHED":
        return {
          ...state,
          WorkspaceModels: action.payload,
        };
      case "MODEL_TRAINING_TRIGGERED":
        return {
          ...state,
          WorkspaceModels: (state.WorkspaceModels||[]).map(w => {
            if (w.model_id === action.payload) {
              return {
                ...w,
                status: FewShotTaggingModelStatus.EMBEDDING_TRAIN_DATA,
              };
            }
            return w;
          })
        };
      case "NAME_SET":
        return {
          ...state,
          modelName: action.payload,
          formIsValid: formIsValid(state),
        };
      case "FILE_SELECTED":
        return {
          ...state,
          selectedFile: action.payload,
          selectedFileName: action.payload.name,
          formIsValid: formIsValid(state),
        };
      case "FILE_READ":
        return {
          ...state,
          firstLines: action.payload,
          selectedFileHasHeader: true,
        };
      case "CREATE_MODAL_OPENED":
        return {
          ...state,
          createModalOpened: true,
          configurationStep: "file",
        };
      case "CREATE_MODAL_CLOSED":
        return {
          ...state,
          createModalOpened: false,
        };
      case "FILE_HAS_HEADER_TOGGLED":
        return {
          ...state,
          columnVerbatim: "",
          columnLabel: "",
          selectedFileHasHeader: !state.selectedFileHasHeader,
        };
      case "VERBATIM_COLUMN_SELECTED":
        return {
          ...state,
          columnVerbatim: action.payload,
        };
      case "LABEL_COLUMN_SELECTED":
        return {
          ...state,
          columnLabel: action.payload,
        };
      case "CONFIGURATION_STEP_PASSED":
        return {
          ...state,
          configurationStep: "model",
        };
      case "MODEL_CLASSIFICATION_TYPE_SELECTED":
        return {
          ...state,
          modelClassificationMethod: action.payload,
        };
      case "EMBEDDING_MODEL_SELECTED":
        return {
          ...state,
          modelEmbeddingModel: action.payload,
        };
      case "MODEL_CREATED":
        return {
          ...state,
          createModalOpened: false,
          modelName: "",
          selectedFile: null,
          lastModelCreated: {
            model_id: action.payload,
            embedding_model: "undefined",
            status: FewShotTaggingModelStatus.EMBEDDING_TRAIN_DATA,
            name: state.modelName,
            training_example_counter: {},
            classification_method: state.modelClassificationMethod,
          },
        };
      case "MODEL_DISABLED":
        return {
          ...state,
          disabledModelIds: [...state.disabledModelIds, action.payload],
        };
      default:
        return state;
    }
  };

  let newState = reducer(state, action);
  newState = {
    ...newState,
    selectedFileHeaders: fileHeaders(newState),
    formIsValid: formIsValid(newState),
    detectedLabels: detectedLabels(newState),
  };
  return {
    ...newState,
    detectedLabels: detectedLabels(newState),
    trainingData: trainingData(newState),
    WorkspaceModelsComputed: computeWorkspaceModels(newState),
  };
};

export const initialNewShotLearningModelsComponentState: NewShotLearningModelsComponentState =
  {
    modelName: "",
    selectedFile: null,
    firstLines: [],
    selectedFileHeaders: [],
    formIsValid: false,
    createModalOpened: false,
    selectedFileHasHeader: false,
    columnVerbatim: "",
    columnLabel: "",
    detectedLabels: [],
    WorkspaceModels: [],
    WorkspaceModelsComputed: [],
    trainingData: [],
    configurationStep: "file",
    modelClassificationMethod: ClassificationMethod.MONO,
    modelEmbeddingModel: "mistral-embed",
    disabledModelIds: [],
    lastModelCreated: null,
  };

let formIsValid = (state: NewShotLearningModelsComponentState): boolean => {
  return state.modelName.length > 5 && state.selectedFile !== null;
};

let fileHeaders = (state: NewShotLearningModelsComponentState): string[] => {
  if (state.firstLines.length === 0) {
    return [];
  } else if (state.selectedFileHasHeader) {
    return state.firstLines[0];
  } else {
    return state.firstLines[0].map((_, i) => `colonne ${numToExcelCol(i)}`);
  }
};

let detectedLabels = (state: NewShotLearningModelsComponentState): string[] => {
  let colIndex = state.selectedFileHeaders.indexOf(state.columnLabel);
  return Array.from(new Set(state.firstLines.map((line) => line[colIndex])));
};

let trainingData = (state: NewShotLearningModelsComponentState): string[][] => {
  let colLabelIndex = state.selectedFileHeaders.indexOf(state.columnLabel);
  let colVerbatimIndex = state.selectedFileHeaders.indexOf(
    state.columnVerbatim
  );
  let data = state.firstLines.map((line) => [
    line[colVerbatimIndex],
    line[colLabelIndex],
  ]);
  if (state.selectedFileHasHeader) {
    return data.slice(1);
  } else {
    return data;
  }
};

let computeWorkspaceModels = (state: NewShotLearningModelsComponentState) => {
  let activeModels = state.WorkspaceModels.filter(
    (m) => state.disabledModelIds.indexOf(m.model_id) === -1
  );

  if (state.lastModelCreated && state.lastModelCreated.model_id) {
    if (
      activeModels
        .map((m) => m.model_id)
        .indexOf(state.lastModelCreated.model_id) === -1
    ) {
      return [state.lastModelCreated, ...activeModels];
    } else {
      return activeModels;
    }
  }

  return activeModels;
};

function numToExcelCol(num: number) {
  let colName = "";
  while (num > 0) {
    let remainder = (num - 1) % 26;
    colName = String.fromCharCode(65 + remainder) + colName;
    num = Math.floor((num - remainder) / 26);
  }
  return colName;
}
