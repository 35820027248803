/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FewShotTaggingModelStatus {
    EMBEDDING_TRAIN_DATA = 'embedding_train_data',
    TRAINING = 'training',
    TRAINING_REMOTELY = 'training_remotely',
    READY = 'ready',
    ERROR = 'error',
    DISABLED = 'DISABLED',
}
