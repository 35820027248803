import { VerbatimList, VerbatimListProps } from "./VerbatimLists";
import { Modal } from "flowbite-react";
import { SiCodereview } from "react-icons/si";
import React from "react";

type VerbatimModalProps = VerbatimListProps & {
  show: boolean;
  onClose: () => void;
};

export const VerbatimsModal = ({
  show,
  onClose,
  ...forwardedProps
}: VerbatimModalProps) => {
  return (
    <Modal root={document.body} size={"7xl"} show={show} onClose={onClose}>
      <Modal.Header>
        <div className={"text-2xl font-bold text-indigo-800 flex"}>
          {" "}
          <SiCodereview />
          <div className={"pl-2"}> Verbatims</div>{" "}
        </div>
      </Modal.Header>
      <Modal.Body style={{ height: "75vh" }} className={"overflow-scroll"}>
        <div className={""}>{show && <VerbatimList {...forwardedProps} />}</div>
      </Modal.Body>
    </Modal>
  );
};
