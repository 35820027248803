import { ProjectFewShotLearningModels } from "../Model";
import { YMLabel, YMSelect, YMTable, YMToggle } from "../../../ym-components";
import { Badge } from "flowbite-react";
import React from "react";

export const ConfigureFile = (props: ProjectFewShotLearningModels) => {
  return (
    <>
      <div>
        <p className={"text-indigo-900 pb-4"}>
          {" "}
          Sélectionnez les colonnes d'entraînement{" "}
        </p>

        <div className={"flex items-start flex-row gap-4 "}>
          <YMToggle
            label={"Le fichier contient une en-tête"}
            value={props.state.selectedFileHasHeader}
            onChange={() => props.dispatch({ type: "FILE_HAS_HEADER_TOGGLED" })}
          />
          <div>
            <YMSelect
              label={"Verbatims"}
              value={props.state.columnVerbatim}
              options={[
                { id: "", name: "" },
                ...props.state.selectedFileHeaders.map((header, i) => ({
                  id: header,
                  name: header,
                })),
              ]}
              onChange={(e) =>
                props.dispatch({
                  type: "VERBATIM_COLUMN_SELECTED",
                  payload: e,
                })
              }
            />
          </div>
          <div>
            <YMSelect
              label={"Labels"}
              value={props.state.columnLabel}
              options={[
                { id: "", name: "" },
                ...props.state.selectedFileHeaders.map((header, i) => ({
                  id: header,
                  name: header,
                })),
              ]}
              onChange={(e) =>
                props.dispatch({
                  type: "LABEL_COLUMN_SELECTED",
                  payload: e,
                })
              }
            />
          </div>
          <div>
            <YMLabel text={"Classes détectées"} />
            <div
              style={{ maxWidth: "800px" }}
              className={"flex flex-row flex-wrap"}
            >
              {props.state.detectedLabels.map((label, i) => (
                <Badge key={i} color={"primary"}>
                  {label}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
      {props.state.firstLines.length > 0 && (
        <div
          className="space-y-6 overflow-y-scroll mt-10"
          style={{ height: 400 }}
        >
          <YMTable
            tableClassName={"table-auto"}
            header={
              <tr>
                {props.state.selectedFileHeaders.map((row) => (
                  <th
                    className={"overflow-hidden truncate w-2"}
                    style={{ maxWidth: "7rem" }}
                    scope="col"
                  >
                    {" "}
                    {row}{" "}
                  </th>
                ))}
              </tr>
            }
            rows={props.state.firstLines.map((row, i) => (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
              >
                {row.map((p) => (
                  <td
                    key={p}
                    className={"overflow-hidden truncate w-2 max-w-24"}
                    style={{ maxWidth: "7rem" }}
                  >
                    {" "}
                    {p}{" "}
                  </td>
                ))}
              </tr>
            ))}
          />
        </div>
      )}
    </>
  );
};