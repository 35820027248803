/**
 * An enumeration.
 */
export enum POS {
  ADJ = "ADJ",
  ADP = "ADP",
  ADV = "ADV",
  AUX = "AUX",
  CONJ = "CONJ",
  CCONJ = "CCONJ",
  DET = "DET",
  INTJ = "INTJ",
  NOUN = "NOUN",
  NUM = "NUM",
  PART = "PART",
  PRON = "PRON",
  PROPN = "PROPN",
  PUNCT = "PUNCT",
  SCONJ = "SCONJ",
  SYM = "SYM",
  VERB = "VERB",
  X = "X",
}

type PosType = {
  id: POS;
  name: string;
};
type PosListType = PosType[];
export const POS_names: PosListType = [
  { id: POS.ADJ, name: "Adjective" },
  { id: POS.VERB, name: "Verb" },
  { id: POS.NOUN, name: "Noun" },
  { id: POS.ADV, name: "Adverb" },
  { id: POS.CONJ, name: "Conjunction" },
  { id: POS.DET, name: "Determiner" },
  { id: POS.INTJ, name: "Interjection" },
  { id: POS.NUM, name: "Numeral" },
  { id: POS.PART, name: "Particle" },
  { id: POS.PRON, name: "Pronoun" },
  { id: POS.PROPN, name: "Proper noun" },
  { id: POS.PUNCT, name: "Punctuation" },
  { id: POS.SCONJ, name: "Subordinating conjunction" },
  { id: POS.SYM, name: "Symbol" },
  { id: POS.X, name: "Other" },
];
