import { ProjectDimension, ProjectView, TagType } from "../../../../app_client";
import React, { useEffect, useState } from "react";
import { ModalNewClass } from "../../VerbatimToken";
import { Badge } from "flowbite-react";
import { HiPlus, HiX } from "react-icons/hi";
import { DropDownMenu, DropDownMenuItem } from "../components";
import { MdCreateNewFolder } from "react-icons/md";
import { useProjectApi } from "../../State";

const AddATagMenu = ({
  project,
  handleAddTag,
  tagType,
}: {
  project: ProjectView;
  handleAddTag: (dimension: string, className: string) => void;
  tagType: TagType;
}) => {
  const projectApi = useProjectApi();

  const [open, setOpen] = useState(false);
  let [newClassDimension, setNewClassDimension] = useState(
    null as ProjectDimension | null
  );
  let [displayNewClassNameModal, setDisplayNewClassNameModal] = useState(false);
  const handleAddClassname = (dimension: ProjectDimension | null) => {
    setDisplayNewClassNameModal(true);
    setNewClassDimension(dimension);
  };

  const [singletonClassDimensions, setSingletonClassDimensions] = useState(
    [] as ProjectDimension[]
  );
  const [multiClassDimension, setmultiClassDimension] = useState(
    [] as ProjectDimension[]
  );

  useEffect(() => {
    const isSingletonClass = (dim: ProjectDimension) => dim.mono_class;
    setSingletonClassDimensions(
      project.dimensions?.filter(isSingletonClass) || []
    );
    setmultiClassDimension(
      project.dimensions?.filter((d) => !isSingletonClass(d)) || []
    );
  }, [project]);

  const handleAddClassRequest = (
    classname: string,
    allOccurrences: boolean
  ) => {
    if (newClassDimension) {
      handleAddTag(newClassDimension.name, classname);
      setDisplayNewClassNameModal(false);
    }
  };

  const handleAddDimensionRequest = async (
    dimension: string,
    classname: string,
    allOccurrences: boolean
  ) => {
    if (tagType == TagType.TOKEN_CLASSIFICATION && projectApi) {
      const dimensionid = await projectApi.createPatternMatchingDimension(
        project.id,
        dimension,
        classname
      );
      handleAddTag(dimension, classname);
      setDisplayNewClassNameModal(false);
    }

    if (tagType == TagType.TEXT_CLASSIFICATION && projectApi) {
      handleAddTag(dimension, classname);
      // TODO: create dimension id on the fly
      setDisplayNewClassNameModal(false);
    }
  };

  return (
    <>
      <div className={"relative cursor-pointer"}>
        <ModalNewClass
          dimension_type={tagType}
          display={displayNewClassNameModal}
          dimension={newClassDimension}
          onAddClass={(classname, allOccurrences) =>
            handleAddClassRequest(classname, allOccurrences)
          }
          onAddDimension={(dimension, classname, allOccurrences) =>
            handleAddDimensionRequest(dimension, classname, allOccurrences)
          }
          displayAllOccurrences={false}
          onClose={() => setDisplayNewClassNameModal(false)}
        />
        <Badge color={"indigo"} size="xs" onClick={() => setOpen(!open)}>
          <HiPlus className={"w-3 h-3"} />
        </Badge>
        {open && (
          <div className={"absolute top-6 z-20"}>
            <a onClick={() => setOpen(false)}>
              <HiX />
            </a>

            <DropDownMenu
              color={"indigo"}
              label={
                tagType === TagType.TOKEN_CLASSIFICATION
                  ? "Tagguer ce motif"
                  : "Tagguer ce verbatim"
              }
              items={
                <DropDownMenuItem
                  icon={<MdCreateNewFolder className={"w-3 h-3"} />}
                  color={"indigo"}
                  label={
                    tagType === TagType.TOKEN_CLASSIFICATION
                      ? "Créer un nouveau modèle"
                      : "Créer un modèle verbatim"
                  }
                  onClick={() => handleAddClassname(null)}
                />
              }
            />

            {multiClassDimension
              .filter((d) => d.tag_type === tagType)
              .map((d) => (
                <DropDownMenu
                  key={d.name}
                  color={"indigo"}
                  label={`${d.name}`}
                  items={
                    <>
                      {d.class_names?.map((c) => (
                        <DropDownMenuItem
                          key={c}
                          icon={<HiPlus className={"ml-3 w-3 h-3"} />}
                          color={"indigo"}
                          label={`Ajouter à ${c}`}
                          onClick={() => {
                            handleAddTag(d.name, c);
                            setOpen(false);
                          }}
                        />
                      ))}
                      <DropDownMenuItem
                        icon={<MdCreateNewFolder className={"ml-3 w-3 h-3"} />}
                        color={"indigo"}
                        label={"créer une classe"}
                        onClick={() => handleAddClassname(d)}
                      />
                    </>
                  }
                />
              ))}

            {singletonClassDimensions
              .filter((d) => d.tag_type === tagType)
              .map((d) => (
                <DropDownMenuItem
                  icon={<HiPlus className={"w-3 h-3"} />}
                  color={"indigo"}
                  label={"Ajouter à " + d.name}
                  onClick={() => {
                    handleAddTag(d.name, "");
                    setOpen(false);
                  }}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AddATagMenu;
