import React from "react";
import { ProjectFewShotLearningModels } from "../Model";
import { ClassificationMethod } from "../../../app_client";
import { YMSelect } from "../../../ym-components";

export const FewShotTaggingModelConfiguration = ({
  classificationType,
  embeddingModel,
  onClassificationMethodChange,
  onEmbeddingModelChange,
}: {
  classificationType: ClassificationMethod;
  embeddingModel: string;
  onClassificationMethodChange: (method: ClassificationMethod) => void;
  onEmbeddingModelChange: (name: string) => void;
}) => {
  return (
    <>
      <h3
        className={
          "dark:text-white text-indigo-900 text-md mt-5 mb-2 font-bold"
        }
      >
        {" "}
        Type de classification{" "}
      </h3>

      <div className="flex flex-row gap-4">
        <div
          onClick={() =>
            onClassificationMethodChange(ClassificationMethod.MULTI)
          }
          className={
            "w-1/2 cursor-pointer flex rounded-lg border border-gray-200 shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col " +
            (classificationType === "multi"
              ? "ring ring-indigo-500"
              : "bg-white")
          }
        >
          <div className="flex h-full flex-col justify-center gap-4 p-6">
            <h5 className="text-md font-bold tracking-tight text-indigo-900 dark:text-white">
              Multi-Classe
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              En fonction d'un threshold le modèle choisi les classes les plus
              pertinentes pour chaque verbatims.
            </p>
          </div>
        </div>

        <div
          onClick={() =>
            onClassificationMethodChange(ClassificationMethod.MONO)
          }
          className={
            "w-1/2 flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col " +
            (classificationType === "mono"
              ? "ring ring-indigo-500"
              : "bg-white")
          }
        >
          <div className="flex h-full flex-col justify-center gap-4 p-6">
            <h5 className="text-md font-bold tracking-tight text-indigo-900 dark:text-white">
              Mono-classe
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Pour chaque verbatim le modèle choisi la classe avec la plus forte
              probabilité.
            </p>
          </div>
        </div>
      </div>

      <h3
        className={
          "dark:text-white text-indigo-900 text-md mt-5 mb-2 font-bold"
        }
      >
        {" "}
        Modèle d'embedding{" "}
      </h3>

      <div className="flex flex-row gap-4">
        <YMSelect
          value={embeddingModel}
          label={"Modèle d'embedding"}
          options={[
            { id: "mistral-embed", name: "mistral-embed (Mistral)" },
            {
              id: "text-embedding-ada-002",
              name: "text-embedding-ada-002 (OpenAI)",
            },
            {
              id: "text-embedding-3-small",
              name: "text-embedding-3-small (OpenAI)",
            },
            {
              id: "text-embedding-3-large",
              name: "text-embedding-3-large (OpenAI)",
            },
          ]}
          onChange={onEmbeddingModelChange}
        />
      </div>
    </>
  );
};

export const ConfigureModel = (props: ProjectFewShotLearningModels) => {
  return (
    <div>
      <FewShotTaggingModelConfiguration
        embeddingModel={props.state.modelEmbeddingModel}
        onEmbeddingModelChange={(name) =>
          props.dispatch({ type: "EMBEDDING_MODEL_SELECTED", payload: name })
        }
        classificationType={props.state.modelClassificationMethod}
        onClassificationMethodChange={(method) =>
          props.dispatch({
            type: "MODEL_CLASSIFICATION_TYPE_SELECTED",
            payload: method,
          })
        }
      />
    </div>
  );
};
