import React, { useEffect, useState } from "react";
import { useApi } from "../../api";
import { Language, WorkspaceModel } from "../../app_client";
import { useNavigate } from "react-router-dom";
import {
  YMButton,
  YMCard,
  YMInput,
  YMSelect,
  YMTable,
} from "../../ym-components";
import { Button, Spinner } from "flowbite-react";
import { SiDatabricks } from "react-icons/si";
import { HiTrash } from "react-icons/hi";
import { usePatternMatchingModelApi } from "./Api";

export default function PatternMatchingModelList(props: any) {
  const modelApi = usePatternMatchingModelApi();

  const { api, isAuthenticated } = useApi();
  const [models, setModels] = useState<WorkspaceModel[] | "loading">("loading");
  const navigate = useNavigate();

  const [lang, setLang] = useState<Language>(Language.FR);
  const [creatingModel, setCreatingModel] = useState(false);
  const [newModelName, setNewModelName] = useState("");

  // Fetch models on component mount
  useEffect(() => {
    if (modelApi) {
      modelApi.getModels().then(setModels);
    }
  }, [modelApi]);

  const handleCreateModel = async () => {
    if (newModelName.length < 3) {
      alert("Le nom du modèle doit contenir au moins 3 caractères");
      return;
    }
    if (api && isAuthenticated) {
      setCreatingModel(true);
      modelApi
        .createModel({
          name: newModelName,
          patterns: [],
          language: lang,
        })
        .then(async ({ model_id }) => {
          // timeout to let the time to back to create the model in db
          setTimeout(() => {
            setCreatingModel(false);
            navigate(
              `/pattern_matching_models/edit?id=${model_id}&name=${newModelName}`
            );
          }, 1500);
        });
    }
  };

  const handleDeleteModel = async (model_id: string) => {
    if (api && isAuthenticated) {
      setModels(
        (models == "loading" ? [] : models).filter(
          (m) => m.model_id !== model_id
        )
      );
      api.delete_pattern_matching_model(model_id);
    }
  };

  const handleOnClickModel = (modelId: string, modelName: string) => {
    navigate(`/pattern_matching_models/edit?id=${modelId}&name=${modelName}`);
  };

  return (
    <div>
      <YMCard>
        <div className="flex flex-col w-100">
          <div className="format">
            <h2 className="text-indigo-900">
              <SiDatabricks className="inline" /> Modèles à champs lexicaux
            </h2>
            <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
              Regroupez dans des champs lexicaux des mots et expressions
              similaires. Exemple : les mots "chat", "chien", "oiseau" peuvent
              se regrouper dans le champ lexical "animal".
            </p>
          </div>
          <div className="format mt-3 pt-3 border-t w-100 border-gray-200">
            <h4 className={"text-indigo-800"}> Créer un nouveau modèle </h4>
            <form className="flex flex-col gap-4">
              <div className={"flex flex gap-4"}>
                <div className={"w-1/2"}>
                  <YMInput
                    value={newModelName}
                    label={"Nom du modèle"}
                    onChange={(e) => setNewModelName(e)}
                  />
                </div>

                <YMSelect
                  label={"Langue"}
                  value={lang}
                  options={[
                    { id: Language.EN, name: "Anglais" },
                    { id: Language.FR, name: "Français" },
                  ]}
                  onChange={(v) => setLang(v as Language)}
                />
              </div>
              <div className={"w-20"}>
                <YMButton
                  text={"Créer"}
                  onClick={handleCreateModel}
                  icon={
                    creatingModel ? (
                      <div className={"mr-3"}>
                        <Spinner size="sm" light={true} />
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  disabled={creatingModel ? true : undefined}
                />
              </div>
            </form>
          </div>
        </div>
      </YMCard>
      {models === "loading" ? (
        <div className="w-100 mt-10 text-center">
          <Spinner color="gray" size="xl" aria-label="Default status example" />
          <div className="mt-4 text-gray-400">fetching models</div>
        </div>
      ) : models.length === 0 ? (
        <div className="w-100 mt-5 text-center">
          <p className="text-gray-500 mb-4">
            No pattern matching models available.
          </p>
        </div>
      ) : (
        <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
          <YMTable
            header={
              <tr>
                <th scope="col" className="px-6 py-3">
                  Models
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only"> x </span>
                </th>
              </tr>
            }
            rows={models.map((m) => (
              <tr
                key={m.model_id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
              >
                <td
                  scope="row"
                  className="px-6 py-4 text-indigo-900 cursor-pointer capitalize font-bold max-w-[48px] hover:text-indigo-900 dark:text-white"
                  onClick={() => handleOnClickModel(m.model_id, m.name)}
                >
                  {m.name}
                </td>
                <td className="px-6 py-4 flex gap-2">
                  <Button
                    size="xs"
                    color="gray"
                    onClick={() => handleDeleteModel(m.model_id)}
                  >
                    <HiTrash className="h-4 w-4" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      )}
    </div>
  );
}
