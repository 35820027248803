import {ExportType, ProjectDimension} from "../../../app_client";
import {YMButton, YMCard, YMInput, YMLabel, YMLoader, YMStatusBadge, YMTable} from "../../../ym-components";
import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {ProjectApi, useProjectApi} from "../State";
import {AiOutlineDownload} from "react-icons/ai";
import {Badge} from "flowbite-react";
import {ProjectFilterContext} from "../ProjectFilterContext";


export const Fst2DMapExports = ({
                                    dimension,
                                }: { dimension: ProjectDimension }) => {

    const api = useProjectApi() as ProjectApi;

    const [fstParams, setFstParams] = React.useState({
        threshold: 0.5,
        title: "",
        marker_size: 7,
        classes: [...(dimension.class_names||[]), 'out of threshold']
    });

    const projectFilter = useContext(ProjectFilterContext);
    const toggleClass = (class_name: string) => {
        if (fstParams.classes.includes(class_name)) {
            setFstParams({...fstParams, classes: fstParams.classes.filter(c => c !== class_name)});
        } else {
            setFstParams({...fstParams, classes: [...fstParams.classes, class_name]});
        }
    }


    const exports = useQuery({
        queryKey: ["projectDocuments", dimension.project_id, "export"],
        queryFn: async () => {
            let exports = await api.getExportedDocument(dimension.project_id);
            return exports.filter(e => e.type === ExportType.FST_2D_MAP && e.info && e.info.dimension_id === dimension.id).reverse()
        },
        enabled: !!api,
        refetchInterval: 5000
    });

    return (<div>
            <YMCard>
                <div className="mb-2 font-bold text-indigo-900 text-2xl">Créer une nouvelle 2d Map FST</div>
                <div className={"flex flex-row gap-4"}>
                    <div>
                        <YMInput
                            value={fstParams.threshold}
                            label={"Threshold"}
                            onChange={(e) => setFstParams({...fstParams, threshold: parseFloat(e)})}
                            type={"number"}
                            min={0}
                            max={1}
                            step={0.01}/>
                    </div>
                    <div>
                        <YMInput
                            value={fstParams.marker_size}
                            label={"Marker"}
                            onChange={(e) => setFstParams({...fstParams, marker_size: parseFloat(e)})}
                            type={"number"}
                            min={1}
                            max={10}
                            step={0.5}/>
                    </div>
                    <div>
                        <YMInput value={fstParams.title} label={"Nom"}
                                 onChange={(e) => setFstParams({...fstParams, title: e})}/>
                        <p className={"text-gray-500 text-xs"}> Laissez vide pour utiliser le nom de la dimension </p>
                    </div>
                </div>
                <div className={"w-100"}>
                    <YMLabel text={"classes"}/>

                    <div>
                                <div className={"flex flex-wrap gap-2"}>
                                    {(dimension.class_names || []).map((class_name, index) => <
                                        Badge id={index+''} className={"cursor-pointer"}
                                              onClick={() => toggleClass(class_name)}
                                              color={fstParams.classes?.includes(class_name) ? "green" : "indigo"}>{class_name} </Badge>)}
                                    <Badge id={'out of threshold'} className={"cursor-pointer"}
                                              onClick={() => toggleClass('out of threshold')}
                                              color={fstParams.classes?.includes('out of threshold') ? "green" : "indigo"}>{'out of threshold'} </Badge>
                                </div>
                    </div>

                </div>
                <div className={"mt-2"}>
                    <YMButton
                        text="Générer une dataviz"
                        size={"xs"}
                        onClick={async () => {
                            await api.generateFst2dMap({
                                dimension_id: dimension.id,
                                threshold: fstParams.threshold,
                                marker_size: fstParams.marker_size,
                                classes: fstParams.classes,
                                title: fstParams.title,
                                project_id: dimension.project_id,
                                conditions: projectFilter.conditions.filter(c => c.active)
                            })
                            await exports.refetch()
                        }}
                    />
                </div>
            </YMCard>
            <div className={"mt-5"}></div>
            <YMLoader
                message={"Chargement des dataviz"}
                loading={exports.isInitialLoading}
            >
                {!!exports.data?.length && (
                    <YMTable
                        header={
                            <tr>
                                <th className="px-6 py-3" scope="col">Dataviz</th>
                                <th></th>
                                <th>Etat</th>
                                <th></th>
                            </tr>
                        }
                        rows={(exports.data || []).map((d) => (
                            <tr
                                key={d.id}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                            >
                                <td
                                    scope="row"
                                    className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
                                >
                                    {" "}
                                    {d.info && d.info.title}
                                </td>
                                <td
                                    scope="row"
                                    className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
                                >
                                    <div className={"flex text-gray-500"}>
                                        threshold: {d.info?.threshold} <br/>
                                        marker size: {d.info?.marker_size} <br/>
                                        classes: {(d.info?.classes||[]).join(", ")} <br/>
                                        filtres: {(d.info?.conditions||[]).map(c => c.dimension+":"+c.label).join(", ")} <br/>
                                    </div>
                                </td>
                                <td
                                    scope="row"
                                    className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
                                >
                                    <div className={"flex"}>
                                        {" "}
                                        <YMStatusBadge status={d.status || "PENDING"}/>{" "}
                                    </div>
                                </td>
                                <td>
                                    <div className={"flex gap-2"}>
                                        <a href={`${process.env.REACT_APP_APP_API_HOST}/project/${dimension.project_id}/2dmap/${d.id}.html`}
                                           target={"_blank"} className={"text-indigo-900"}>
                                            <AiOutlineDownload/>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    />
                )}
            </YMLoader>
        </div>
    )
}