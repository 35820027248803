import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import {
  Alert,
  Badge,
  Button,
  Card,
  FileInput,
  Label,
  Modal,
  Radio,
  RangeSlider,
  Spinner,
  TextInput,
} from "flowbite-react";
import { parse } from "papaparse";
import { useApi } from "../api"; // @ts-ignore
import { useNavigate } from "react-router-dom";
import { HiLink } from "react-icons/hi2";
import { HiInformationCircle } from "react-icons/hi";

function TopicModeling() {
  const { api, isAuthenticated } = useApi();
  const [jobs, setJobs] = useState(null as null | Array<any>);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [embeddingModelName, setEmbeddingModelName] = useState(
    null as null | string
  );

  const [dimRecAlgo, setDimRecAlgo] = useState("umap" as string);
  const [umapNNeighbors, setUmapNNeighbors] = useState(15);
  const [umapMinDist, setUmapMinDist] = useState(0);
  const [umapNComponent, setUmapNComponent] = useState(5);

  const [clusteringAlgo, setClusteringAlgo] = useState("hdbscan" as string);
  const [hdbscanMinClusterSize, setHdbscanMinClusterSize] = useState(15);
  const [kMeanNCluster, setKMeanNCluster] = useState(10);

  const [vectNGram, setVectNGram] = useState(2);
  const [vectMinDF, setVectMinDF] = useState(1);

  const [showTopics, setShowTopics] = useState(null as null | string);

  let refresh_jobs = useCallback(async () => {
    const jobs = await api.get_topic_modeling_jobs();
    setJobs((_) => [...jobs].reverse());
  }, [api]);

  useEffect(() => {
    const getUserJobs = async () => {
      try {
        let refresh_job_loop = async () => {
          const jobs = await api.get_topic_modeling_jobs();
          setJobs((_) => [...jobs].reverse());
          await new Promise((r) => setTimeout(r, 30000));
          await refresh_job_loop();
        };
        await refresh_job_loop();
      } catch (e: any) {
        console.log(e);
      }
    };
    if (isAuthenticated) {
      getUserJobs();
    }
  }, [api, isAuthenticated]);

  const [err, setErr] = useState("");
  const [csvfile, setCsvFile] = useState("" as any);
  const handleFileChange = (e: any) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!["csv"].includes(fileExtension)) {
        alert("Please input a csv file");
        throw Error("csv file expected");
      }
      setCsvFile(inputFile);
    }
  };

  const handleUpload = async () => {
    if (!csvfile) {
      alert("no file selected !");
      throw Error("select a file");
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      // @ts-ignore
      const csv = parse(event.target.result, { header: false });
      // @ts-ignore
      const parsedData = csv?.data;
      if (parsedData.length === 0) {
        alert("The filed is empty");
      } else {
        // @ts-ignore
        let csvData = parsedData.map((row: any) => row[0]);
        try {
          setLoading(true);

          let dimRecParams: any = {
            type: dimRecAlgo,
          };
          if (dimRecAlgo === "umap") {
            dimRecParams = {
              ...dimRecParams,
              n_neighbors: umapNNeighbors,
              min_dist: umapMinDist,
              n_component: umapNComponent,
            };
          }

          let clusteringParms: any = {
            type: clusteringAlgo,
          };
          if (clusteringAlgo === "hdbscan") {
            clusteringParms = {
              ...clusteringParms,
              hdbscan_min_size: hdbscanMinClusterSize,
            };
          }

          if (clusteringAlgo === "kmeans") {
            clusteringParms = {
              ...clusteringParms,
              kmeans_n_clusters: kMeanNCluster,
            };
          }

          let params: any = {
            embedding_model: embeddingModelName,
            dim_rec: dimRecParams,
            clustering: clusteringParms,
            vectorizer: {
              min_df: vectMinDF,
              n_gram: vectNGram,
            },
          };

          await api.new_topic_modeling_job(csvData, csvfile.name, params);
          await refresh_jobs();
          setLoading(false);
        } catch (err: any) {
          setErr(err.message);
        }
      }
    };
    reader.readAsText(csvfile);
  };

  // @ts-ignore
  return (
    <div className="w-100 mx-auto mt-28">
      <div className="grid grid-cols-5 gap-8">
        <div>
          <div className="mb-2 block">
            <span className="text-2xl"> Embedding Model </span>
          </div>
          <TextInput
            id="embedding_model_name"
            value={embeddingModelName || ""}
            onChange={(e) => setEmbeddingModelName(e.target.value)}
            placeholder="all-MiniLM-L6-v2"
          />
          <p className="text-xs text-gray-700 mt-2">
            The embedding model transform the sentence to a numeric vector
          </p>
        </div>
        <div>
          <fieldset
            className="flex flex-col gap-4"
            id="radio-dim-rec"
            onChange={(e: any) =>
              setDimRecAlgo((e.target as any).getAttribute("value"))
            }
          >
            <div className="mb-2 block">
              <span className="text-2xl"> Dimensionality Reduction </span>
            </div>
            <div className="flex items-center gap-2 pl-2">
              <Radio
                id="umap"
                name="dim_rec"
                value="umap"
                defaultChecked={true}
              />
              <Label htmlFor="umap">UMAP</Label>
            </div>
            <div className="flex items-center gap-2 pl-2">
              <Radio id="skip" name="dim_rec" value="skip" />
              <Label htmlFor="skip">Skip Dimensionality Reduction</Label>
            </div>
          </fieldset>
          {dimRecAlgo === "umap" && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="mt-5 block">
                  <Label
                    htmlFor="default-range"
                    value={`UMAP n_neighbors : ${umapNNeighbors}`}
                  />
                </div>
                <RangeSlider
                  id="umap-n_neighbors"
                  value={umapNNeighbors}
                  min={5}
                  max={60}
                  onChange={(e: any) =>
                    setUmapNNeighbors(parseInt(e.target.value))
                  }
                />
                <p className="text-xs text-gray-700">
                  This determines the number of neighboring points used in local
                  approximations of manifold structure. Larger values will
                  result in more global structure being preserved at the loss of
                  detailed local structure. In general this parameter should
                  often be in the range 5 to 50, with a choice of 10 to 15 being
                  a sensible default.
                </p>
              </div>
            </div>
          )}

          {dimRecAlgo === "umap" && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="mt-5 block">
                  <Label
                    htmlFor="default-range"
                    value={`UMAP min_dist : ${umapMinDist}`}
                  />
                </div>
                <RangeSlider
                  id="umap_min_dist"
                  value={umapMinDist * 1000}
                  min={0}
                  max={500}
                  onChange={(e: any) =>
                    setUmapMinDist(parseInt(e.target.value) / 1000)
                  }
                />
                <p className="text-xs text-gray-700">
                  This controls how tightly the embedding is allowed compress
                  points together. Larger values ensure embedded points are more
                  evenly distributed, while smaller values allow the algorithm
                  to optimise more accurately with regard to local structure.
                  Sensible values are in the range 0.001 to 0.5, with 0.1 being
                  a reasonable default.
                </p>
              </div>
            </div>
          )}

          {dimRecAlgo === "umap" && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="mt-5 block">
                  <Label
                    htmlFor="default-range"
                    value={`UMAP n component : ${umapNComponent}`}
                  />
                </div>
                <RangeSlider
                  value={umapNComponent}
                  min={2}
                  max={100}
                  onChange={(e: any) =>
                    setUmapNComponent(parseInt(e.target.value))
                  }
                />
                <p className="text-xs text-gray-700">
                  This determines the number target dimension size{" "}
                </p>
              </div>
            </div>
          )}

          {dimRecAlgo === "umap" && (
            <p className="text-xs text-gray-700 format mt-5">
              UMAP documentation :{" "}
              <a
                rel="noreferrer"
                href="https://github.com/lmcinnes/umap"
                target="_blank"
              >
                https://github.com/lmcinnes/umap
              </a>
            </p>
          )}
        </div>
        <div>
          <fieldset
            className="flex flex-col gap-4"
            id="radio-clustering-algo"
            onChange={(e) =>
              setClusteringAlgo((e.target as any).getAttribute("value"))
            }
          >
            <div className="mb-2 block">
              <span className="text-2xl"> Clustering </span>
            </div>
            <div className="flex items-center gap-2 pl-2">
              <Radio
                id="hdbscan"
                name="clustering_algo"
                value="hdbscan"
                defaultChecked={true}
              />
              <Label htmlFor="umap">HDBSCAN</Label>
            </div>
            <div className="flex items-center gap-2 pl-2">
              <Radio id="kmeans" name="clustering_algo" value="kmeans" />
              <Label htmlFor="skip">K-mean</Label>
            </div>
          </fieldset>
          {clusteringAlgo === "hdbscan" && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="mt-5 block">
                  <Label
                    htmlFor="default-range"
                    value={`HDBSCAN min cluster size : ${hdbscanMinClusterSize}`}
                  />
                </div>
                <RangeSlider
                  value={hdbscanMinClusterSize}
                  min={2}
                  max={100}
                  onChange={(e: any) =>
                    setHdbscanMinClusterSize(parseInt(e.target.value))
                  }
                />
                <p className="text-xs text-gray-700">
                  The minimum cluster size parameter in HDBSCAN controls the
                  smallest size of a cluster that can be considered valid, and
                  it plays an important role in determining the granularity of
                  the clustering result. A smaller minimum cluster size will
                  result in more clusters being identified, including smaller,
                  more tightly-packed clusters, while a larger minimum cluster
                  size will result in fewer, larger clusters being identified.
                </p>
              </div>
            </div>
          )}

          {clusteringAlgo === "kmeans" && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="mt-5 block">
                  <Label
                    htmlFor="default-range"
                    value={`K-Mean nomber of cluster : ${kMeanNCluster}`}
                  />
                </div>
                <RangeSlider
                  value={kMeanNCluster}
                  min={2}
                  max={100}
                  onChange={(e: any) =>
                    setKMeanNCluster(parseInt(e.target.value))
                  }
                />
                <p className="text-xs text-gray-700">
                  The choice of the number of clusters in K-means clustering
                  will depend on the specific problem at hand, the structure of
                  the data, and the goals of the analysis. It is often a matter
                  of balancing the desire for a simple, interpretable solution
                  with the need for a solution that accurately captures the
                  underlying patterns in the data.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="flex flex-col gap-4">
            <div className="mb-2 block">
              <span className="text-2xl"> Vectorizer </span>
            </div>
            <div>
              <div className="mt-2 block">
                <Label value={`Vectorizer N-Gram range : ${vectNGram}`} />
              </div>

              <RangeSlider
                value={vectNGram}
                min={1}
                max={4}
                onChange={(e: any) => setVectNGram(parseInt(e.target.value))}
              />
              <p className="text-xs text-gray-700">
                The ngram_range parameter allows us to decide how many tokens
                each entity is in a topic representation. For example, we have
                words like "game" and "team" with a length of 1 in a topic but
                it would also make sense to have words like "hockey league" with
                a length of 2.
              </p>
            </div>
            <div>
              <div className="mt-5 block">
                <Label value={`Vectorizer Min Frequency : ${vectMinDF}`} />
              </div>
              <RangeSlider
                value={vectMinDF}
                min={1}
                max={1000}
                onChange={(e: any) => setVectMinDF(parseInt(e.target.value))}
              />
              <p className="text-xs text-gray-700">
                This is typically an integer representing how frequent a word
                must be before being added to our representation. You can
                imagine that if we have a million documents and a certain word
                only appears a single time across all of them, then it would be
                highly unlikely to be representative of a topic. Typically, the
                c-TF-IDF calculation removes that word from the topic
                representation but when you have millions of documents, that
                will also lead to a very large topic-term matrix. To prevent a
                huge vocabulary, we can set the min_df to only accept words that
                have a minimum frequency.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* file upload */}
      <div id="fileUpload" className="mt-10">
        <div className="mb-2 block">
          <Label htmlFor="file" value="CSV File to analyse" />
        </div>
        <FileInput
          id="file"
          accept=".csv"
          helperText="Upload a csv file containing a list of verbatims"
          onChange={handleFileChange}
        />
      </div>

      {!loading && (
        <div>
          <Button
            className="mt-10"
            gradientMonochrome="info"
            onClick={handleUpload}
          >
            Analyse
          </Button>
          {err && (
            <Alert color="failure" className="mt-5">
              <span>
                <span className="font-medium"> {err} </span>
              </span>
            </Alert>
          )}
        </div>
      )}

      {loading && <Spinner />}

      <div className="mt-10 text-2xl">Jobs</div>

      {jobs === null && <p> Loading jobs ... </p>}
      {jobs && jobs.length === 0 && <p> Anys jobs for the moment .. </p>}

      {jobs &&
        jobs.map((j) => (
          <Card key={j.id} className="mt-2">
            <div className="flex">
              <div>
                <div className="flex flex-wrap gap-2">
                  {j.state === "WAITING" && (
                    <div>
                      <Badge color="info">Waiting...</Badge>
                    </div>
                  )}
                  {j.state === "PROCESSING" && (
                    <Badge color="success">Processing...</Badge>
                  )}
                  {j.state === "MODEL_LOADING" && (
                    <Badge color="info">Loading model...</Badge>
                  )}
                  {j.state === "COMPLETED" && (
                    <Badge color="success">Completed</Badge>
                  )}
                  {j.state === "FAILED" && (
                    <Badge color="failure">Failed </Badge>
                  )}
                </div>
                <div>
                  <span className="text-sm text-gray-900">
                    <span className="text-xs text-gray-500">
                      {j.created_at}{" "}
                    </span>
                    <br />
                    <span
                      className="text-xs text-gray-500 hover:text-blue-500 cursor-pointer"
                      onClick={() => navigate("/events/" + j.id)}
                    >
                      # {j.id}
                    </span>
                    <br />
                    <span className="text-xs text-gray-500">{j.name} </span>
                    <br />
                    <span className="text-xs text-gray-500">
                      📝 {j.verbatim_count} verbatims{" "}
                    </span>
                    <br />
                    {j.run_duration && (
                      <span className="text-xs text-gray-500">
                        ⏱ {Math.round(j.run_duration / 60)} minutes{" "}
                        {j.run_duration % 60} seconds{" "}
                      </span>
                    )}
                    <br />
                    <br />
                    {typeof j.params === "object" &&
                      j.params !== null &&
                      Object.entries(j.params).map(
                        ([k, v]: [string, any]) =>
                          v && (
                            <>
                              <span className="text-gray-500">
                                <b>{k}</b> : {JSON.stringify(v, null, 2)}
                              </span>
                              <br />
                            </>
                          )
                      )}
                  </span>
                  {j.fail_reason && (
                    <Alert color="failure" icon={HiInformationCircle}>
                      {" "}
                      {j.fail_reason}{" "}
                    </Alert>
                  )}
                </div>
              </div>

              {j.state === "COMPLETED" && (
                <>
                  <div className="grow mx-10" style={{ height: "420px" }}>
                    <a
                      href={api.topic_modeling_visualisation_iframe_url(
                        j.id,
                        "documents"
                      )}
                      target={"_blank"}
                      className={"text-blue-500"} rel="noreferrer"
                    >
                      <HiLink className={"inline"} /> dataviz documents{" "}
                    </a>
                    <a
                      href={api.topic_modeling_visualisation_iframe_url(
                        j.id,
                        "hierarchy"
                      )}
                      target={"_blank"}
                      className={"text-blue-500"} rel="noreferrer"
                    >
                      <HiLink className={"inline"} /> dataviz hierarchy{" "}
                    </a>
                    <a
                      href={api.topic_modeling_visualisation_iframe_url(
                        j.id,
                        "heatmap"
                      )}
                      target={"_blank"}
                      className={"text-blue-500"} rel="noreferrer"
                    >
                      <HiLink className={"inline"} /> dataviz heatmap{" "}
                    </a>
                    <a
                      href={api.topic_modeling_visualisation_iframe_url(
                        j.id,
                        "barchart"
                      )}
                      target={"_blank"}
                      className={"text-blue-500"} rel="noreferrer"
                    >
                      <HiLink className={"inline"} /> dataviz barchart{" "}
                    </a>
                    <a
                      href={api.topic_modeling_visualisation_iframe_url(
                        j.id,
                        "bubblechart"
                      )}
                      target={"_blank"}
                      className={"text-blue-500"} rel="noreferrer"
                    >
                      <HiLink className={"inline"} /> dataviz bubblechart{" "}
                    </a>
                  </div>
                  <div>
                    <Button onClick={() => setShowTopics(j.id)} size="xs">
                      Topics
                    </Button>
                    <Modal
                      show={showTopics === j.id}
                      size={"7xl"}
                      onClose={() => setShowTopics(null)}
                    >
                      <Modal.Header>Topics</Modal.Header>
                      <Modal.Body
                        className="overflow-scroll"
                        style={{ maxHeight: "80vh" }}
                      >
                        <div className="grid grid-cols-4 gap-4">
                          {j.topics.map((t: any) => (
                            <div className="format py-2 py-5">
                              <h4 className="mb-0 mt-2">{t.name}</h4>
                              <p className="mt-0">
                                {" "}
                                {t.words.map((w: string) => (
                                  <span className="bg-gray-100 px-2 rounded mr-2 mt-1">
                                    {" "}
                                    {w}{" "}
                                  </span>
                                ))}{" "}
                              </p>
                              <p className="text-2xs">
                                {t.representative &&
                                  t.representative.map((r: any) => (
                                    <>
                                      {r}
                                      <br />
                                      <br />
                                    </>
                                  ))}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          onClick={() => api.download_topic_csv(j.id, j.name)}
                          size="xs"
                        >
                          Download
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )}
            </div>
          </Card>
        ))}
    </div>
  );
}

export default TopicModeling;
