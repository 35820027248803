import React, { useEffect, useState } from "react";
import { useProject, useProjectApi } from "./State";
import {
  YMButton,
  YMCard,
  YMInput,
  YMLightButton,
  YMSqueleton,
  YMTutorialTips,
} from "../../ym-components";
import { DimensionClassVerbatims } from "../../app_client";
import { YMIframe } from "./components/components";
import { createPortal } from "react-dom";
import { IoMdRefresh } from "react-icons/io";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";

export default function ProjectTopics(props: any) {
  const { _project, projectId } = useProject();
  const projectApi = useProjectApi();

  const [topicModelingId, setTopicModelingId] = useState(null as string | null);
  const [barChart, setBarChart] = useState(undefined as string | undefined);
  const [treeChart, setTreeChart] = useState(undefined as string | undefined);
  const [documentsMap, setDocumentsMap] = useState(
    undefined as string | undefined
  );
  const [topicVerbatims, setTopicVerbatims] = useState(
    [] as DimensionClassVerbatims[]
  );

  const [activeTopic, setActiveTopic] = useState(
    undefined as DimensionClassVerbatims | undefined
  );
  const [topicName, setTopicName] = useState(undefined as string | undefined);

  const [datavizVersion, setDatavizVersion] = useState(0);
  const [displayedDatavizVersion, setDisplayedDatavizVersion] = useState(0);

  useEffect(() => {
    if (_project.data.most_recent_topic_modeling_id != topicModelingId) {
      setTopicModelingId(_project.data.most_recent_topic_modeling_id as string);
    }
  }, [_project.data]);

  useEffect(() => {
    if (projectApi && projectId && topicModelingId) {
      projectApi.getTopicVerbatims(projectId).then((res) => {
        const clusters = res
          .filter((t) => t.classname != "non classé")
          .sort((a, b) => a.classname.localeCompare(b.classname));
        setTopicVerbatims(clusters);
        setActiveTopic(clusters[0]);
        setTopicName(clusters[0].classname);
      });
      setBarChart(
        projectApi.getBertopicBartChart(topicModelingId as string, "barchart")
      );
      setTreeChart(
        projectApi.getBertopicBartChart(topicModelingId as string, "hierarchy")
      );
      setDocumentsMap(
        projectApi.getBertopicBartChart(topicModelingId as string, "documents")
      );
    }
  }, [projectId, projectApi, topicModelingId]);

  const handleRenameTopic = async () => {
    if (projectApi && projectId && activeTopic && topicName) {
      await projectApi.renameTopic(projectId, activeTopic.classname, topicName);
      activeTopic.classname = topicName;
      setTopicVerbatims(
        topicVerbatims.map((t) => {
          if (t.classname == activeTopic.classname) {
            return { ...t, classname: topicName };
          } else {
            return t;
          }
        })
      );
      setDatavizVersion(datavizVersion + 1);
    }
  };

  return (
    <div className={"ml-4"}>
      {datavizVersion != displayedDatavizVersion &&
        createPortal(
          <div
            id="bottom-banner"
            style={{ left: 230, width: "calc(100% - 230px)" }}
            className="fixed top-10 z-30 flex justify-center w-full p-4"
          >
            <Alert color="success" icon={HiInformationCircle}>
              <span>
                <p>
                  Vous venez de renommer un thème. Pour appliquer les
                  changements au dataviz, cliquez sur le bouton ci-dessous.
                </p>
                <span>
                  <YMLightButton
                    onClick={() => {
                      setDisplayedDatavizVersion(datavizVersion);
                    }}
                    color={"green"}
                    icon={<IoMdRefresh className={"h-5 w-5 pr-2"} />}
                    label={"Actualiser les dataviz"}
                  />
                </span>
              </span>
            </Alert>
          </div>,
          document.getElementById("panel-drawer") || document.body
        )}

      <div className={"flex gap-2 mb-1 mt-5"}>
        <h3 className={"text-indigo-900 text-2xl font-bold"}>
          Thèmes détectés
        </h3>
        <YMTutorialTips
          content={
            <p>
              Classement des thèmes détectés automatiquement. Pour chaque thème,
              le top 5 des mots les plus représentatifs.
            </p>
          }
        />
      </div>
      {barChart && (
        <YMCard>
          <YMIframe
            key={displayedDatavizVersion}
            src={barChart + `?nocache=${displayedDatavizVersion}`}
            style={{ width: "100%", height: "700px" }}
          />
        </YMCard>
      )}
      {!barChart && <YMSqueleton />}

      <div className={"flex gap-2 mb-1 mt-5"}>
        <h3 className={"text-indigo-900 text-2xl font-bold"}>
          Aperçu et nom des thèmes
        </h3>
        <YMTutorialTips
          content={
            <p>
              Le nom des thèmes est proposé par défaut par l’IA. Affinez en
              lisant les sélections de verbatims, puis choisissez, si besoin,
              des noms plus adaptés au contexte de votre analyse.
            </p>
          }
        />
      </div>

      <YMCard>
        <div className={"flex flex-row"}>
          <div className="border-r border-gray-200 w-56 mb-10">
            <div className="my-2">
              <div className="text-indigo-800 cursor-pointer hover:font-bold flex items-center font-bold">
                <div> Liste des thèmes</div>
              </div>
              <div className="pl-4 border-l border-dashed border-indigo-800 ml-2">
                <div>
                  {topicVerbatims.map((t, i) => (
                    <div
                      onClick={() => {
                        setActiveTopic(t);
                        setTopicName(t.classname);
                      }}
                      className="flex flex-row items-center pb-2 pr-5 group hover:text-indigo-500 cursor-pointer text-indigo-900 font-bold"
                    >
                      <div
                        className={
                          "text-indigo-800 group-hover:text-indigo-500 cursor-pointer " +
                          (t.classname == (activeTopic && activeTopic.classname)
                            ? "font-bold"
                            : "font-normal")
                        }
                      >
                        {" "}
                        {t.classname}{" "}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={"pl-4 grow max-h-96 overflow-scroll"}>
            {activeTopic && (
              <div>
                <div className={"mt-2 mb-5 flex gap-2 items-end"}>
                  <YMInput
                    label={"Nom"}
                    value={topicName || ""}
                    onChange={setTopicName}
                  />
                  {topicName != activeTopic.classname && (
                    <YMButton
                      size={"xs"}
                      text={"Sauvegarder"}
                      onClick={handleRenameTopic}
                    />
                  )}
                </div>

                {activeTopic.verbatims.map((v, i) => (
                  <div
                    key={i + activeTopic.classname}
                    className={"mb-1"}
                    style={{ maxWidth: 700 }}
                  >
                    {" "}
                    {v}{" "}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </YMCard>

      <div className={"flex gap-2 mb-1 mt-5"}>
        <h3 className={"text-indigo-900 text-2xl font-bold"}>
          Cartographie des thèmes
        </h3>
        <YMTutorialTips
          content={
            <p>
              Les thèmes détectés automatiquement par l'IA apparaissent en
              couleur. Vous pouvez ainsi visualiser le poids de chaque thème de
              votre corpus.
              <br /> Chaque point représente un verbatim ; les points gris sont
              des verbatims non thématisés par l’IA.
              <br /> Cette cartographie facilite une exploration de votre corpus
              à partir de laquelle vous pouvez
              <br /> notamment créer vos propres modèles à champs lexicaux.
            </p>
          }
        />
      </div>
      {documentsMap && (
        <YMCard>
          <YMIframe
            key={displayedDatavizVersion}
            src={documentsMap + `?nocache=${displayedDatavizVersion}`}
            style={{ width: "100%", height: "800px" }}
          />
        </YMCard>
      )}
      {!documentsMap && <YMSqueleton />}

      <div className={"flex gap-2 mb-1 mt-5"}>
        <h3 className={"text-indigo-900 text-2xl font-bold"}>
          Arbre de proximité des thèmes
        </h3>
        <YMTutorialTips
          content={
            <p>
              Ce cladogramme représente la proximité / distance entre les thèmes
              automatiquement identifiés.
            </p>
          }
        />
      </div>
      {treeChart && (
        <YMCard>
          <YMIframe
            key={displayedDatavizVersion}
            src={treeChart + `?nocache=${displayedDatavizVersion}`}
            style={{ width: "100%", height: "420px" }}
          />
        </YMCard>
      )}
      {!treeChart && <YMSqueleton />}
    </div>
  );
}
