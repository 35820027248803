/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Permissions {
    INFERENCE_FULL = 'inference:full',
    JOB_FULL = 'job:full',
    TOPIC_MODELING_FULL = 'topic_modeling:full',
    KERNEL_FULL = 'kernel:full',
    WORKSPACE_FULL = 'workspace:full',
    DEVELOPER_FULL = 'developer:full',
}
