import React, { useState } from "react";
import { Card } from "flowbite-react";
import { YMButton, YMInput, YMSelect } from "../../../ym-components";
import { useNavigate } from "react-router-dom";
import { useProjectApi } from "../State";
import {
  ConfigurationMap,
  fileId,
  FileUploadForm,
} from "../components/FileUploadForm";
import { Language } from "../../../app_client";

type NewProjectFormProps = {};

export const NewProjectForm: React.FC<NewProjectFormProps> = () => {
  const projectApi = useProjectApi();
  const navigate = useNavigate();

  const [files, setFiles] = useState<File[]>([]);
  const [configurations, setConfigurations] = useState<ConfigurationMap>({});

  const [name, setName] = useState<string>("");
  const [lang, setLang] = useState<Language>(Language.FR);
  const [nameError, setNameError] = useState(false);

  const [uploadStates, setUploadStates] = useState<{
    [key: string]: "uploading" | "done" | "error";
  }>({});
  const [creatingProject, setCreatingProject] = useState(false);

  const onCreateProject = async () => {
    if (!projectApi) return;

    if (!name) {
      setNameError(true);
      return;
    }
    setCreatingProject(true);
    try {
      let project_id = await projectApi.createProject(name, lang);
      Promise.all(
        files.map((f) => {
          uploadState(f, "uploading");
          let promise = projectApi.addFileToProject(
            project_id,
            f,
            configurations[fileId(f)] || { split_cell: true }
          );
          promise
            .then(() => {
              uploadState(f, "done");
            })
            .catch((error: any) => {
              uploadState(f, "error");
            });
          return promise;
        })
      ).then(() => {
        navigate("/project/" + project_id + "?name=" + name);
      });
    } catch (error) {
      alert("Une erreur s'est produite");
    }
    setCreatingProject(false);
  };

  const uploadState = (file: File, state: "uploading" | "done" | "error") => {
    setUploadStates({
      ...uploadStates,
      [fileId(file)]: state,
    });
  };

  return (
    <div>
      <div className="w-100">
        <Card>
          <div className={"format"}>
            <h2 className={"text-indigo-900"}>Créer un nouveau projet</h2>
          </div>
          <form className="flex flex-col gap-4">
            <div className={"w-72"}>
              <YMInput
                value={name}
                label={"Nom du projet"}
                onChange={setName}
              />
            </div>
            <div className={"w-72 mb-5"}>
              <YMSelect
                label={"Langue"}
                value={lang}
                options={[
                  { id: Language.EN, name: "Anglais" },
                  { id: Language.FR, name: "Français" },
                ]}
                onChange={(v) => setLang(v as Language)}
              />
            </div>
            <FileUploadForm
              onFilesUpdated={(files, configurations) => {
                setFiles(files);
                setConfigurations(configurations);
              }}
              uploadStates={uploadStates}
            />
          </form>
          <YMButton
            className={"w-56"}
            text={creatingProject ? "Téléversement des documents ..." : "Créer"}
            disabled={creatingProject || !projectApi}
            onClick={onCreateProject}
          ></YMButton>
        </Card>
      </div>
    </div>
  );
};
