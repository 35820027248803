import { YMLabel, YMTinyButton } from "../../../ym-components";
import { FaInfoCircle } from "react-icons/fa";
import { useFewShotTaggingModelApi } from "../../fewshot-learning-models/Api";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { AiOutlineDownload } from "react-icons/ai";

export const FewShotLearningModelInfo = ({
  model_id,
}: {
  model_id: string;
}) => {
  const api = useFewShotTaggingModelApi();
  const [modal, setModal] = useState(false);
  const model = useQuery({
    queryKey: ["fewshot-learning-model", model_id],
    queryFn: () => api.fetchModel(model_id),
  });

  useEffect(() => {
    if (api) {
      model.refetch();
    }
  }, [api]);

  const onClick = () => {
    model.refetch();
    setModal(true);
  };

  return (
    <>
      <YMTinyButton
        icon={<FaInfoCircle className={"mr-2"} />}
        text={"Information sur le modèle"}
        color={"white"}
        loading={model.isFetching}
        disabled={model.isFetching}
        onClick={() => setModal(true)}
      />
      <Modal
        size={"4xl"}
        show={modal && model.isFetched}
        onClose={() => setModal(false)}
      >
        <Modal.Header>
          <span className={"text-2xl capitalize font-bold text-indigo-900"}>
            {model.data?.name}
          </span>
        </Modal.Header>
        <Modal.Body>
          <YMLabel text={"Etat"} />
          <p className={"text-indigo-900 mb-4 -mt-2 capitalize"}>
            {model.data?.status}
          </p>
          <YMLabel text={"Type de classification"} />
          <p className={"text-indigo-900 mb-4 -mt-2"}>
            {model.data?.classification_method}
          </p>
          <YMLabel text={"Donnée d'entraînement"} />
          <p className={"text-indigo-900 -mt-2"}>
            <div className={"mb-4"}>
              {Object.entries(model.data?.training_example_counter || {}).map(
                ([k, v]) => (
                  <span className={"mr-4"} key={k}>
                    <span className="text-indigo-900 capitalize">
                      {k}&nbsp;
                    </span>
                    <span className="text-indigo-800 text-xs">{v}</span>
                  </span>
                )
              )}
            </div>
            <YMTinyButton
              text={"Télécharger les données d'entraînement"}
              icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
              color={"white"}
              onClick={() => model.data && api.downloadTrainingData(model.data)}
            />
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};
