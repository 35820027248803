const buildNodesAndLinks = (
  initialNodes,
  initialLinks,
  blackList,
  excludeOrphans
) => {
  // Ordonner les noeuds par frequence d'apparition
  const trierParFrequence = (node1, node2) => node2.frequency - node1.frequency;

  var nodes = initialNodes
    .sort(trierParFrequence)
    .map((node) => ({ ...node, hidden: blackList.includes(node.lemma) }));

  var edges = initialLinks.map((edge) => ({
    ...edge,
    hidden: blackList.includes(edge.source) || blackList.includes(edge.target),
  }));

  if (!excludeOrphans) return { nodes, edges };

  // An orphan is a node with no link
  const listOfOrphans = nodes
    .filter((node) => {
      if (node.hidden) return true;
      const source = edges
        .filter((edge) => !edge.hidden)
        .find((link) => node.lemma === link.source);
      if (source) return false;
      const target = edges
        .filter((edge) => !edge.hidden)
        .find((link) => node.lemma === link.target);
      if (target) return false;
      return true;
    })
    .map((node) => node.lemma);

  if (listOfOrphans.length) {
    nodes = nodes.map((node) => ({
      ...node,
      hidden: listOfOrphans.includes(node.lemma),
    }));
  }

  return { nodes, edges };
};

export default buildNodesAndLinks;
