import {
  ProjectView,
  TagOccurencesWithRelatedTags,
  TagType,
} from "../../../app_client";
import { YMNavBar } from "../components/components";
import { YMLoader, YMTable } from "../../../ym-components";
import { VerbatimTagBadge } from "../components/verbatims/VerbatimTag";
import AddATagMenu from "../components/verbatims/AddTagMenu";
import { Tooltip } from "flowbite-react";
import { HiSearch } from "react-icons/hi";
import React, { useState } from "react";
import { useSearchTagsQuery } from "./State";
import { useProject } from "../State";
import { VerbatimsModal } from "../components/verbatims/VerbatimModal";

const TagsTable = (props: {
  searchParams: {
    dimension: string;
  };
}) => {
  const { _project, projectId } = useProject();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { _ProjectTags, handleDeleteTag, handleTagAPattern } =
    useSearchTagsQuery({
      projectId: projectId,
      searchParams: {
        ...props.searchParams,
        page: currentPage,
      },
    });

  const handleTagATagAndRefreshProject = async (
    tag: TagOccurencesWithRelatedTags,
    dimension: string,
    className: string
  ) => {
    let dimension_o = _project.data.dimensions.find((d) => d.name == dimension);
    const color = dimension_o?.color || "indigo";
    await handleTagAPattern(tag, dimension, className, color);
    if (!dimension_o) {
      await _project.refetch(); // creating new dimension, reload project
    }
  };

  const [displayVerbatimModal, setDisplayVerbatimModal] = useState(false);
  const [verbatimLemmaFilter, setVerbatimLemmaFilter] = useState("");
  const [verbatimDimensionFilter, setVerbatimDimensionFilter] = useState("");
  const [verbatimClassFilter, setVerbatimClassFilter] = useState("");
  const [verbatimPOSFilter, setVerbatimPOSFilter] = useState("");

  const handleSearchVerbatims = (
    tag_occurence: TagOccurencesWithRelatedTags
  ) => {
    setVerbatimLemmaFilter(
      tag_occurence.name +
        "," +
        tag_occurence.tags.map((t) => t.token_lemma).join(",")
    );
    setVerbatimDimensionFilter(tag_occurence.dimension);
    setVerbatimClassFilter(tag_occurence.label);
    setDisplayVerbatimModal(true);
  };

  return (
    <>
      <YMLoader
        message={"Chargement des résultats"}
        loading={_ProjectTags.isLoading}
      >
        <div className={"flex gap-2 mb-2 items-end"}>
          {(_ProjectTags.data?.page_count || 0) > 1 && (
            <>
              <YMNavBar
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={_ProjectTags.data?.page_count || 1}
                previousLabel={"Précédent"}
                nextLabel={"Suivant"}
              />
              <div className={"text-indigo-900 text-sm"}>
                {" "}
                {_ProjectTags.data?.total} résultats /{" "}
                {_ProjectTags.data?.page_count} pages
              </div>
            </>
          )}
          <div className={"grow"}></div>
          {_ProjectTags.isFetching && (
            <div className={"text-gray-500 text-sm justify-self-end "}>
              {" "}
              mise à jours des données...{" "}
            </div>
          )}
        </div>

        <div className="relative overflow-x-auto rounded-lg border border-indigo-200 bg-white shadow-lg">
          <YMTable
            header={
              <tr>
                <th scope="col" className="px-6 py-3">
                  Label
                </th>
                <th scope="col" className="px-6 py-3">
                  Tag
                </th>
                <th scope="col" className="px-6 py-3">
                  Occurrences
                </th>
                <th scope="col" className="px-6 py-3">
                  Champ Lexical
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only"> x </span>
                </th>
              </tr>
            }
            rows={(_ProjectTags.data?.data || []).map((project_tag, i) => (
              <tr
                key={project_tag.label + project_tag.name + i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
              >
                <td
                  scope="row"
                  className="px-6 py-4 text-indigo-900 hover:text-indigo-900 dark:text-white"
                >
                  {project_tag.label}
                </td>
                <td
                  scope="row"
                  className="px-6 text-md font-bold py-4 text-indigo-800 hover:text-indigo-900 dark:text-white"
                >
                  <div style={{ width: "fit-content" }}>{project_tag.name}</div>
                </td>
                <td
                  scope="row"
                  className="px-6 py-4 text-gray-900 max-w-[48px] hover:text-indigo-900 dark:text-white"
                >
                  {project_tag.occurrences}
                </td>
                <td>
                  <div className={"flex flex-wrap gap-2"}>
                    {project_tag.tags
                      ?.filter((t) => t.dimension != "lexique")
                      .filter((t) => t.dimension != "ngrammes")
                      .filter((t) => t.dimension != "blacklist_frequencygraph")
                      .map((t) => (
                        <VerbatimTagBadge
                          key={t.id}
                          tag={t}
                          project={_project.data as ProjectView}
                          onDelete={() => handleDeleteTag(project_tag, t)}
                        />
                      ))}
                    <div>
                      <AddATagMenu
                        project={_project.data as ProjectView}
                        handleAddTag={(dimension, className) => {
                          handleTagATagAndRefreshProject(
                            project_tag,
                            dimension,
                            className
                          );
                        }}
                        tagType={TagType.TOKEN_CLASSIFICATION}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <Tooltip content={"Rechercher les verbatims"}>
                    <div
                      onClick={() => handleSearchVerbatims(project_tag)}
                      className={
                        "cursor-pointer flex items-center justify-center bg-indigo-200 text-indigo-800 hover:bg-indigo-800 hover:text-indigo-100 rounded h-8 w-8"
                      }
                    >
                      <HiSearch className={""} />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          />
        </div>
        {(_ProjectTags.data?.page_count || 0) > 1 && (
          <div className={"flex gap-2 mb-2 items-end"}>
            <YMNavBar
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={_ProjectTags.data?.page_count || 1}
              previousLabel={"Précédent"}
              nextLabel={"Suivant"}
            />
            <div className={"text-indigo-900 text-sm"}>
              {" "}
              {_ProjectTags.data?.total} résultats /{" "}
              {_ProjectTags.data?.page_count} pages
            </div>
          </div>
        )}
      </YMLoader>
      <VerbatimsModal
        show={displayVerbatimModal}
        onClose={() => setDisplayVerbatimModal(false)}
        lemmasFilter={verbatimLemmaFilter}
        dimensionFilter={verbatimDimensionFilter}
        classNameFilter={verbatimClassFilter}
        posFilter={verbatimPOSFilter}
      />
    </>
  );
};

export default TagsTable