import React, { useEffect, useState } from "react";
import { useApi } from "../../api";
import { Language, PatternMatchingModelProjection } from "../../app_client";
import { HiOutlineArrowUturnLeft } from "react-icons/hi2";
import { IoIosSave } from "react-icons/io";
import { YMButton, YMCard, YMInput } from "../../ym-components";
import { SiDatabricks } from "react-icons/si";
import { useNavigate, useParams } from "react-router-dom";
import { BiDownload } from "react-icons/bi";
import { useProject, useProjectApi } from "../project/State";
import { HiPlus, HiTrash } from "react-icons/hi";
import { Button, Label, Spinner, Textarea, TextInput } from "flowbite-react";
import {
  notificationDismiss,
  notificationInfo,
} from "../../toast-notification";

type DistributionDatavizProps = {
  modelId: string | undefined;
  modelName: string;
};

export const PatternMatchingModelForm: React.FC<DistributionDatavizProps> = ({
  modelId,
  modelName,
}) => {
  const { api, isAuthenticated } = useApi();
  const { _ProjectAnalysisStats } = useProject();

  const [model, setModel] = useState(
    "loading" as PatternMatchingModelProjection | "loading"
  );
  const [name, setName] = useState(modelName as string);
  const [lang, setLang] = useState<Language>(Language.FR);
  const [classNameList, setClassNameList] = useState([] as string[]);
  const [patternsList, setPatternsList] = useState([] as any[]);
  const [edited, setEdited] = useState(false as boolean);
  const [saving, setSaving] = useState(false as boolean);
  const { projectId } = useParams();
  const projectApi = useProjectApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (api && isAuthenticated && modelId) {
      api
        .get_pattern_matching_model(modelId)
        .then((data: PatternMatchingModelProjection) => {
          setModel(data);
          setName(data.name);
          resetLists(data);
        });
    }
  }, [api, isAuthenticated, modelId]);

  const resetLists = (data: PatternMatchingModelProjection | "loading") => {
    if (data === "loading") return;
    setClassNameList(data.patterns.map((p) => p.name));
    setPatternsList(
      data.patterns.map((p) =>
        p.patterns
          .map((p: any) => p.map((ppp: any) => ppp["LEMMA"]).join(" "))
          .join(", ")
      )
    );
  };

  const handleChangeClassName = (index: number, newClassName: string) => {
    if (model === "loading") return;
    setEdited(true);
    setClassNameList(
      classNameList.map((c, i) => {
        // update classNameList
        if (i === index) {
          return newClassName;
        }
        return c;
      })
    );
  };

  const handleChangePatternList = (index: number, newPatternList: string) => {
    if (model === "loading") return;
    setEdited(true);
    setPatternsList(
      patternsList.map((p, i) => {
        // update classNameList
        if (i === index) {
          return newPatternList;
        }
        return p;
      })
    );
  };

  const handleAddCL = () => {
    setEdited(true);
    setClassNameList(["", ...classNameList]);
    setPatternsList(["", ...patternsList]);
  };

  const deleteCL = (index: number) => {
    setEdited(true);
    setClassNameList(classNameList.filter((c, i) => i !== index));
    setPatternsList(patternsList.filter((p, i) => i !== index));
  };

  const handleSaveChanges = () => {
    setSaving(true);
    let notifId = notificationInfo("Mise à jour du modèle en cours...");
    api
      .update_pattern_matching_model(
        modelId as string,
        name,
        patternsList.map((patterns, i) => [
          classNameList[i],
          patterns.split(","),
        ])
      )
      .then(() => {
        notificationDismiss(notifId);
        setSaving(false);
        setEdited(false);
        setModel({
          id: modelId || "",
          name: name,
          language: Language.FR,
          patterns: patternsList.map((patterns, i) => ({
            name: classNameList[i],
            patterns: patterns.split(",").map((p: string) => p.split(" ")),
          })),
        });
      });
  };

  const handleImport = async () => {
    if (projectApi && projectId && modelId) {
      await projectApi.importPatternMatchingModel(projectId, modelId, name);
      setTimeout(() => {
        navigate("/project/" + projectId + "/");
        _ProjectAnalysisStats.refetch();
      }, 1000);
    }
  };

  return (
    <div>
      <div className={"fixed flex gap-4 right-10 top-10 z-50"}>
        {edited && (
          <>
            <YMButton
              icon={
                <HiOutlineArrowUturnLeft
                  color={"white"}
                  className={"h-6 w-6 mr-2"}
                />
              }
              disabled={saving}
              text={"Annuler"}
              onClick={() => {
                resetLists(model);
                setEdited(false);
              }}
            />
            <YMButton
              disabled={saving}
              icon={<IoIosSave className={"h-6 w-6 mr-2"} />}
              text={"Sauvegarder"}
              onClick={() => {
                handleSaveChanges();
              }}
            />
          </>
        )}
        {!edited && projectId && (
          <YMButton
            icon={<BiDownload color={"white"} className={"h-6 w-6 mr-2"} />}
            text={"Importer"}
            onClick={() => handleImport()}
          />
        )}
      </div>

      <div>
        <YMCard>
          <div className={"w-100"}>
            <div className={"format max-w-4xl"}>
              <h2 className={"text-indigo-600 capitalize"}>
                <SiDatabricks className={"inline -mt-1"} />{" "}
                {model === "loading" ? modelName : model.name}
              </h2>
              <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
                Alimentez votre modèle en créant autant de champs lexicaux que
                nécessaire.
              </p>

              <YMInput
                value={name}
                label={"Nom du model"}
                onChange={(e) => {
                  setName(e);
                  setEdited(true);
                }}
              />
            </div>
          </div>

          {model === "loading" ? (
            <div className="w-100 mt-5 text-center">
              <Spinner
                color="gray"
                size="xl"
                aria-label="Default status example"
              />
              <div className={"mt-4 text-gray-400"}> fetching {modelName} </div>
            </div>
          ) : (
            <div className={"flex flex-col gap-4 mt-2"}>
              <Button
                gradientDuoTone={"gray"}
                size={"sm"}
                className={"mt-5"}
                onClick={() => handleAddCL()}
              >
                <HiPlus className={"h-4 w-4 mr-2"} /> Ajouter une classe{" "}
              </Button>
              {patternsList.map((p, i) => (
                <div
                  key={i}
                  className={"border border-indigo-200 rounded-lg pb-5 px-5"}
                >
                  <div className={"flex flex-row-reverse "}>
                    <Button
                      size={"xs"}
                      color="light"
                      className={"mt-5"}
                      onClick={() => deleteCL(i)}
                    >
                      <HiTrash className={"h-4 w-4 mr-2"} /> Supprimer{" "}
                    </Button>
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        className={
                          "text-indigo-900 text-md mt-5 mb-2 font-bold"
                        }
                        htmlFor="name"
                        value="Classe"
                      />
                    </div>
                    <TextInput
                      type="name"
                      required={true}
                      className="w-80"
                      value={classNameList[i]}
                      onChange={(e) => handleChangeClassName(i, e.target.value)}
                    />
                  </div>
                  <h3 className={"text-indigo-900 text-md mt-5 mb-2 font-bold"}>
                    Tags
                  </h3>
                  <Textarea
                    required={true}
                    style={{ minHeight: "250px" }}
                    className="w-80"
                    value={patternsList[i]}
                    onChange={(e) => handleChangePatternList(i, e.target.value)}
                  />
                  <p className={"text-indigo-900 text-md"}>
                    {" "}
                    Les tags peuvent être séparés par des virgules{" "}
                  </p>
                </div>
              ))}
            </div>
          )}
        </YMCard>
      </div>
    </div>
  );
};
