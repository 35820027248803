import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { ProjectApi, useProjectApi } from "../State";
import { YMButton, YMInput } from "../../../ym-components";

export const DimensionTagRenameModal = ({
  show,
  onClose,
  onRenamed,
  currentTagName,
  dimensionName,
  projectId,
}: {
  show: boolean;
  onClose: () => void;
  onRenamed: () => void;
  currentTagName: string;
  dimensionName: string;
  projectId: string;
}) => {
  const [newTagName, setNewTagName] = useState("");
  const projectApi = useProjectApi() as ProjectApi;

  const handleRename = async () => {
    await projectApi.mergeVerbatimTag(
      projectId,
      dimensionName,
      newTagName,
      newTagName,
      [currentTagName]
    );
    onRenamed();
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>Renommer "{currentTagName}"</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <YMInput
            type="text"
            label="Nouveau nom du tag"
            value={newTagName}
            onChange={(e) => setNewTagName(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <YMButton onClick={handleRename} text={"Confirmer"} />
        <YMButton color="white" onClick={onClose} text={"Annuler"} />
      </Modal.Footer>
    </Modal>
  );
};
