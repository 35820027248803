import { ProjectView, VerbatimTag } from "../../../../app_client";
import React, { useEffect, useState } from "react";
import { Badge, Tooltip } from "flowbite-react";
import { HiOutlineCheck, HiPlus, HiTrash, HiX } from "react-icons/hi";
import { DropDownMenu, DropDownMenuItem } from "../components";
import { useProjectApi } from "../../State";

export const VerbatimTagBadge = ({
  tag,
  project,
  onDelete,
  onScoreChange,
}: {
  tag: VerbatimTag;
  project: ProjectView;
  onDelete: () => void;
  onScoreChange?: (score: number) => void;
}) => {
  let api = useProjectApi();
  let [open, setOpen] = useState(false);
  let [_tag, setTag] = useState(tag);

  const get_color = (tag: VerbatimTag) => {
    let color = tag.color || "indigo";
    if (tag.dimension == "sentiments") {
      color = "gray";
      color =
        tag.label == "😀 positif"
          ? "green"
          : tag.label == "😖 négatif"
          ? "red"
          : color;
    }
    return color;
  };

  let [_color, setColor] = useState(get_color(tag));
  let [score, setScore] = useState(tag.score || 0);

  useEffect(() => {
    setColor(get_color(_tag));
  }, [_tag]);

  const dimension = project.dimensions?.find((d) => d.name == tag.dimension);

  const handleEditTag = (className: string) => {
    if (api) {
      api.editTag(project.id, tag.id, className);
      setTag({ ...tag, label: className });
      setScore(1);
      setOpen(false);
    }
  };

  const handleDeleteTag = () => {
    setOpen(false);
    onDelete();
  };

  const handleValidateTag = () => {
    setScore(1);
    onScoreChange && onScoreChange(1);
    setOpen(false);
  };

  return (
    <>
      <div className={"relative cursor-pointer"}>
        <Tooltip content={`Score: ${(_tag.score || 0).toPrecision(2)}`}>
          <Badge
            color={_color}
            className={`bg-${_color}-100 text-${_color}-800`}
            size="xs"
            onClick={() => setOpen(!open)}
          >
            <div className={"flex flex-row"}>
              {_tag.dimension == _tag.label
                ? _tag.label
                : _tag.dimension + ":" + _tag.label}
              {score === 1 && (
                <HiOutlineCheck
                  color={_color}
                  className={`text-${_color}-800`}
                  style={{ marginTop: 2 }}
                />
              )}
            </div>
          </Badge>
        </Tooltip>
        {open && (
          <div className={"absolute top-6 z-20"}>
            <a onClick={() => setOpen(false)}>
              <HiX />
            </a>
            <DropDownMenu
              color={_color}
              label={tag.dimension || "classe"}
              dropdown={true}
              items={
                <>
                  {dimension &&
                    (dimension.class_names || []).map((c) => (
                      <DropDownMenuItem
                        key={c}
                        icon={<HiPlus className={"w-3 h-3"} />}
                        color={_color}
                        label={c}
                        onClick={() => handleEditTag(c)}
                      />
                    ))}
                  <DropDownMenuItem
                    icon={<HiOutlineCheck className={"w-3 h-3"} />}
                    color={_color}
                    label="Valider le tag"
                    onClick={() => handleValidateTag()}
                  />
                  <DropDownMenuItem
                    icon={<HiTrash className={"w-3 h-3"} />}
                    color={_color}
                    label="Supprimer"
                    onClick={() => handleDeleteTag()}
                  />
                </>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};
