/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ClusteringAlgo {
    HDBSCAN = 'hdbscan',
    KMEANS = 'kmeans',
    MINI_BATCH_KMEANS = 'mini_batch_kmeans',
    INCREMENTAL_PCA = 'incremental_pca',
}
