import { ProjectApi, useProjectApi } from "../State";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  TagOccurencesWithRelatedTags,
  TagOccurencesWithRelatedTagsPaginated,
  TagType,
  VerbatimTag,
} from "../../../app_client";

export type tagsSearchParams = {
  dimension: string;
  page: number;
};

export const useSearchTagsQuery = ({
  projectId,
  searchParams,
}: {
  projectId: string;
  searchParams: tagsSearchParams;
}) => {
  const projectApi = useProjectApi() as ProjectApi;
  const queryClient = useQueryClient();

  const queryPatterns = async (p: number) => {
    let { dimension, page } = searchParams;
    return await projectApi.getProjectTags(
      projectId,
      page,
      dimension
    );
  };

  const _ProjectTags = useQuery({
    queryKey: ["_ProjectTags", projectId, searchParams],
    queryFn: () => {
      return queryPatterns(searchParams.page);
    },
    onSuccess: (data) => {
      prefetchNextPage();
    },
    enabled: !!projectApi,
    staleTime: 0,
  });

  const prefetchNextPage = () => {
    queryClient.prefetchQuery(
      [
        "_ProjectTags",
        projectId,
        {
          ...searchParams,
          page: searchParams.page + 1,
        },
      ],
      () => queryPatterns(searchParams.page + 1)
    );
  };

  const handleDeleteTag = async (
    tag_occurrence: TagOccurencesWithRelatedTags,
    tag: VerbatimTag
  ) => {
    if (projectApi && projectId) {
      await projectApi.deleteVerbatimTag(projectId, tag.id, true);
    }
    queryClient.setQueryData(
      ["_ProjectTags", projectId, searchParams],
      (oldData: TagOccurencesWithRelatedTagsPaginated | undefined) => {
        if (oldData === undefined) {
          return oldData;
        }
        return {
          ...oldData,
          data: removeTagFromTagOccurence(oldData.data, tag_occurrence, tag),
        };
      }
    );
  };

  const handleTagAPattern = async (
    tag: TagOccurencesWithRelatedTags,
    dimension: string,
    className: string,
    color: string
  ) => {
    queryClient.setQueryData(
      ["_ProjectTags", projectId, searchParams],
      (oldData: TagOccurencesWithRelatedTagsPaginated | undefined) => {
        if (oldData === undefined) {
          return oldData;
        }
        return {
          ...oldData,
          data: addRelatedTagsToTag(
            oldData.data,
            tag,
            className,
            dimension,
            color
          ),
        };
      }
    );
    await projectApi.duplicateATag({
      project_id: projectId,
      dimension: tag.dimension,
      label: tag.label,
      name: tag.name,
      target_dimension: dimension,
      target_label: className,
    });
  };

  return {
    _ProjectTags,
    handleDeleteTag,
    handleTagAPattern,
    prefetchNextPage,
  };
};


const addRelatedTagsToTag = (
  tags: TagOccurencesWithRelatedTags[],
  tag: TagOccurencesWithRelatedTags,
  className: string,
  dimension: string,
  color: string
) => {
  return tags.map((t: TagOccurencesWithRelatedTags) => {
    if (tag.id === t.id) {
      return {
        ...t,
        tags: [
          ...(t.tags || []),
          {
            id: "fake_id",
            verbatim_id: "fake_verbatim_id",
            type: TagType.TOKEN_CLASSIFICATION,
            dimension: dimension,
            label: className || t.label,
            color: color,
            score: 1,
          },
        ],
      };
    } else {
      return t;
    }
  });
};

const removeTagFromTagOccurence = (
  tag_occurrences: TagOccurencesWithRelatedTags[],
  tag_occurrence: TagOccurencesWithRelatedTags,
  tag: VerbatimTag
) => {
  return tag_occurrences.map((t) => {
    if (t.id === tag_occurrence.id) {
      return {
        ...t,
        tags: (t.tags || []).filter((t) => t.id !== tag.id),
      };
    } else {
      return t;
    }
  });
};
