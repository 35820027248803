import React, { useCallback, useEffect, useState } from "react";
import { useProjectApi, useProjectQuery } from "../../State";
import { useApi } from "../../../../api";
import {
  ProjectDimension,
  ProjectView,
  TagType,
  VerbatimViewWithTagTokens,
} from "../../../../app_client";
import { onDeleteParams, onTagParams } from "../../VerbatimToken";
import { YMLoader, YMTable } from "../../../../ym-components";
import { YMNavBar } from "../components";
import { Badge } from "flowbite-react";
import { HiFilter } from "react-icons/hi";
import Verbatim from "./Verbatim";
import { VerbatimTagBadge } from "./VerbatimTag";
import AddATagMenu from "./AddTagMenu";
import { useParams } from "react-router-dom";

export type VerbatimListProps = {
  lemmasFilter?: string;
  posFilter?: string;
  dimensionFilter?: string;
  classNameFilter?: string;
  subsetFilter?: string;
  graphDimensionsFilter?: string;
  graphNodesFilter?: string;
  orderByClassScore?: string;
};

export const VerbatimList: React.FC<VerbatimListProps> = ({
  lemmasFilter,
  posFilter,
  dimensionFilter,
  classNameFilter,
  subsetFilter,
  graphDimensionsFilter,
  graphNodesFilter,
    orderByClassScore
}) => {
  const { projectId } = useParams() as { projectId: string };
  const _project = useProjectQuery({ refetchInterval: 0 });

  const { api } = useApi();
  const projectApi = useProjectApi();

  const [verbatims, setVerbatims] = useState([] as VerbatimViewWithTagTokens[]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(-1);

  const [dimensions, setDimensions] = useState([] as ProjectDimension[]);

  useEffect(() => {
    // update dimensions only when they changes to avoid unnecessary re-rendering
    if (
      _project.data?.dimensions.map((d) => d.id).join(",") !=
      dimensions.map((d) => d.id).join(",")
    ) {
      setDimensions(_project.data.dimensions);
    }
  }, [_project.data?.dimensions]);

  // refresh pattern
  const refreshVerbatims = useCallback(
    (page: number) => {
      if (api && projectId) {
        setLoading(true);
        api
          .list_verbatims(
            projectId,
            lemmasFilter || undefined,
            posFilter || undefined,
            dimensionFilter || undefined,
            classNameFilter || undefined,
            subsetFilter || undefined,
            graphDimensionsFilter,
            graphNodesFilter || undefined,
            orderByClassScore || undefined,
            false,
            undefined,
            page
          )
          .then((r: any) => {
            setLoading(false);
            setVerbatims(r.data);
            setCurrentPage(r.page);
            setPageCount(r.page_count);
            setTotal(r.total);
          });
      }
    },
    [api, lemmasFilter, posFilter, dimensionFilter, classNameFilter, projectId]
  );

  useEffect(() => {
    refreshVerbatims(currentPage);
  }, [api, projectId]);

  const handleDownload = async () => {
    if (api && projectId) {
      await api.verbatims_as_csv(
        projectId,
        lemmasFilter || undefined,
        posFilter || undefined,
        dimensionFilter || undefined,
        classNameFilter || undefined,
        subsetFilter || undefined,
        graphDimensionsFilter,
        graphNodesFilter || undefined,
        undefined
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setTimeout(() => refreshVerbatims(page), 10);
  };

  const handleDeleteTag = useCallback(
    (verbatim_id: string, verbatim_i: number, params: onDeleteParams) => {
      let cverbatims = [...verbatims];

      if (cverbatims[verbatim_i]) {
        // @ts-ignore
        let tag_index = cverbatims[verbatim_i].tags
          .map((t) => t.id)
          .indexOf(params.tagId);
        cverbatims[verbatim_i].tagged_tokens = cverbatims[
          verbatim_i
        ].tagged_tokens.map((token) => ({
          ...token,
          // @ts-ignore
          tag_index: token.tag_index.filter((ti) => ti != tag_index),
        }));
      }

      setVerbatims(cverbatims);
      api.delete_verbatim_tag(
        projectId,
        params.tagId,
        params.extendToSimilarToken
      );
    },
    [api, verbatims]
  );

  const handleDeleteTagVerbatim = useCallback(
    (verbatim_id: string, verbatim_i: number, tagId: string) => {
      let cverbatims = [...verbatims];

      if (cverbatims[verbatim_i]) {
        // @ts-ignore
        cverbatims[verbatim_i].tags = cverbatims[verbatim_i].tags.filter(
          (t) => t.id != tagId
        );
      }

      setVerbatims(cverbatims);
      if (projectApi && projectId)
        projectApi.deleteVerbatimTag(projectId, tagId);
    },
    [projectApi, verbatims]
  );

  const handleAddVerbatimTag = useCallback(
    (
      verbatim_id: string,
      verbatim_i: number,
      dimension: string,
      className: string
    ) => {
      let cverbatims = [...verbatims];

      if (cverbatims[verbatim_i]) {
        let projectDimensions = (_project.data as ProjectView).dimensions || [];
        let dimension_o = projectDimensions.find((d) => d.name == dimension);

        // creating new dimension refresh project
        if (!dimension_o) {
          _project.refetch();
        }

        (cverbatims[verbatim_i].tags || []).push({
          id: "fake-id",
          verbatim_id: verbatim_id,
          type: TagType.TEXT_CLASSIFICATION,
          dimension: dimension,
          label: className,
          color: dimension_o ? dimension_o.color : "indigo",
          score: 1,
        });
      }

      setVerbatims(cverbatims);
      if (projectApi && projectId)
        projectApi.addAVerbatimTag(
          projectId,
          verbatim_id,
          dimension,
          className
        );
    },
    [projectApi, verbatims]
  );

  const handleTagToken = useCallback(
    (verbatim_id: string, verbatim_i: number, params: onTagParams) => {
      let cverbatims = [...verbatims];
      let projectDimensions = (_project.data as ProjectView).dimensions || [];
      let dimension_o = projectDimensions.find(
        (d) => params.dimension == d.name
      );

      // creating new dimension refresh project
      if (!dimension_o) {
        _project.refetch();
      }

      if (cverbatims[verbatim_i]) {
        let tags = cverbatims[verbatim_i].tags;
        if (tags) {
          tags.push({
            id: "fake-id",
            verbatim_id: verbatim_id,
            type: TagType.TOKEN_CLASSIFICATION,
            token_position: [params.tokenIndex, params.tokenIndex],
            dimension: params.dimension,
            label: params.className,
            score: 1,
            color: params.color,
          });
          if (cverbatims[verbatim_i].tagged_tokens[params.tokenIndex]) {
            let tag_index =
              cverbatims[verbatim_i].tagged_tokens[params.tokenIndex].tag_index;
            if (tag_index) {
              tag_index.push(tags.length - 1);
            }
          }
        }
      }

      setVerbatims(cverbatims);

      let rule = params.token_text
        .split(" ")
        .map((token) => ({ LEMMA: token }));
      if (rule.length <= 1) {
        api.tag_verbatim_token({
          project_id: projectId || "",
          verbatim_id: verbatim_id,
          token_index: params.tokenIndex,
          dimension: params.dimension,
          label: params.className,
          generalize: params.extendToSimilarToken,
          rule: params.token_text.split(" ").map((token) => ({ LEMMA: token })),
        });
      } else {
        projectApi?.tagAPattern({
          project_id: projectId || "",
          rule: rule,
          dimension: params.dimension,
          label: params.className,
        });
      }
    },
    [api, verbatims, projectId]
  );

  return (
    <>
      <div>
        <YMLoader message={"Chargement des verbatims"} loading={loading}>
          <div className={"flex mt-10 justify-between items-end"}>
            <div className={"flex gap-2"}>
              {pageCount > 1 && (
                <YMNavBar
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  totalPages={pageCount}
                  previousLabel={"Précédent"}
                  nextLabel={"Suivant"}
                />
              )}
              {total > -1 && (
                <div className={"text-xs text-indigo-900 flex items-end"}>
                  {total} verbatims
                </div>
              )}
              {lemmasFilter && (
                <Badge color="indigo" icon={HiFilter} size="xs">
                  lemma:{lemmasFilter}
                </Badge>
              )}
              {posFilter && (
                <Badge color="indigo" icon={HiFilter} size="xs">
                  pos:{posFilter.toLowerCase()}
                </Badge>
              )}
              {dimensionFilter && (
                <Badge color="indigo" icon={HiFilter} size="xs">
                  dimension:{dimensionFilter}
                </Badge>
              )}
              {subsetFilter && (
                <Badge color="indigo" icon={HiFilter} size="xs">
                  {subsetFilter}{" "}
                </Badge>
              )}
              {classNameFilter && (
                <Badge color="indigo" icon={HiFilter} size="xs">
                  classe:{classNameFilter}
                </Badge>
              )}
            </div>
            <div className={"flex gap-2 items-end"}>
              <a
                className={"text-indigo-900 hover:underline cursor-pointer"}
                onClick={handleDownload}
              >
                {" "}
                Télécharger au format .csv{" "}
              </a>
            </div>
          </div>
          <div className="relative rounded-lg border border-gray-200 bg-white mt-2">
            <YMTable
              tableClassName={"mb-96"}
              header={
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Verbatims
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only"> x </span>
                  </th>
                </tr>
              }
              rows={verbatims.map((v, i) => (
                <tr
                  key={v.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                >
                  <td
                    scope="row"
                    className="w-3/5 px-6 py-4 text-gray-900  hover:text-indigo-900 dark:text-white"
                  >
                    <Verbatim
                      dimensions={dimensions}
                      verbatim={v}
                      onTag={(params) => handleTagToken(v.id, i, params)}
                      onDelete={(params) => handleDeleteTag(v.id, i, params)}
                    />
                  </td>
                  <td className="w-2/5 py-5 pr-10">
                    <div className={"flex flex-wrap gap-2 pr-20"}>
                      {v.tags
                        ?.filter((t) => t.type == TagType.TEXT_CLASSIFICATION || t.type == TagType.META_DATA)
                          .sort((a, b) => (a.dimension||'').localeCompare(b.dimension||''))
                        .map((t) => (
                          <VerbatimTagBadge
                            key={t.id}
                            tag={t}
                            project={_project.data as ProjectView}
                            onScoreChange={(score) => projectApi?.scoreATag(t.id, score)}
                            onDelete={() =>
                              handleDeleteTagVerbatim(v.id, i, t.id)
                            }
                          />
                        ))}

                      <div>
                        <AddATagMenu
                          project={_project.data as ProjectView}
                          tagType={TagType.TEXT_CLASSIFICATION}
                          handleAddTag={(dim, classname) =>
                            handleAddVerbatimTag(v.id, i, dim, classname)
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
          <div className={"flex gap-2"}>
            {pageCount > 1 && (
              <YMNavBar
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={pageCount}
                previousLabel={"Précédent"}
                nextLabel={"Suivant"}
              />
            )}
          </div>
        </YMLoader>
      </div>
    </>
  );
};
