/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DimRec {
    UMAP = 'umap',
    INCREMENTAL_PCA = 'incremental_pca',
    SKIP = 'skip',
}
