import { DocumentView } from "../../../app_client";
import { Badge, Modal } from "flowbite-react";
import { YMButton } from "../../../ym-components";
import React, { useEffect, useState } from "react";

export const ImportMetadataModal = ({
  doc,
  onCloseModal,
  onImportMetadata,
}: {
  doc: DocumentView;
  onImportMetadata: (doc: DocumentView, metadata: string[]) => void;
  onCloseModal: () => void;
}) => {
  const [selectedMetadata, setSelectedMetadata] = useState<string[]>([]);
  const [hasMetadata, setHasMetadata] = useState<boolean>(false);

  const toogleMetada = (meta: string) => {
    if (selectedMetadata.indexOf(meta) == -1) {
      setSelectedMetadata([...selectedMetadata, meta]);
    } else {
      setSelectedMetadata(selectedMetadata.filter((d) => d !== meta));
    }
  };

  useEffect(() => {
    setHasMetadata(!!(doc.metadata_columns && doc.metadata_columns.length > 0));
  }, [doc]);

  return (
    <>
      <Modal
        root={document.body}
        size="5xl"
        show={doc !== undefined}
        onClose={onCloseModal}
      >
        <Modal.Header>
          <div className={"font-bold text-indigo-800 title"}>
            {" Importer des métadonnées depuis "}
            {doc?.document_name}
            {doc?.document_extension}
          </div>
        </Modal.Header>
        <Modal.Body>
          {hasMetadata && (
            <>
              <p> Sélectionnez les metadonnées à importer </p>
              <div className={"flex flex-wrap gap-2"}>
                {(doc.metadata_columns || []).map((col) => (
                  <Badge
                    className={"cursor-pointer py-1"}
                    color={
                      selectedMetadata.indexOf(col) === -1 ? "gray" : "info"
                    }
                    size="xs"
                    onClick={() => toogleMetada(col)}
                  >
                    {col}
                  </Badge>
                ))}
              </div>
            </>
          )}

          {!hasMetadata && (
            <p> Aucune métadonnée n'est disponible pour ce document </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {hasMetadata && (
            <YMButton
              text={"Importer"}
              onClick={() => onImportMetadata(doc, selectedMetadata)}
              disabled={selectedMetadata.length === 0}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
