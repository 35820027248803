import React, { useEffect, useState } from "react";
import {
  YMIcons,
  YMInput,
  YMLoader,
  YMTutorialTips,
} from "../../ym-components";
import { useProject, useProjectApi } from "./State";
import { Badge } from "flowbite-react";
import { ColorChart, ProjectDimension } from "../../app_client";
import { ProjectBreadcrumb } from "./components/components";
import { useLocation } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { BiBarChartAlt } from "react-icons/bi";
import { MdTableChart } from "react-icons/md";
import { debounce } from "../../util";
import { VerbatimsModal } from "./components/verbatims/VerbatimModal";

export default function ProjectCrossAnalyse(props: any) {
  const location = useLocation();
  const { _project } = useProject();

  return (
    <YMLoader
      loading={_project.isLoading}
      message={"Chargement de " + _project.data.name}
    >
      <ProjectBreadcrumb
        breadcrumb={[{ title: "Analyse croisée", link: location.pathname }]}
      />
      <div className={"pl-5"}>
        <CrossAnalyse />
      </div>
    </YMLoader>
  );
}

const CrossAnalyse = () => {
  const api = useProjectApi();
  const { projectId, _project } = useProject();

  const [dimensionIndex, setDimensionIndex] = useState([1, 2] as number[]);
  const [userHasSelectDimension, setUserHasSelectDimension] = useState(false);

  const [chartType, setChartType] = useState("sunburst");
  const [iframeUrl, setIframeUrl] = useState("");
  const [maxCategoriesDim1, setMaxCategoriesDim1] = useState(10);
  const [maxCategoriesDim2, setMaxCategoriesDim2] = useState(10);
  const [isIframeLoaded, setIframeIsLoaded] = useState("");
  const [color, setColor] = useState(ColorChart.INDIGO);
  const [dimensions, setDimensions] = useState([] as ProjectDimension[]);

  const [verbatimModalProps, setVerbatimModalProps] = useState({
    show: false,
    subsetFilter: "",
  });

  useEffect(() => {
    setDimensions(_project.data.dimensions);
  }, [_project.data.dimensions]);

  // autoselect dimensions for the first time
  useEffect(() => {
    if (!userHasSelectDimension && dimensions.length > 2) {
      let indexOfPublic = dimensions.findIndex((d) => d.name === "publics");
      let indexOfValeur = dimensions.findIndex((d) => d.name === "valeurs");
      setDimensionIndex([indexOfPublic, indexOfValeur]);
    }
  }, [dimensions, userHasSelectDimension]);

  const handleSelectDimension = (axe: number, dimension: number) => {
    const newDimensionIndex = [...dimensionIndex];
    newDimensionIndex[axe] = dimension;
    setDimensionIndex(newDimensionIndex);
    setUserHasSelectDimension(true);
  };

  const updateIframe = debounce(() => {
    if (api && projectId) {
      // @ts-ignore
      if (dimensions.length > 2) {
        const params = new URLSearchParams({
          parents: dimensions[dimensionIndex[0]].name,
          childs: dimensions[dimensionIndex[1]].name,
          maxClassParents: maxCategoriesDim1.toString(),
          maxClassChilds: maxCategoriesDim2.toString(),
          color: color,
        });
        setIframeUrl(
          api.getMultiDimensionalPlotIframeUrl(projectId, chartType) +
            `?${params.toString()}`
        );
      }
    }
  }, 2000);

  useEffect(() => {
    updateIframe();
  }, [dimensions, maxCategoriesDim1, maxCategoriesDim2, chartType]);

  const colors = [
    ColorChart.GREEN,
    ColorChart.RED,
    ColorChart.AMBER,
    ColorChart.EMERALD,
    ColorChart.BLUE,
    ColorChart.INDIGO,
    ColorChart.VIOLET,
    ColorChart.PINK,
  ];

  setTimeout(updateIframe, 100);

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        setVerbatimModalProps({
          show: true,
          subsetFilter: e.data.split("DISPLAY_VERBATIMS_")[1],
        });
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  return (
    <>
      <VerbatimsModal
        show={verbatimModalProps.show}
        onClose={() => setVerbatimModalProps({ show: false, subsetFilter: "" })}
        subsetFilter={verbatimModalProps.subsetFilter}
      />

      <div className={"flex items-center gap-1 mt-2"}>
        <div>
          <YMIcons.Dimension className={"h-6 w-6 text-indigo-900"} />
        </div>
        <div className={"flex gap-2"}>
          <h3 className={"text-indigo-900 text-2xl font-bold"}>
            Analyse Croisée
          </h3>
          <YMTutorialTips
            content={
              <p>
                Croisez les informations contenues dans votre corpus en
                choisissant un axe principal et un axe secondaire d'analyse.
                <br />
                Exemple : s'intéresser aux organisations (axe principal) et
                visualiser les jugements portés sur elles (axe secondaire).
                <br />
                En cliquant sur les jugements, vous accédez aux verbatims
                concernés, ce qui facilite la fouille sémantique pour trouver
                l'information pertinente.
              </p>
            }
          />
        </div>
      </div>

      <div className={""}>
        {dimensions.filter((d) => d.status === "TAGGED").length == 0 ? (
          <div
            className={
              "text-indigo-400 text-xl font-medium text-center mt-10 animate-text bg-gradient-to-r from-purple-400 to-purple-600   bg-clip-text text-transparent"
            }
          >
            <div>
              L'analyse du projet est en cours. Les tags apparaîtront ici une
              fois l'analyse terminée.
            </div>
          </div>
        ) : (
          <div className={"mt-1"}>
            <div className={"text-indigo-800"}>
              Explorez et croisez vos données selon les{" "}
              <b>{dimensions.length}</b> modèle(s) d'analyse de votre projet.
              <div className={"mt-5"}>
                <div>
                  <div
                    className={"uppercase text-indigo-900 text-xs font-bold"}
                  >
                    {" "}
                    Couleur
                  </div>
                  <div className={"flex items-center gap-2 mt-2"}>
                    {colors.map((c, i) => (
                      <div
                        onClick={() => setColor(c)}
                        className={`h-3 w-3 cursor-pointer ring-2 bg-${c}-500 hover:bg-${c}-800 rounded-3xl ring-gray-200 ${
                          color === c ? "h-5 w-5" : "h-3 w-3"
                        } `}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={"mt-5"}>
                <div>
                  <div
                    className={"uppercase text-indigo-900 text-xs font-bold"}
                  >
                    {" "}
                    Représentation
                  </div>
                  <div className={"flex flex-wrap gap-2 mt-2"}>
                    <Badge
                      icon={FaChartPie}
                      onClick={() => setChartType("sunburst")}
                      color={chartType == "sunburst" ? "purple" : "gray"}
                      className={"cursor-pointer"}
                    >
                      sunburst
                    </Badge>
                    <Badge
                      icon={BiBarChartAlt}
                      onClick={() => setChartType("icicle")}
                      color={chartType == "icicle" ? "purple" : "gray"}
                      className={"cursor-pointer"}
                    >
                      icicle
                    </Badge>
                    <Badge
                      icon={MdTableChart}
                      onClick={() => setChartType("treemap")}
                      color={chartType == "treemap" ? "purple" : "gray"}
                      className={"cursor-pointer"}
                    >
                      treemap
                    </Badge>
                  </div>
                </div>
              </div>
              <div className={"flex gap-4 items-start mt-5"}>
                <div>
                  <div
                    className={"uppercase text-indigo-900 text-xs font-bold"}
                  >
                    {" "}
                    Axe principal
                  </div>
                  <div className={"flex flex-wrap gap-2 mt-2"}>
                    {dimensions.map(
                      (dimension, index) =>
                        dimension.status === "TAGGED" && (
                          <Badge
                            key={index}
                            onClick={() => handleSelectDimension(0, index)}
                            color={
                              dimensionIndex[0] != index ? "gray" : "purple"
                            }
                            className={"cursor-pointer"}
                          >
                            {dimension.name}
                          </Badge>
                        )
                    )}
                  </div>
                  <div className={"mt-3 w-96"}>
                    <YMInput
                      type="number"
                      label={"Nombre de catégories max"}
                      value={maxCategoriesDim1}
                      onChange={(v) => setMaxCategoriesDim1(parseInt(v))}
                    />
                  </div>
                </div>
                <div className={"border-l border-gray-300 ml-5 pl-5"}>
                  <div
                    className={"uppercase text-indigo-900 text-xs font-bold"}
                  >
                    {" "}
                    Axe secondaire
                  </div>
                  <div className={"flex flex-wrap gap-2 mt-2"}>
                    {dimensions.map(
                      (dimension, index) =>
                        dimension.status === "TAGGED" && (
                          <Badge
                            key={index}
                            onClick={() => handleSelectDimension(1, index)}
                            color={
                              dimensionIndex[1] != index ? "gray" : "purple"
                            }
                            className={"cursor-pointer"}
                          >
                            {dimension.name}
                          </Badge>
                        )
                    )}
                  </div>
                  <div className={"mt-3 w-96"}>
                    <YMInput
                      type="number"
                      label={"Nombre de catégories max"}
                      value={maxCategoriesDim2}
                      onChange={(v) => setMaxCategoriesDim2(parseInt(v))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div></div>
            <div className="w-100 mt-10 flex justify-center items-center">
              <div className={"text-center"}>
                <div className={"mt-4 text-indigo-900"}>
                  {isIframeLoaded == iframeUrl ? (
                    <a href={iframeUrl} target={"_blank"} rel="noreferrer">
                      {" "}
                      Ouvrir la dataviz en plein écran{" "}
                    </a>
                  ) : (
                    "Mise à jours du graphe en cours ..."
                  )}
                </div>
              </div>
            </div>
            <div className={"mt-5"}>
              <iframe
                onLoad={() => setIframeIsLoaded(iframeUrl)}
                style={{ width: "100%", height: "700px" }}
                src={iframeUrl}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
