import {
  HiCheckCircle,
  HiDocument,
  HiUpload,
  HiX,
  HiXCircle,
} from "react-icons/hi";
import { Pagination, Spinner } from "flowbite-react";
import React, { useState } from "react";
import { BreadcrumbLink, YMBreadCrumb } from "../../../ym-components";
import { useProject } from "../State";

export const FileToast = (props: any) => {
  return (
    <div
      id="toast-default"
      className="flex items-center w-full max-w-xl p-4 text-gray-500 rounded-lg backdrop-blur-md bg-opacity-40 bg-white shadow-md border border-purple-200"
      role="alert"
    >
      {props.state === "done" && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
          <HiCheckCircle />
        </div>
      )}

      {props.state === undefined && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
          <HiDocument />
        </div>
      )}

      {props.state === "error" && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
          <HiXCircle />
        </div>
      )}

      {props.state === "uploading" && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
          <HiUpload />
        </div>
      )}

      <div className={"ml-3 flex flex-col"}>
        <div className="text-sm font-normal">{props.children}</div>
      </div>

      {props.state === "uploading" && (
        <Spinner size="xs" className={"ml-2 mb-1"} />
      )}

      <button
        type="button"
        onClick={props.onDelete}
        className="ml-auto text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex  dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast-default"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <HiX />
      </button>
    </div>
  );
};

export function DropDownMenuItem({
  label,
  color,
  icon,
  onClick,
}: {
  label: string;
  color: string;
  icon?: JSX.Element;
  onClick?: any;
}) {
  return (
    <li
      onClick={() => onClick()}
      className={`flex h-fit items-center gap-1 font-semibold bg-${color}-100 hover:bg-${color}-200 text-${color}-800 dark:bg-blue-200 dark:text-blue-900 group-hover:bg-blue-200 dark:group-hover:bg-blue-300 rounded px-2 py-0.5 p-1 text-xs`}
    >
      <div>{icon} </div>
      <div>{label}</div>
    </li>
  );
}

export function DropDownMenu({
  label,
  color,
  items,
  dropdown,
}: {
  label: string;
  color: string;
  items: JSX.Element | undefined;
  dropdown?: boolean;
}) {
  const [_dropdown, setDropdown] = useState(dropdown === true);

  return (
    <div className={" "}>
      <button
        style={{ width: "200px" }}
        onClick={() => setDropdown(!_dropdown)}
        id="multiLevelDropdownButton"
        data-dropdown-toggle="dropdown"
        className={`flex h-fit items-center gap-1 font-semibold bg-${color}-100 text-${color}-800 dark:bg-blue-200 dark:text-blue-900 group-hover:bg-blue-200 dark:group-hover:bg-blue-300 rounded px-2 py-0.5 p-1 text-xs`}
        type="button"
      >
        {label}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      <div
        style={{ width: "200px" }}
        className={
          "z-10 bg-gradient-to-r from-blue-50 to-indigo-50 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 " +
          (!_dropdown && "hidden")
        }
      >
        <ul>{items}</ul>
      </div>
    </div>
  );
}

export const YMNavBar = Pagination;

export function ProjectBreadcrumb({
  breadcrumb,
}: {
  breadcrumb: BreadcrumbLink[];
}) {
  const { projectId, _project } = useProject();

  return (
    <YMBreadCrumb
      links={[
        { title: "Projets", link: "/project" },
        {
          title: _project.data.name,
          link: "/project/" + projectId + "?name=" + _project.data.name,
        },
        ...(breadcrumb || []),
      ]}
    />
  );
}

export const YMIframe: React.FC<
  React.IframeHTMLAttributes<HTMLIFrameElement>
> = (props) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading && (
        <div className="w-100 mt-10 flex justify-center items-center">
          <div className={"text-center"}>
            <Spinner color="gray" size="xl" />
            <div className={"mt-4 text-gray-400"}>
              {" "}
              Chargement de la Dataviz
            </div>
          </div>
        </div>
      )}
      <iframe {...props} onLoad={() => setLoading(false)} />
    </>
  );
};