/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Language {
    FR = 'fr',
    EN = 'en',
}
