import { FewShotLearningComponentModel } from "../Model";
import { YMLabel, YMSelect } from "../../../../ym-components";
import React from "react";

export const SelectDimension = ({
  state,
  onSelect,
}: Pick<FewShotLearningComponentModel, "state"> & {
  onSelect: (d: string) => void;
}) => {
  const onDimensionChange = (d: string) => {
    onSelect(d);
    // @ts-ignore
    setTimeout(() => document.activeElement.blur(), 0); // focus on the card to re-enable keyboard shortcuts
  };
  return (
    <div className={"flex flex-col mt-5"}>
      <div className="flex gap-4 w-42">
        <div className={"w-80"}>
          <YMLabel text="Dimension" />
          <YMSelect
            label={"Dimension"}
            value={state.selectedDimensionName}
            options={state.dimensionsOptions}
            onChange={onDimensionChange}
          />
        </div>
      </div>
    </div>
  );
};