import { FewShotLearningComponentModel } from "../Model";
import { VerbatimViewWithTagTokens } from "../../../../app_client";
import React, { useEffect } from "react";
import { Badge } from "flowbite-react";

export const Verbatims = ({
  actions,
  state,
}: Pick<FewShotLearningComponentModel, "actions" | "state">) => {
  const verbatimCounter = () => {
    if (!state.projectTotalVerbatims) return;
    return state.verbatims
      ? `${state.projectTotalVerbatims - state.verbatims.total} / ${
          state.projectTotalVerbatims
        } verbatims taggués`
      : "";
  };

  return (
    <>
      <div className={"text-gray-500 text-sm justify-self-end mt-2"}>
        {verbatimCounter()}
      </div>
      <div className="flex flex-col ml-2 mt-5">
        {(state.verbatims?.data || []).map((v: VerbatimViewWithTagTokens) => (
          <FewShotLearningVerbatimRow
            key={v.id}
            verbatim={v}
            isActive={state.activeVerbatim?.id == v.id}
            classes={state.selectedDimensionClasses}
            activeClasses={state.activeClasses}
            onToggleClass={(c: string) => actions.toggleClass(c)}
          />
        ))}
      </div>
    </>
  );
};

const FewShotLearningVerbatimRow = ({
  verbatim,
  classes,
  activeClasses,
  onToggleClass,
  isActive,
}: {
  verbatim: VerbatimViewWithTagTokens;
  isActive: boolean;
  classes: string[];
  activeClasses: string[];
  onToggleClass: (classname: string) => void;
}) => {
  useEffect(() => {
    (verbatim.tags || []).forEach((t) => {
      if (t.label && classes.indexOf(t.label) >= 0) {
        onToggleClass(t.label);
      }
    });
  }, [verbatim]);

  return (
    <div
      className={
        "flex gap-4 w-42 my-4 rounded p-3 shadow border border-gray-200 text-md " +
        (isActive ? "bg-indigo-50" : "bg-white")
      }
    >
      <div className={"w-2/3"}> {verbatim.data} </div>
      {isActive && (
        <div className={"w-1/3 flex flex-wrap gap-2"}>
          {classes.map((c, i) => (
            <Badge
              key={c}
              onClick={() => onToggleClass(c)}
              color={activeClasses.indexOf(c) >= 0 ? "green" : "grey"}
              className={`cursor-pointer bg-${
                activeClasses.indexOf(c) >= 0 ? "green" : "grey"
              }-100 text-${
                activeClasses.indexOf(c) >= 0 ? "green" : "grey"
              }-800`}
              size="xs"
            >
              {c}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};
