/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum domains__topic_modeling__topic_modeling__JobStatus {
    WAITING = 'WAITING',
    MODEL_LOADING = 'MODEL_LOADING',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}
