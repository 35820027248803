function copyStylesInline(destinationNode, sourceNode) {
  var containerElements = ["svg", "g"];
  for (var cd = 0; cd < destinationNode.childNodes.length; cd++) {
    var child = destinationNode.childNodes[cd];
    if (containerElements.indexOf(child.tagName) !== -1) {
      copyStylesInline(child, sourceNode.childNodes[cd]);
      continue;
    }
    var style =
      sourceNode.childNodes[cd].currentStyle ||
      window.getComputedStyle(sourceNode.childNodes[cd]);
    if (style === "undefined" || style === null) continue;
    for (var st = 0; st < style.length; st++) {
      child.style.setProperty(style[st], style.getPropertyValue(style[st]));
    }
  }
}

function triggerDownload(imgURI, fileName) {
  var evt = new MouseEvent("click", {
    view: window,
    bubbles: false,
    cancelable: true,
  });
  var a = document.createElement("a");
  a.setAttribute("download", fileName);
  a.setAttribute("href", imgURI);
  a.setAttribute("target", "_blank");
  a.dispatchEvent(evt);
}

export function downloadPngImage() {
  var svg = document.querySelector("#corpusgraph");
  var copy = svg.cloneNode(true);
  copyStylesInline(copy, svg);
  var canvas = document.createElement("canvas");
  // var bbox = svg.getBBox();
  var bbox = svg.getBoundingClientRect();
  canvas.width = Math.round(bbox.width * 1.2);
  canvas.height = Math.round(bbox.height * 1.2);
  var topMargin = Math.round(bbox.height / 10);
  var leftMargin = Math.round(bbox.width / 10);
  var ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  var data = new XMLSerializer().serializeToString(copy);
  var DOMURL = window.URL || window.webkitURL || window;
  var img = new Image();
  var svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
  var url = DOMURL.createObjectURL(svgBlob);
  img.onload = function () {
    ctx.drawImage(img, leftMargin, topMargin);
    DOMURL.revokeObjectURL(url);
    if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
      var blob = canvas.msToBlob();
      navigator.msSaveOrOpenBlob(blob, "export.png");
    } else {
      var imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      triggerDownload(imgURI, "export.png");
    }
    canvas.remove();
  };
  img.src = url;
}
