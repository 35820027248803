import {AppCLient, WorkspaceTokenMove, WorkspaceView} from "../../app_client";
import {AuthUserContext} from "../../AuthUserContext";
import {useContext, useEffect, useState} from "react";

interface WorkspaceApi {
    getWorkspaces: () => Promise<WorkspaceView[]>;

    getWorkspace(id: string): Promise<WorkspaceView>;

    creditWorkspace(id: string, credit: number): Promise<void>;

    getWorkspaceTokenMoves(id: string): Promise<WorkspaceTokenMove[]>;

    getPortalUrl(id: string): Promise<string>;

    getInvitationUrl(id: string): Promise<string>;
    getWorkspaceName(token: string): Promise<string>;
    joinWorkspace(token: string): Promise<string>;
}

export const useWorkspaceApi = (): WorkspaceApi | null => {
    const {token} = useContext(AuthUserContext);
    const [api, setApi] = useState<WorkspaceApi | null>(null);

    useEffect(() => {
        if (token) {

            let client = new AppCLient({
                BASE: process.env.REACT_APP_APP_API_HOST,
                TOKEN: token,
            })

            setApi({
                getWorkspaces: () => {
                    return client.workspace.getWorkspacesWorkspaceGet()
                },
                getWorkspace: (id: string) => {
                    return client.workspace.getWorkspaceWorkspaceWorkspaceIdGet(id)
                },
                creditWorkspace: async (id: string, credit: number) => {
                    await client.workspace.creditWorkspaceTokenWorkspaceWorkspaceIdCreditTokenPost(id, {amount: credit})
                },
                getWorkspaceTokenMoves: (id: string) => {
                    return client.workspace.getWorkspaceTokenMovesWorkspaceWorkspaceIdTokenMovesGet(id)
                },
                getPortalUrl: (id: string) => {
                    return client.workspace.getCustomerPortalWorkspaceWorkspaceIdPaymentPortalGet(id)
                },
                getInvitationUrl: (id: string) => {
                    return client.workspace.getWorkspaceInvitationLinkWorkspaceWorkspaceIdInvitationLinkGet(id)
                },
                getWorkspaceName(token: string): Promise<string> {
                    return client.workspace.getWorkspaceNameWorkspaceTokenWorkspaceTokenNameGet(token)
                },
                async joinWorkspace(token: string): Promise<string> {
                    const workspace = await client.workspace.joinWorkspaceWorkspaceJoinWorkspacePost({workspace_token: token})
                    return workspace.workspace_id
                }
            })

        }
    }, [token]);

    return api;
}