/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WorkspaceTokenMoveReason {
    CHUNK_DEBITED = 'CHUNK_DEBITED',
    TOKEN_CREDITED = 'TOKEN_CREDITED',
}
