import {useWorkspaceApi} from "./Api";
import React, {useContext, useEffect, useState} from "react";
import {WorkspaceView} from "../../app_client";
import {Spinner} from "flowbite-react";
import {YMBreadCrumb, YMButton, YMTable} from "../../ym-components";
import {useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../AuthUserContext";


export default function WorkspaceList() {

    const {setWorkspaceId} = useContext(AuthUserContext)

    const workspaceApi = useWorkspaceApi();
    const [workspaces, setWorkspaces] = useState<WorkspaceView[] | "loading">("loading");
    const navigate = useNavigate();

    useEffect(() => {
        if (workspaceApi) {
            workspaceApi.getWorkspaces().then(setWorkspaces);
        }
    }, [workspaceApi]);

    const handleSelectWorkspace = (workspaceId: string) => {
        setWorkspaceId(workspaceId);
        navigate('/');
    }

    return <div>

        <YMBreadCrumb links={[{title: "Workspaces", link: "/workspaces"}]}/>

        {workspaces === "loading" && <div className="w-100 mt-10 text-center">
            <Spinner color="gray" size="xl"/>
            <div className="mt-4 text-gray-400">Chargement des workspaces ...</div>
        </div>}

        {workspaces != 'loading' && <div className="w-100 mt-5 text-center">
            <YMTable
                header={
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Workspace
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Propriétaire
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Invités
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Crédit
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only"> x </span>
                        </th>
                    </tr>
                }
                rows={
                    (workspaces as WorkspaceView[]).map((w) => (
                        <tr key={w.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100">
                            <td scope="row" style={{lineBreak: "anywhere"}}
                                className="px-6 py-4 text-xs text-indigo-900 cursor-pointer capitalize font-bold max-w-[100px] hover:text-indigo-900 dark:text-white">
                                {w.id}
                            </td>
                            <td scope="row" className="px-6 max-w-[48px]">
                                <b className={"capitalize"}>{w.owner_name}</b> | {w.owner_email}
                            </td>
                            <td scope="row" className="px-6 max-w-[48px]">
                                {(w.guest_users || []).map((u) => (
                                    <div key={u.user_id}>{u.user_email}</div>
                                ))}
                            </td>
                            <td scope="row" className="px-6 max-w-[48px]">
                                {w.token_balance}
                            </td>
                            <td className="px-6 py-4 flex gap-2">
                                <div className={"flex gap-2"}>
                                    <YMButton
                                        size="xs"
                                        text={"Gérer"}
                                        onClick={() => navigate('/workspaces/' + w.id)}
                                    />
                                    <YMButton
                                        size="xs"
                                        text={"Se connecter"}
                                        onClick={() => handleSelectWorkspace(w.id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))
                }
            />
        </div>}

    </div>

}