import React, { useState } from "react";
import { POS, POS_names } from "../utils";
import {
  YMButton,
  YMCard,
  YMInput,
  YMLabel,
  YMSelect,
  YMToggle,
} from "../../../ym-components";
import { TbCubeUnfolded } from "react-icons/tb";
import { HiFilter, HiPlus, HiTrash } from "react-icons/hi";
import { LexiquePatterns, NgrammesPatterns } from "./State";
import { Alert } from "flowbite-react";

export default function ProjectPatternsFilter({
  onSearch,
}: {
  onSearch: (
    patterns: POS[][],
    minMaxOccurrence: [number, number],
    term: string,
    enabledParsing: boolean
  ) => void;
}) {
  const [minMaxOccurrenceInput, setMinMaxOccurrenceInput] = useState([
    "1",
    "10000",
  ] as [string, string]);
  const [minMaxOccurrence, setMinMaxOccurrence] = useState([1, 10000] as [
    number,
    number
  ]);
  const [term, setTerm] = useState("");
  const [patterns, setPatterns] = useState([
    ["ADJ"],
    ["VERB"],
    ["NOUN"],
  ] as POS[][]);
  const [enabledParsing, setEnabledParsing] = useState(false);

  const search_lexicons = () => {
    setPatterns(LexiquePatterns);
    setEnabledParsing(false);
    onSearch(LexiquePatterns, minMaxOccurrence, term, false);
  };

  const search_ngram = () => {
    setPatterns(NgrammesPatterns);
    setEnabledParsing(false);
    onSearch(NgrammesPatterns, minMaxOccurrence, term, false);
  };

  const delete_index = (arr: any[], i: number) => {
    const copy = [...arr];
    copy.splice(i, 1);
    return copy;
  };

  const handleChangePattern = (
    patternIndex: number,
    pieceIndex: number,
    newValue: POS
  ) => {
    const newPatterns = [...patterns];
    newPatterns[patternIndex][pieceIndex] = newValue;
    setPatterns(newPatterns);
  };

  const handleAddPatternPiece = (patternIndex: number) => {
    const newPatterns = [...patterns];
    newPatterns[patternIndex] = [...patterns[patternIndex], POS.ADJ];
    setPatterns(newPatterns);
  };

  const handleAddPattern = () => {
    setPatterns([...patterns, [POS.ADJ]]);
  };
  const handleDeletePattern = (patternIndex: number) => {
    const newPatterns = delete_index(patterns, patternIndex);
    setPatterns(newPatterns);
  };

  const handleChangeMinMaxOccurrence = (min: string, max: string) => {
    setMinMaxOccurrenceInput([min, max]);
    const minInt = parseInt(min as string) || 0;
    const maxInt = parseInt(max as string) || 0;
    setMinMaxOccurrence([minInt, maxInt]);
  };

  return (
    <YMCard>
      <div className={"flex justify-between pr-20"}>
        <div className={""}>
          <h2 className={"text-indigo-800 mb-1 text-2xl font-bold"}>
            <TbCubeUnfolded className={"inline h-6 w-6"} /> Recherche de mots,
            expressions, motifs{" "}
          </h2>
          <p className={"text-indigo-900"}>
            Explorez le lexique structurant de votre projet par catégorie de
            mots (nom, verbes, adjectifs), ou par combinaison afin de rechercher
            des n-grammes (groupes de 2 mots ou plus) significatifs. Ils vous
            éclaireront sur les orientations sémantiques de votre corpus.
          </p>
        </div>
      </div>
      <div className={"flex flex-col mt-5"}>
        <div className={"mb-10 w-96"}>
          <YMLabel text="Fréquemment utilisé" />
          <div className={"flex gap-2"}>
            <YMButton
              text="Lexique"
              size={"xs"}
              icon={<HiFilter className="h-4 w-4 mr-2" />}
              onClick={search_lexicons}
            />
            <YMButton
              text="N-grammes"
              size={"xs"}
              icon={<HiFilter className="h-4 w-4 mr-2" />}
              onClick={search_ngram}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div>
            <YMLabel text="Expression" />
            <YMInput label={"Terme"} value={term} onChange={setTerm} />
          </div>
          <div className="">
            <YMLabel text="Motifs" />
            <div className="flex flex-col">
              {patterns.map((pattern, patternIndex) => (
                <div className={"flex-initial"} key={patternIndex}>
                  <div className={"mt-2 flex items-center "}>
                    {pattern.map((patternPiece, pieceIndex) => (
                      <YMSelect
                        label={"Motif"}
                        key={pieceIndex}
                        value={patternPiece}
                        options={POS_names}
                        onChange={(newValue) =>
                          handleChangePattern(
                            patternIndex,
                            pieceIndex,
                            newValue as POS
                          )
                        }
                      />
                    ))}
                    <div
                      className={"cursor-pointer ml-3"}
                      onClick={() => handleAddPatternPiece(patternIndex)}
                    >
                      <HiPlus />
                    </div>
                    <div
                      className={"cursor-pointer ml-3"}
                      onClick={() => handleDeletePattern(patternIndex)}
                    >
                      <HiTrash />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={"mt-2"}>
              <span
                className={"cursor-pointer underline text-indigo-600"}
                onClick={handleAddPattern}
              >
                {" "}
                ajouter un motif{" "}
              </span>
            </div>
          </div>
          <div className={"w-96 flex flex-col gap-2"}>
            <YMLabel text="Occurrences" />
            <YMInput
              label={"Nombre d'occurrence min"}
              value={minMaxOccurrenceInput[0]}
              onChange={(x) =>
                handleChangeMinMaxOccurrence(x, minMaxOccurrenceInput[1])
              }
            />
            <YMInput
              label={"Nombre d'occurrence max"}
              value={minMaxOccurrenceInput[1]}
              onChange={(x) =>
                handleChangeMinMaxOccurrence(minMaxOccurrenceInput[0], x)
              }
            />
          </div>
          <div className={"w-96 flex flex-col gap-2 pl-10"}>
            <YMToggle
              value={enabledParsing}
              onChange={setEnabledParsing}
              label={"Recherche avancée"}
            />
            {enabledParsing && (
              <Alert color="info" rounded>
                <p>
                  La recherche avancée permet de rechercher une variété de
                  motifs plus large et d'obtenir davantage de résultats.
                  Cependant elle peut être plus lente.
                </p>
              </Alert>
            )}
          </div>
        </div>
      </div>
      <div>
        <YMButton
          text="Rechercher"
          onClick={() => {
            onSearch(patterns, minMaxOccurrence, term, enabledParsing);
          }}
        />
      </div>
    </YMCard>
  );
}
