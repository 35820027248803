import React, { useEffect, useState } from "react";
import { useApi } from "../../api";
import { JobMonitoringView } from "../../app_client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Badge, Spinner } from "flowbite-react";
import { YMTable } from "../../ym-components";

export default function ProjectNLPHistory(props: any) {
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState(" " as string);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { api, isAuthenticated } = useApi();
  const [jobs, setJobs] = useState(
    "loading" as
      | Array<JobMonitoringView & { duration: string | null }>
      | "loading"
  );

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const formattedTime = `${minutes}:${seconds - minutes * 60}`;
    return formattedTime as string;
  }

  useEffect(() => {
    if (searchParams.get("name")) {
      setProjectName(searchParams.get("name") as string);
    }
  }, [searchParams]);

  useEffect(() => {
    if (api && isAuthenticated && projectId) {
      api.get_project_job(projectId).then((data: JobMonitoringView[]) =>
        setJobs(
          data.map((j) => ({
            ...j,
            duration: j.run_duration ? formatTime(j.run_duration) : null,
          }))
        )
      );
    }
  }, [api, isAuthenticated, projectId]);

  return (
    <>
      <div>
        {jobs === "loading" ? (
          <div className="w-100 mt-10 text-center">
            <Spinner
              color="gray"
              size="xl"
              aria-label="Default status example"
            />
            <div className={"mt-4 text-gray-400"}> Fetching History</div>
          </div>
        ) : jobs.length === 0 ? (
          <div className="w-100 mt-10 text-center"> x </div>
        ) : (
          <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
            <YMTable
              header={
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tâche
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Duration
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only"> x </span>
                  </th>
                </tr>
              }
              rows={jobs.map((j) => (
                <tr
                  key={j.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                >
                  <td
                    scope="row"
                    className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
                  >
                    <a href={"/events/" + j.id} target="_blank" rel="noreferrer">
                      {" "}
                      {j.name}{" "}
                    </a>
                  </td>
                  <td scope="row">
                    <div className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white">
                      {j.duration}
                    </div>
                  </td>
                  <td
                    scope="row"
                    className="px-6 py-4 text-gray-900 capitalize hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
                  >
                    {j.status === "COMPLETED" && (
                      <Badge className={"w-24 text-xs"} color={"green"}>
                        {" "}
                        {j.status}{" "}
                      </Badge>
                    )}
                    {[
                      "WAITING",
                      "MODEL_LOADING",
                      "PROCESSING_ON_LAMBDA",
                      "PROCESSING_LOCALLY",
                      "LAMBDA_STARTING",
                    ].indexOf(j.status) > -1 && (
                      <Badge className={"w-24 text-xs"} color={"orange"}>
                        {" "}
                        {j.status}{" "}
                      </Badge>
                    )}
                    {["FAILED", "TIMEOUT"].indexOf(j.status) > -1 && (
                      <Badge className={"w-24 text-xs"} color={"red"}>
                        {" "}
                        {j.status}{" "}
                      </Badge>
                    )}
                  </td>
                </tr>
              ))}
            />
          </div>
        )}
      </div>
    </>
  );
}
