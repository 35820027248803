import React from "react";
import {
  FewShotLearningComponentDispatch,
  FewShotLearningComponentState,
} from "../Reducer";
import { TagType } from "../../../../app_client";
import { ModalNewClass } from "../../VerbatimToken";

export const ModalNewClassFewShotLearning = ({
  state,
  dispatch,
}: {
  state: FewShotLearningComponentState;
  dispatch: FewShotLearningComponentDispatch;
}) => {
  return (
    <>
      {state.modalNewClass && (
        <ModalNewClass
          display={state.modalNewClass}
          dimension_type={TagType.TEXT_CLASSIFICATION}
          dimension={state.selectedDimension || null}
          onAddClass={(classname, allOccurrences) => {
            dispatch({ type: "CLASS_ADDED", payload: classname });
            // @ts-ignore
            setTimeout(() => document.activeElement.blur(), 0); // focus on the card to re-enable keyboard shortcuts
          }}
          onAddDimension={(dimension, classname, allOccurrences) => {}}
          onClose={() => {
            dispatch({ type: "MODAL_NEW_CLASS_CLOSED" });
            // @ts-ignore
            document.activeElement.blur(); // focus on the card to re-enable keyboard shortcuts
          }}
        />
      )}
    </>
  );
};