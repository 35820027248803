/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KernelEndpointService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Events
     * @param aggregateId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getEventsKernelEventsAggregateIdGet(
        aggregateId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kernel/events/{aggregate_id}/',
            path: {
                'aggregate_id': aggregateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Last Events
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLastEventsKernelEventsGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kernel/events/',
        });
    }

}
