import { ProjectApi, useProject, useProjectApi } from "../State";
import React, { useEffect } from "react";
import { useApi } from "../../../api";
import { YMCard } from "../../../ym-components";
import { useProjectFewShotLearningModel } from "./Model";
import { ModalNewClassFewShotLearning } from "./components/ModalNewClass";
import { useFewShotTaggingApi } from "./Api";
import { SelectDimension } from "./components/SelectDimension";
import { CardHeader } from "./components/CardHeader";
import { KeyboardShortcut } from "./components/KeyboardShortcut";
import { InfoMessage, message } from "./components/InfoMessage";
import { Verbatims } from "./components/Verbatims";
import { NewFewShotLearningModel } from "./components/ModalNewModel";
import {
  TrainNewModelButton,
  UpdateDimensionModelButton,
} from "./components/ActionButtons";
import { FewShotLearningModelInfo } from "../components/FewShotLearningModelInfo";

export default function ProjectFewShotLearning(props: any) {
  const { _project, _ProjectAnalysisStats, projectId } = useProject();
  const { api } = useApi();
  const projectApi = useProjectApi() as ProjectApi;

  const { state, dispatch, actions } = useProjectFewShotLearningModel(
    useFewShotTaggingApi(api, projectApi)
  );

  useEffect(() => {
    actions.projectSet(
      projectId,
      _project.data.dimensions,
      _ProjectAnalysisStats.data?.verbatim_count_number
    );
  }, [projectId, _project.data, _ProjectAnalysisStats.data]);

  useEffect(() => {
    actions.updateDimensionAnalysisStats(
      _ProjectAnalysisStats?.data?.dimensionAnalysisPercentage
    );
  }, [_ProjectAnalysisStats?.data?.dimensionAnalysisPercentage]);

  return (
    <>
      <ModalNewClassFewShotLearning {...{ state, dispatch }} />
      <NewFewShotLearningModel {...{ actions, state, dispatch }} />
      <YMCard>
        <CardHeader />
        <SelectDimension
          state={state}
          onSelect={(d) => {
            actions.selectDimension(
              d,
              _ProjectAnalysisStats?.data?.dimensionAnalysisPercentage
            );
          }}
        />
        {state.selectedDimensionName != "" && (
          <div className={"flex flex-row mt-5 justify-between"}>
            <KeyboardShortcut state={state} actions={actions} />
            <div className={"flex flex-col gap-1"}>
              {state.selectedDimension && state.selectedDimension.model_id && (
                <FewShotLearningModelInfo
                  model_id={state.selectedDimension.model_id}
                />
              )}
              <TrainNewModelButton {...{ state, dispatch }} />
              <UpdateDimensionModelButton {...{ state, actions }} />
            </div>
          </div>
        )}
      </YMCard>
      <div>
        {message(state) ? (
          <InfoMessage message={message(state)} />
        ) : (
          <Verbatims state={state} actions={actions} />
        )}
      </div>
    </>
  );
}
