/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProjectDimensionStatus {
    NEW = 'new',
    WAITING = 'WAITING',
    PROCESSING = 'PROCESSING',
    TAGGED = 'TAGGED',
}
