/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Permissions } from '../models/Permissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserInfoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Me Info
     * @returns Permissions Successful Response
     * @throws ApiError
     */
    public getMeInfoMePermissionsGet(): CancelablePromise<Array<Permissions>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/me/permissions',
        });
    }

}
