import { useAuthenticatedClient } from "../../AuthUserContext";
import { useQuery } from "@tanstack/react-query";
import {
  AppCLient,
  DimensionProcessInfo,
  DocumentChunkInfo,
} from "../../app_client";
import {
  YMButtonLight,
  YMInput,
  YMLoader,
  YMSelect,
  YMTable,
  YMTableTh,
} from "../../ym-components";
import React, { useCallback } from "react";
import { Badge } from "flowbite-react";

const useDocumentChunkQuery = (project_id: string) => {
  const client = useAuthenticatedClient() as AppCLient;
  const query = useQuery({
    queryKey: ["document-chunk", project_id],
    queryFn: () => {
      return client.project.getDocumentChunksProjectDocumentChunksProjectIdGet(
        project_id
      );
    },
    initialData: [],
    enabled: !!client,
    staleTime: 0,
    refetchInterval: 5000,
  });
  return query;
};

export default function DocumentChunkList(props: any) {
  const client = useAuthenticatedClient() as AppCLient;
  const [projectIdFilter, setProjectIdFilter] = React.useState<string>("");
  const [chunkStatus, setChunkStatus] = React.useState<string>("");
  const [dimensionStatus, setDimensionStatus] = React.useState<string>("");
  const documentChunkQuery = useDocumentChunkQuery(projectIdFilter);

  const filtered = useCallback(
    (data: DocumentChunkInfo[]) => {
      let filtered = data;
      if (projectIdFilter) {
        filtered = filtered.filter(
          (chunk) => chunk.project_id === projectIdFilter
        );
      }
      if (chunkStatus) {
        filtered = filtered.filter(
          (chunk) => chunk.parsing_status === chunkStatus
        );
      }
      if (dimensionStatus) {
        filtered = filtered.filter(
          (chunk) =>
            chunk.dimension_info.map((d) => d.status).indexOf(dimensionStatus) >
            -1
        );
      }
      return filtered;
    },
    [projectIdFilter, chunkStatus, dimensionStatus]
  );

  const handleRelaunchParsing = async (chunk: DocumentChunkInfo) => {
    await client.project.relaunchDocumentChunkParsingProjectProjectIdRelaunchDocumentChunkParsingPost(
      { project_id: chunk.project_id, chunk_id: chunk.id }
    );
    await documentChunkQuery.refetch();
  };

  const handleRelaunchDimension = async (chunk: DocumentChunkInfo, dimension: DimensionProcessInfo) => {
    await client.project.relaunchDimensionProcessProjectProjectIdRelaunchDimensionProcessPost({
        project_id: chunk.project_id,
        process_id: dimension.process_id,
    })
    await documentChunkQuery.refetch();
  }

  return (
    <>
      <div className={"flex my-10 space-x-4"}>
        <div className={"w-96"}>
          <YMInput
            value={projectIdFilter}
            label={"Project ID"}
            onChange={setProjectIdFilter}
          />
        </div>
        <div className={"w-96"}>
          <YMSelect
            label={"Parsing Status"}
            value={chunkStatus}
            onChange={setChunkStatus}
            options={["", "DONE", "EXPECTED", "PENDING", "FAILED"].map((v) => ({
              id: v,
              name: v,
            }))}
          />
        </div>
        <div className={"w-96"}>
          <YMSelect
            label={"Dimension Status"}
            value={dimensionStatus}
            onChange={setDimensionStatus}
            options={["", "DONE", "EXPECTED", "PENDING", "FAILED"].map((v) => ({
              id: v,
              name: v,
            }))}
          />
        </div>
      </div>
      <YMLoader
        message={"Chargement des chunks"}
        loading={documentChunkQuery.isLoading}
      >
        <YMTable
          header={
            <tr>
              <th scope="col" className="px-6 py-3">
                Project
              </th>
              <th scope="col" className="px-6 py-3">
                Job Parsing
              </th>
              <th scope="col" className="px-6 py-3">
                Job Parsing Status
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Dimension
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only"> x </span>
              </th>
            </tr>
          }
          rows={filtered(documentChunkQuery.data).map((chunk) => (
            <DocumentChunkRow
              key={chunk.id}
              chunk={chunk}
              onRelauchDimension={(dimension) => handleRelaunchDimension(chunk, dimension)}
              onRelaunchParsing={() => handleRelaunchParsing(chunk)}
            />
          ))}
        />
      </YMLoader>
    </>
  );
}

const DocumentChunkRow: React.FC<{
  chunk: DocumentChunkInfo;
  onRelaunchParsing: () => void;
  onRelauchDimension: (dimension: DimensionProcessInfo) => void;
}> = ({ chunk, onRelaunchParsing, onRelauchDimension }) => {
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100">
      <YMTableTh>
        <a href={`/project/${chunk.project_id}`} target="_blank" rel="noreferrer">
          {" "}
          {chunk.project_name}{" "}
        </a>{" "}
        <br />
        <span className={"text-xs text-indigo-800 font-normal"}>
          document_name : {chunk.document_name}
        </span>{" "}
        <span className={"text-xs text-indigo-800 font-normal"}>
          #{chunk.chunk_index}
        </span>{" "}
        <br />
        <span className={"text-xs text-indigo-800 font-normal"}>
          document_id : {chunk.document_id}
        </span>
      </YMTableTh>
      <YMTableTh>
        <a href={`/events/${chunk.job_id}`} target="_blank" rel="noreferrer">
          {" "}
          {chunk.job_id}{" "}
        </a>{" "}
        <br />
        <span className={"text-xs text-indigo-800 font-normal"}>
          started {ageOfInSeconds(chunk.job_created_at)} seconds ago
        </span>
      </YMTableTh>
      <YMTableTh>
        <div className={"flex"}>
          {" "}
          {chunk.job_status && <StatusBadge status={chunk.job_status} />}{" "}
        </div>
      </YMTableTh>
      <YMTableTh>
        <div className={"flex"}>
          <StatusBadge status={chunk.parsing_status} />
        </div>
      </YMTableTh>
      <YMTableTh>
        {chunk.dimension_info.map((dimension, index) => (
          <div key={index} className={"flex mt-1"}>
            <DimensionBadge dimension={dimension} />{" "}
            {dimension.status !== "DONE" && (
              <a
                className="font-normal
            "
                onClick={() => onRelauchDimension(dimension)}
              >
                relancer
              </a>
            )}
          </div>
        ))}
      </YMTableTh>
      <YMTableTh>
        <YMButtonLight
          size="xs"
          text={"Relancer le parsing"}
          onClick={onRelaunchParsing}
        />
      </YMTableTh>
    </tr>
  );
};

const ageOfInSeconds = (date: string | undefined) => {
  if (!date) {
    return "unknown";
  }
  const now = new Date();
  const then = new Date(date);
  const diff = now.getTime() - then.getTime();
  return Math.round(diff / 1000);
};

const ColorMap = {
  DONE: "success",
  EXPECTED: "indigo",
  PENDING: "warning",
  WAITING: "warning",
  PROCESSING_LOCALLY: "warning",
  PROCESSING_ON_LAMBDA: "warning",
  COMPLETED: "success",
  FAILED: "failure",
  TIMEOUT: "failure",
};

const DimensionBadge = ({ dimension }: { dimension: DimensionProcessInfo }) => {
  // @ts-ignore
  const color = ColorMap[dimension.status] || "gray";
  return <Badge color={color}><a href={"/events/"+dimension.job_id} target={"_blank"} rel="noreferrer">{dimension.dimension}:{dimension.status}</a></Badge>;
};

const StatusBadge = ({ status }: { status: string }) => {
  // @ts-ignore
  const color = ColorMap[status] || "gray";

  return <Badge color={color}>{status}</Badge>;
};
