import React, { useState } from "react";
import { YMButton, YMCard, YMInput, YMLabel } from "../../../ym-components";
import { TbCubeUnfolded } from "react-icons/tb";

export default function ProjectSemanticFilter({
  onSearch,
}: {
  onSearch: (term: string) => void;
}) {
  const [term, setTerm] = useState("");

  return (
    <YMCard>
      <div className={"flex justify-between pr-20"}>
        <div className={""}>
          <h2 className={"text-indigo-800 mb-1 text-2xl font-bold"}>
            <TbCubeUnfolded className={"inline h-6 w-6"} /> Recherche sémantique
          </h2>
          <p className={"text-indigo-900"}>
            Explorez le lexique structurant de votre projet ... à compléter ...
          </p>
        </div>
      </div>
      <div className={"flex flex-col mt-5"}>
        <div className="flex gap-4">
          <div>
            <YMLabel text="Expression" />
            <YMInput label={"Terme"} value={term} onChange={setTerm} />
          </div>
        </div>
      </div>
      <div className={"mt-5"}>
        <YMButton
          text="Rechercher"
          onClick={() => {
            onSearch(term);
          }}
        />
      </div>
    </YMCard>
  );
}
