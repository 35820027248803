/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFewShotTaggingModelFromProjectTags } from '../models/CreateFewShotTaggingModelFromProjectTags';
import type { FewShotTaggingModelId } from '../models/FewShotTaggingModelId';
import type { FewShotTaggingModelView } from '../models/FewShotTaggingModelView';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FewShotTaggingModelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Few Shot Tagging Model From Project Tags
     * @param requestBody
     * @returns FewShotTaggingModelId Successful Response
     * @throws ApiError
     */
    public createFewShotTaggingModelFromProjectTagsFewshotTaggingModelProjectIdPost(
        requestBody: CreateFewShotTaggingModelFromProjectTags,
    ): CancelablePromise<FewShotTaggingModelId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/fewshot_tagging_model/{project_id}',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Few Shot Tagging
     * @param modelId
     * @returns FewShotTaggingModelView Successful Response
     * @throws ApiError
     */
    public getFewShotTaggingFewshotTaggingModelModelIdGet(
        modelId: string,
    ): CancelablePromise<FewShotTaggingModelView> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fewshot_tagging_model/{model_id}/',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Trigger Train Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public triggerTrainModelFewshotTaggingModelModelIdTrainPost(
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/fewshot_tagging_model/{model_id}/train',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Few Shot Tagging Training Data Plot Embedding
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFewShotTaggingTrainingDataPlotEmbeddingFewshotTaggingModelModelIdTrainingDataPlotEmbeddingHtmlGet(
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fewshot_tagging_model/{model_id}/training_data/plot_embedding.html',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Few Shot Tagging Training Data Csv
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFewShotTaggingTrainingDataCsvFewshotTaggingModelModelIdTrainingDataCsvGet(
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fewshot_tagging_model/{model_id}/training_data.csv',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Few Shot Tagging Embedded Training Data Json
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFewShotTaggingEmbeddedTrainingDataJsonFewshotTaggingModelModelIdEmbeddedTrainingDataJsonGet(
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fewshot_tagging_model/{model_id}/embedded_training_data.json',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
