import { useAuth0 } from "@auth0/auth0-react";
import { YMButton } from "../ym-components";
import { AiFillHeart } from "react-icons/ai";
import { Alert, Badge } from "flowbite-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <YMButton
      text={"Se connecter"}
      onClick={() =>
        loginWithRedirect({
          screen_hint: "login",
          appState: { returnTo: window.location.pathname },
        })
      }
    />
  );
};

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <YMButton
      text={"S'inscrire"}
      onClick={() =>
        loginWithRedirect({
          screen_hint: "signup",
          appState: { returnTo: window.location.pathname },
        })
      }
    />
  );
};

const CancelSubscriptionButton = () => {
  const { logout } = useAuth0();
  return (
    <YMButton text={"Annuler mon inscription"} onClick={() => logout({})} />
  );
};

export const LoginPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const error_description = urlParams.get("error_description");

  return (
    <div>
      <div className="h-40 fixed top-10 left-0 right-0 z-10 px-10 py-5 flex gap-4">
        <div className="text-6xl font-bold text-indigo-900"> Youmean</div>
        <Badge className={"mt-5"} color={"red"}>
          {" "}
          VERSION BETA{" "}
        </Badge>
      </div>
      <div className="w-full h-screen flex flex-row bg-gray-100">
        <div className="w-1/2 relative overflow-hidden">
          <div
            className="z-0 rounded-full bg-white absolute"
            style={{ height: 1500, width: 1500, top: -350, right: 100 }}
          />
          <div className="h-screen z-100 asbolute flex items-center justify-end top-0 ml-10">
            <img className="w-100 z-20 -mt-20" src="/login-illustration.svg" />
          </div>
        </div>

        <div className="w-1/2 flex flex-col justify-center items-center">
          <div className={"w-100 flex flex-col gap-2"}>
            <LoginButton />
            {!error_description && <SignupButton />}
            {error_description == "USER_EMAIL_NOT_VERIFIED" && (
              <CancelSubscriptionButton />
            )}
          </div>
          {error_description == "USER_DONT_CONSENT" && (
            <Alert className="mt-10" color="failure">
              Vous devez accepter les conditions d'utilisation pour utiliser
              l'application
            </Alert>
          )}
          {error_description == "USER_EMAIL_NOT_VERIFIED" && (
            <Alert className="mt-10" color="failure">
              Vous devez confirmer votre adresse mail en cliquant sur le lien
              que nous vous avons envoyé par mail
            </Alert>
          )}
          <div className="mt-10">
            Propulsé avec{" "}
            <AiFillHeart className={"inline -mt-1 text-red-500"} /> par l'équipe{" "}
            <a className="text-indigo-500 cursor-pointer hover:text-indigo-700">
              {" "}
              SenseFab{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
