import {AppCLient, InfraService, InfraServiceStatus,} from "../../app_client";
import {useContext, useEffect, useState} from "react";
import {AuthUserContext} from "../../AuthUserContext";

export interface InfrastructureApi {
  getStatus(service: InfraService): Promise<InfraServiceStatus>;
  restart(service: InfraService): Promise<void>;
}

export const useInfrastructureApi = (): InfrastructureApi => {
  const [api, setApi] = useState<InfrastructureApi | null>(null);
  const { token } = useContext(AuthUserContext) as {
    token: string;
  };

  useEffect(() => {
    if (token) {
      let client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setApi({
        getStatus: (service: InfraService): Promise<InfraServiceStatus> => {
          return client.infraApi.getStatusInfraStatsServicePost(service)
        },
        restart: (service: InfraService): Promise<void> => {
            return client.infraApi.restartsInfraRestartsServicePost(service)
        }
      })
    }
  }, [token]);

  return api as InfrastructureApi;
};
