import { YMLabel } from "../../../../ym-components";
import React, { useEffect } from "react";
import { FewShotLearningComponentModel } from "../Model";
import { Kbd } from "flowbite-react";

export const KeyboardShortcut = ({
  state,
  actions,
}: Pick<FewShotLearningComponentModel, "actions" | "state">) => {
  useEffect(() => {
    function handleKeyPress(e: any) {
      actions.onKeyPress(e.key);
    }
    document.addEventListener("keypress", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });

  return (
    <div className={"w-2/3"}>
      <YMLabel text="raccourcis clavier" />
      <div className={"flex flex-row flex-wrap gap-4"}>
        {state.selectedDimensionClasses.map((c: string, i: number) => (
          <KbTouch keyTouch={"azertyuiopqsdfghjklmwx"[i]} name={c} />
        ))}
        <KbTouch keyTouch={"+"} name={"Nouvelle classe"} />
        <KbTouch keyTouch={"x"} name={"Outlier (pas de classe)"} />
        <KbTouch keyTouch={"Enter"} name={"Tagguer"} />
      </div>
    </div>
  );
};
const KbTouch = ({ keyTouch, name }: { keyTouch: string; name: string }) => {
  return (
    <div>
      <Kbd>{keyTouch}</Kbd>{" "}
      <span className={"text-indigo-900 text-xs"}>{name}</span>
    </div>
  );
};
