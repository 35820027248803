/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum POS {
    ADJ = 'ADJ',
    ADP = 'ADP',
    ADV = 'ADV',
    AUX = 'AUX',
    CONJ = 'CONJ',
    CCONJ = 'CCONJ',
    DET = 'DET',
    INTJ = 'INTJ',
    NOUN = 'NOUN',
    NUM = 'NUM',
    PART = 'PART',
    PRON = 'PRON',
    PROPN = 'PROPN',
    PUNCT = 'PUNCT',
    SCONJ = 'SCONJ',
    SYM = 'SYM',
    VERB = 'VERB',
    X = 'X',
}
