import { YMIframe } from "../project/components/components";
import React from "react";

export default function RateLimitInfo() {
  const remainingTokens = (model: string) => {
    return `${process.env.REACT_APP_APP_API_HOST}/rate_limit_info/${model}/plot_remaining_tokens.html`;
  };
  const remainingRequests = (model: string) => {
    return `${process.env.REACT_APP_APP_API_HOST}/rate_limit_info/${model}/plot_remaining_requests.html`;
  };

  return (
    <div className={"p-4"}>
      <h1 className={"text-indigo-800 font-bold text-3xl"}>
        OpenAI Api Limits
      </h1>
      <p className={"pt-4"}>
        {" "}
        L'utilisation de l'API d'open AI est bridée en nombre de token par
        minutes et par jours{" "}
      </p>
      <h2 className={"py-2 text-indigo-800 font-bold text-md"}>
        Nombre de tokens restants pour le modèle text-embedding-3-large
      </h2>
      <YMIframe
        src={remainingTokens("text-embedding-3-large")}
        style={{ width: "100%", height: "700px" }}
      />
      <h2 className={"py-2 text-indigo-800 font-bold text-md"}>
        Nombre de requêtes restants pour le modèle text-embedding-3-large
      </h2>
      <YMIframe
        src={remainingRequests("text-embedding-3-large")}
        style={{ width: "100%", height: "700px" }}
      />
      <h1 className={"text-indigo-800 font-bold text-3xl"}>
        MistralAI Api Limits
      </h1>
      <p className={"pt-4"}>
        {" "}
        L'utilisation de mistral AI est bridée en nombre de token par minutes et
        par mois{" "}
      </p>
      <h2 className={"py-2 text-indigo-800 font-bold text-md"}>
        Nombre de tokens restants pour le modèle mistral-embed
      </h2>
      <YMIframe
        src={remainingTokens("mistral-embed")}
        style={{ width: "100%", height: "700px" }}
      />
    </div>
  );
}