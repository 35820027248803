import React, { useState } from "react";
import TagsTable from "./TagsTable";
import { YMButton, YMCard, YMLabel, YMSelect } from "../../../ym-components";
import { TbCubeUnfolded } from "react-icons/tb";
import { useProject } from "../State";
import { TagType } from "../../../app_client";

function ProjectTagsFilter({
  onSearch,
}: {
  onSearch: (dimension: string) => void;
}) {
  const { _project, projectId } = useProject();
  const [dimension, setDimension] = useState("");

  return (
    <YMCard>
      <div className={"flex justify-between pr-20"}>
        <div className={""}>
          <h2 className={"text-indigo-800 mb-1 text-2xl font-bold"}>
            <TbCubeUnfolded className={"inline h-6 w-6"} /> Recherche par tags
          </h2>
          <p className={"text-indigo-900"}>
            Explorez les tags de votre projet ... à compléter ...
          </p>
        </div>
      </div>
      <div className={"flex flex-col mt-5"}>
        <div className="flex gap-4 w-42">
          <div className={"w-80"}>
            <YMLabel text="Dimension" />
            <YMSelect
              label={"Dimension"}
              value={dimension}
              options={[
                { id: "", name: "-- Sélectionnez une dimension --" },
              ].concat(
                _project.data.dimensions
                  .filter((d) => d.tag_type == TagType.TOKEN_CLASSIFICATION)
                  .map((d) => ({
                    id: d.name,
                    name: d.name,
                  }))
              )}
              onChange={setDimension}
            />
          </div>
        </div>
      </div>
      <div className={"mt-5"}>
        <YMButton
          text="Rechercher"
          onClick={() => {
            onSearch(dimension || "");
          }}
        />
      </div>
    </YMCard>
  );
}


export default function ProjectTagsSearch(props: any) {
  const [searchParams, setSearchParams] = useState({
    dimension: "",
  });
  return (
    <>
      <div className="relative overflow-x-auto">
        <ProjectTagsFilter
          onSearch={(dimension: string) => {
            setSearchParams({
              dimension,
            });
          }}
        />
        <TagsTable searchParams={searchParams} />
      </div>
    </>
  );
}
