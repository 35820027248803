/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ClassificationModelType {
    REGRESSION = 'regression',
    LLM = 'llm',
}
