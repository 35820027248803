import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import {
  YMButton,
  YMLoader,
  YMMultiSelect,
  YMTable,
  YMToggle,
} from "../../../ym-components";
import { useProjectApi } from "../State";

type SheetFileConfigurationModalProps = {
  file: File;
  show: boolean;
  onClose: () => void;
  onConfirm: (config: SheetFileConfiguration) => void;
};

export interface SheetFileConfiguration {
  data_column: number;
  ignore_header: boolean;
  split_cell: boolean;
}

export const SheetFileConfigurationModal: React.FC<
  SheetFileConfigurationModalProps
> = ({ file, show, onClose, onConfirm }) => {
  const projectApi = useProjectApi();
  const [previewData, setPreviewData] = useState<string[][] | null>(null);
  const [config, setConfig] = useState<SheetFileConfiguration>({
    data_column: 1,
    ignore_header: false,
    split_cell: true,
  });

  const [columnsNames, setColumnsNames] = useState<string[]>([]);
  const [displayedSelectedColumn, setDisplayedSelectedColumn] = useState<
    string[]
  >([]);

  const reset = () => {
    setPreviewData(null);
    setConfig({
      data_column: 1,
      ignore_header: false,
      split_cell: false,
    });
  };

  useEffect(() => {
    if (projectApi && file) {
      projectApi.previewDocument(file).then(data => {
        setPreviewData(data)
      });
    }
  }, [projectApi, file]);

  useEffect(() => {
    computeColumnsNames()
  }, [config.ignore_header, previewData])

  const setDataCol = (col: string) => {
    let num = config.ignore_header ? excelColToNum(col) : columnsNames.indexOf(col) + 1;
    setConfig({ ...config, data_column: num});
    setDisplayedSelectedColumn([col]);
  };

  const setIgnoreFirstRow = (ignore: boolean) => {
    setConfig({ ...config, ignore_header: ignore });
  };

  const computeColumnsNames = () => {
    if (previewData) {
      let columns = previewData[0].map((row, i) =>
        config.ignore_header ? numToExcelCol(i + 1) : row
      );
      setColumnsNames(columns);
      if (columns.length > 0) setDataCol(columns[0]);
    }
  }

  const setSplitCell = (keep: boolean) => {
    setConfig({ ...config, split_cell: keep });
  };

  // @ts-ignore
  return (
    <Modal size="5xl" show={show} onClose={onClose}>
      <Modal.Header>{file && file.name} </Modal.Header>
      <Modal.Body>
        <YMLoader
          message={"Prévualisation de " + (file && file.name)}
          loading={previewData === null}
        >
          {previewData && previewData.length > 0 && (
            <>
              <div className={"flex items-center gap-10"}>
                <div className={"mb-2"}>
                  <div className="flex items-center">
                    <div className="font-bold text-indigo-900 text-xs">
                      Colonne à importer
                    </div>
                  </div>
                  <div className={"w-48"}>
                    <YMMultiSelect
                      wordList={columnsNames}
                      values={displayedSelectedColumn}
                      onListChange={(l) => l.length > 0 && setDataCol(l[0])}
                      max={1}
                      hideDisableButton={true}
                    />
                  </div>
                </div>
                <YMToggle
                  label={"Ignorer l'en-tête"}
                  value={config.ignore_header}
                  onChange={setIgnoreFirstRow}
                />
                <YMToggle
                  label={"Conserver la structure des cellules"}
                  value={!config.split_cell}
                  onChange={(v) => setSplitCell(!v)}
                />
              </div>
              <div
                className="space-y-6 overflow-y-scroll mt-10"
                style={{ height: 400 }}
              >
                <YMTable
                  tableClassName={"table-auto"}
                  header={
                    <tr>
                      {previewData[0].map((row, i) => (
                        <th scope="col">
                          {" "}
                          {config.ignore_header
                            ? numToExcelCol(i + 1)
                            : row}{" "}
                        </th>
                      ))}
                    </tr>
                  }
                  rows={(config.ignore_header
                    ? previewData
                    : previewData.slice(1)
                  ).map((row, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                    >
                      {row.map((p) => (
                        <td scope="row"> {p} </td>
                      ))}
                    </tr>
                  ))}
                />
              </div>
            </>
          )}
        </YMLoader>
      </Modal.Body>
      <Modal.Footer>
        <YMButton
          onClick={() => {
            onConfirm(config);
            reset();
          }}
          text={"Valider"}
        />
      </Modal.Footer>
    </Modal>
  );
};

// Helpers

function numToExcelCol(num: number) {
  let colName = "";
  while (num > 0) {
    let remainder = (num - 1) % 26;
    colName = String.fromCharCode(65 + remainder) + colName;
    num = Math.floor((num - remainder) / 26);
  }
  return colName;
}

function excelColToNum(colName: string) {
  let num = 0;
  for (let i = 0; i < colName.length; i++) {
    num *= 26;
    num += colName.charCodeAt(i) - 64;
  }
  return num;
}
