import {
  AppCLient,
  FewShotTaggingModelView,
  NewWorkspaceFewShotTaggingModels,
} from "../../app_client";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../AuthUserContext";
import { downloadAsCsv } from "../../util";

export interface FewShotTaggingModelsApi {
  fetchModel(model_id: string): Promise<FewShotTaggingModelView>;
  fetchModels(): Promise<FewShotTaggingModelView[]>;
  getModel(
    model_id: string
  ): Promise<FewShotTaggingModelView & { trainingDataMap: string }>;
  downloadTrainingData(model: FewShotTaggingModelView): Promise<void>;
  createModel(
    data: Omit<NewWorkspaceFewShotTaggingModels, "workspace_id">
  ): Promise<void>;
  disableModel(model: FewShotTaggingModelView): Promise<void>;
  triggerTraining(model: FewShotTaggingModelView): Promise<void>;
}

export const useFewShotTaggingModelApi = (): FewShotTaggingModelsApi => {
  const [api, setApi] = useState<FewShotTaggingModelsApi | null>(null);
  const { token, workspaceId } = useContext(AuthUserContext) as {
    token: string;
    workspaceId: string;
  };

  useEffect(() => {
    if (token && workspaceId) {
      let client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setApi({
        fetchModel: (model_id: string) => {
          return client.fewShotTaggingModel.getFewShotTaggingFewshotTaggingModelModelIdGet(
              model_id
          )
        },
        fetchModels: () => {
          return client.workspace.getFewShotTaggingModelsWorkspaceWorkspaceIdModelsFewShotTaggingGet(
            workspaceId
          );
        },
        getModel: async (model_id: string) => {
            let model = await client.fewShotTaggingModel.getFewShotTaggingFewshotTaggingModelModelIdGet(
                model_id
            );
            return {
              ...model,
              trainingDataMap: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/training_data/plot_embedding.html`
            }
        },
        createModel: (data:Omit<NewWorkspaceFewShotTaggingModels, "workspace_id">) => {
          return client.workspace.createFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelPost({
            workspace_id: workspaceId,
            ...data
          })
        },
        downloadTrainingData: async (model: FewShotTaggingModelView) => {
          let data = await client.fewShotTaggingModel.getFewShotTaggingTrainingDataCsvFewshotTaggingModelModelIdTrainingDataCsvGet(model.model_id)
          downloadAsCsv(data, `${model.name}_training_data`)
        },
        disableModel: (model: FewShotTaggingModelView) => {
            return client.workspace.deleteFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelModelIdDelete(
                workspaceId, model.model_id
            )
        },
        triggerTraining: async (model: FewShotTaggingModelView) => {
          await client.fewShotTaggingModel.triggerTrainModelFewshotTaggingModelModelIdTrainPost(model.model_id)
        }
      });


    }
  }, [token, workspaceId]);

  return api as FewShotTaggingModelsApi;
};
