import {Toast} from "flowbite-react";
import React from "react";
import {HiCheck, HiDotsHorizontal, HiRefresh, HiX} from "react-icons/hi";
import {YMTinyButton} from "../../ym-components";
import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query";
import {useInfrastructureApi} from "./Api";
import {InfraService, InfraServiceStatus} from "../../app_client";
import {notificationInfo} from "../../toast-notification";


const useStatusOf = (service: InfraService) => {
    const infraApi = useInfrastructureApi()
    return useQuery({
        queryKey: ["infra_service_status", service],
        queryFn: async () => {
            if (!infraApi) {
                return "loading"
            }
            return infraApi.getStatus(service)
        },
        enabled: !!infraApi
    })
}

const useRestart = (service: InfraService, onSuccess: () => void) => {
    const infraApi = useInfrastructureApi()
    return useMutation({
        mutationFn: async () => {
            notificationInfo(`Redémarrage de ${service} en cours... cela peut prendre plusieurs minutes, merci de patienter et de ne pas fermer l'onglet.`)
            await infraApi.restart(service)
            // await 30 seconds to let the service restart
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true)
                }, 30000)
            })
        },
        onSuccess: () => {
            notificationInfo(`Redémarrage de ${service} effectué`)
            onSuccess()
        }
    })
}

export default function View(props: any) {

    const psql = useStatusOf(InfraService.PSQL)
    const redis = useStatusOf(InfraService.REDIS)
    const rabbitmq = useStatusOf(InfraService.RABBITMQ)

    const psqlRestart = useRestart(InfraService.PSQL, psql.refetch)
    const redisRestart = useRestart(InfraService.REDIS, redis.refetch)
    const rabbitmqRestart = useRestart(InfraService.RABBITMQ, rabbitmq.refetch)

    return (
        <div className="bg-gradient-to-r from-gray-100 to-indigo-100 h-screen">
            <div className="mx-auto w-100 max-w-screen-lg pt-10 text-left">
                <div className={"text-3xl font-bold text-indigo-900"}> Infrastructure Serveur</div>
                <div className="flex flex-col gap-4  mt-10">
                    <Toast>
                        <Status query={psql} mutate={psqlRestart}/>
                        <div className={"ml-3 flex flex-row items-center justify-between grow"}>
                            <div className="text-sm font-bold text-gray-900 mb-1">Base de données
                                PSQL
                            </div>
                            {!psqlRestart.isLoading && <div className={"grow-0 -mt-1"}><YMTinyButton color="white" text={"redémarrer"}
                                                                          onClick={() => psqlRestart.mutate()}/></div>}
                        </div>
                    </Toast>
                    <Toast>
                        <Status query={redis} mutate={redisRestart}/>
                        <div className={"ml-3 flex flex-row items-center justify-between grow"}>
                            <div className="text-sm font-bold text-gray-900 mb-1">Base de données Redis</div>
                            {!redisRestart.isLoading && <div className={"grow-0 -mt-1"}><YMTinyButton color="white" text={"redémarrer"}
                                                                          onClick={() => redisRestart.mutate()}/></div>}
                        </div>
                    </Toast>
                    <Toast>
                        <Status query={rabbitmq} mutate={rabbitmqRestart}/>
                        <div className={"ml-3 flex flex-row items-center justify-between grow"}>
                            <div className="text-sm font-bold text-gray-900 mb-1">Broker de tâches RabbitMQ</div>
                            {!rabbitmqRestart.isLoading && <div className={"grow-0 -mt-1"}>
                                <YMTinyButton
                                    color="white"
                                    text={"redémarrer"}
                                    onClick={() => rabbitmqRestart.mutate()}
                                /></div>}
                        </div>
                    </Toast>
                </div>
            </div>
        </div>
    )
}

const Status = ({query, mutate}: { query: UseQueryResult<InfraServiceStatus | "loading", unknown>, mutate: UseMutationResult<unknown, unknown, void, unknown> }) => {

    if (mutate.isLoading) {
        return <div
            className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500">
            <HiRefresh className="h-5 w-5"/>
        </div>
    }

    if (query.isLoading || query.data === "loading") {
        return <div
            className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-gray-100 text-gray-500">
            <HiDotsHorizontal className="h-5 w-5"/>
        </div>
    }

    if (query.data === InfraServiceStatus.UP) {
        return <div
            className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500">
            <HiCheck className="h-5 w-5"/>
        </div>
    }

    if (query.data === InfraServiceStatus.DOWN) {
        return <div
            className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500">
            <HiX className="h-5 w-5"/>
        </div>
    }

    return <></>
}

