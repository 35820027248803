/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum domains__job_management__values__JobStatus {
    WAITING = 'WAITING',
    MODEL_LOADING = 'MODEL_LOADING',
    PROCESSING_ON_LAMBDA = 'PROCESSING_ON_LAMBDA',
    PROCESSING_LOCALLY = 'PROCESSING_LOCALLY',
    LAMBDA_STARTING = 'LAMBDA_STARTING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    TIMEOUT = 'TIMEOUT',
}
