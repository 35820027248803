import {useWorkspaceApi} from "./Api";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {YMButton, YMLoader} from "../../ym-components";
import {AuthUserContext} from "../../AuthUserContext";

export default function JoinWorkspace() {

    const workspaceApi = useWorkspaceApi();
    const {setWorkspaceId} = useContext(AuthUserContext);

    const [workspaceName, setWorkspaceName] = useState<string>("");
    const [joinWorkspaceLoading, setJoinWorkspaceLoading] = useState<boolean>(false);

    const {workspaceToken} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (workspaceApi) {
           workspaceApi.getWorkspaceName(workspaceToken||"").then(setWorkspaceName)
        }
    }, [workspaceApi]);

    const handleJoinWorkspace = () => {
        if (workspaceApi) {
            setJoinWorkspaceLoading(true);
            workspaceApi.joinWorkspace(workspaceToken||"").then((id) => {
                setTimeout(() => {
                     setWorkspaceId(id)
                     navigate('/')
                     setJoinWorkspaceLoading(false);
                }, 5000)
            })
        }
    }

    return <div className="flex justify-center bg-gradient-to-r from-gray-100 to-indigo-100 h-screen -pt-20">

        <YMLoader message={"Chargement de l'invitation"} loading={workspaceName===""}>
            <div className={"w-lg text-center flex flex-col justify-center -mt-20"}>
                <h1 className={"text-indigo-900 text-2xl font-bold"}>Rejoindre un espace de travail</h1>
                <p>
                    Vous avez été invité à rejoindre un espace de travail Youmean. <br/>
                </p>
                <YMButton
                    disabled={joinWorkspaceLoading}
                    text={joinWorkspaceLoading ? 'Jonction en cours...' : `Rejoindre l'espace de travail de ${workspaceName}`}
                    onClick={handleJoinWorkspace}
                />
                <a onClick={()=>navigate('/')} className={"mt-5 cursor-pointer hover:text-indigo-900"}> refuser </a>
            </div>
        </YMLoader>

    </div>


}