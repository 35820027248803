import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SiderBarItem = (props: any) => {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  // return <Sidebar.Item
  //     onClick={() => navigate(props.path)}
  //     className={{'cursor-pointer justify-left': true, 'text-purple-800 font-bold': pathname === props.path}}
  //     icon={props.icon}
  // >
  //
  // </Sidebar.Item>
  const [active, setActive] = useState(props.defaultActive || false);
  useEffect(() => {
    if (props.path === undefined) return;
    const path = props.path.split("?")[0];
    setActive(pathname === path);
  }, [pathname]);

  return (
    <li onClick={() => props.path && navigate(props.path)}>
      <a
        href={props.href || "#"}
        className={
          (active
            ? "bg-gray-100 text-purple-800 font-bold "
            : "text-gray-600 font-medium ") +
          " flex items-center p-2  rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
        }
      >
        {props.icon && (
          <div
            className={`h-6 w-6 sidebar-icon ${
              active ? "text-purple-800" : "text-gray-600"
            }`}
          >
            {props.icon}
          </div>
        )}
        <span className="ml-3">{props.children}</span>
      </a>
    </li>
  );
};

export const SideBarItemCollaps = (props: any) => {
  let { pathname } = useLocation();
  const [active, setActive] = useState(props.defaultActive || false);
  return (
    <li>
      <button
        type="button"
        onClick={() => setActive(!active)}
        className={
          (active ? "text-purple-800 font-bold" : "text-gray-600 font-medium") +
          " flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
        }
        aria-controls="dropdown-example"
        data-collapse-toggle="dropdown-example"
      >
        {props.icon && (
          <div
            className={`h-6 w-6 sidebar-icon ${
              active ? "text-purple-800" : "text-gray-600"
            }`}
          >
            {props.icon}
          </div>
        )}
        <span
          className="flex-1 ml-3 text-left whitespace-nowrap"
        >
          {props.label}
        </span>
      </button>
      <ul
        id="dropdown-example"
        className={"pl-5 space-y-2 " + (active ? "" : "hidden")}
      >
        {props.children}
      </ul>
    </li>
  );
};
