import {Alert, Badge, Progress, Sidebar, Spinner, Tooltip,} from "flowbite-react";
import {SideBarItemCollaps, SiderBarItem,} from "../../app_client/components/SideBar";
import {HiClock, HiCubeTransparent, HiHome, HiOutlineDocumentText, HiTag,} from "react-icons/hi";
import React, {useContext, useEffect, useState} from "react";
import {Permissions, ProjectDimension, TagType} from "../../app_client";
import {BreadcrumbLink, YMTinyButton, YMTopBanner, YMWorkspaceInfo,} from "../../ym-components";
import {ProjectFilter, useProject} from "./State";
import {BiNetworkChart} from "react-icons/bi";
import {GiArmoredBoomerang, GiNotebook} from "react-icons/gi";
import {SiCodereview} from "react-icons/si";
import {TbCubeUnfolded, TbLayersIntersect} from "react-icons/tb";
import {IoIosSettings, IoMdAdd, IoMdRefresh} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {RiBookReadLine} from "react-icons/ri";
import {AuthUserContext} from "../../AuthUserContext";
import {ProjectSettings} from "./ProjectSettings";
import {BsDatabaseAdd} from "react-icons/bs";
import {ButtonProjectFilterModal, getProjectConditionsFromLocalStorage} from "./components/buttonProjectFilterModal";
import {CiFilter} from "react-icons/ci";
import {ProjectFilterContext} from "./ProjectFilterContext";

type ProjectPanelProps = {
  breadcrumb?: BreadcrumbLink[] | undefined;
  children: React.ReactNode | undefined;
};


export const ProjectPanel: React.FC<ProjectPanelProps> = ({
  breadcrumb,
  children,
}) => {
  const { projectId } = useProject();
  const [filterConditions, setFilterConditions] = React.useState(getProjectConditionsFromLocalStorage(projectId) as ProjectFilter);
  return (
   <ProjectFilterContext.Provider
       value={{conditions: filterConditions, setConditions: setFilterConditions}}>
     <ProjectPanelInner breadcrumb={breadcrumb}>{children}</ProjectPanelInner>
   </ProjectFilterContext.Provider>
  );
};

export const ProjectPanelInner: React.FC<ProjectPanelProps> = ({
  breadcrumb,
  children,
}) => {
  const navigate = useNavigate();
  const {permissions} = useContext(AuthUserContext);

  const { _project, _ProjectAnalysisStats, _resetDimension } = useProject();
  const filter = useContext(ProjectFilterContext);

  const [showProjectSettings, setShowProjectSettings] = useState(false);
  const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(false);
  const [shouldDisplayChildrenNode, setShouldDisplayChildrenNode] =
    useState(false);

  const [dimensionOnUpdate, setDimensionOnUpdate] = useState<string[]>([]);

  const isDeveloper = permissions?.includes(Permissions.DEVELOPER_FULL);

  const onDimensionUpdate = (dimension: ProjectDimension) => {
    // add dimension to the list of dimension being updated
    setDimensionOnUpdate([...dimensionOnUpdate, dimension.name]);
    // update dimension
    _resetDimension.mutate(dimension);
    // remove dimension from the list of dimension being updated after 30s
    setTimeout(() => {
      setDimensionOnUpdate(
        dimensionOnUpdate.filter((d) => d != dimension.name)
      );
    }, 30000);
  };

  useEffect(() => {
    setShouldDisplaySidebar(_ProjectAnalysisStats.data?.tokens_count != "?");
    setShouldDisplayChildrenNode(
      _ProjectAnalysisStats.data?.tokens_count != "?" &&
        _ProjectAnalysisStats.data?.tokens_count != "0"
    );
  }, [_ProjectAnalysisStats.data?.tokens_count]);

  return (
    <>
      <div className="h-full overflow-hidden">
        <YMTopBanner />
        {showProjectSettings && (
          <ProjectSettings
            show={showProjectSettings}
            onClose={async () => {
              await _project.refetch();
              setShowProjectSettings(false);
            }}
            project={_project.data}
          />
        )}
        <Sidebar
          className="fixed top-0 left-0 z-40 w-64 bg-gradient-to-b bg-white border border-r border-gray-200 h-screen transition-transform"
          aria-label="Sidebar with logo branding example"
        >
          <Sidebar.Items>
            <div
              className={
                "text-3xl text-indigo-800 font-bold font-sans pb-3 text-center"
              }
            >
              {" "}
              Youmean
            </div>
          </Sidebar.Items>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <SiderBarItem
                path="/"
                icon={<HiHome className={"h-6 w-6 text-indigo-800"} />}
              >
                Menu principal
              </SiderBarItem>
              <div className={"ml-2"}>
                <YMWorkspaceInfo />
              </div>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <div className={"ml-3 flex flex-col"}>
                <div className="mt-1  text-xl capitalize font-bold text-purple-900">
                  {_project.data?.name}
                </div>
                {!_ProjectAnalysisStats.isLoading && (
                  <div className={"mt-1 text-purple-900 flex gap-1 flex-wrap"}>
                    <Badge className={"cursor-pointer"} color={"indigo"}>
                      {_ProjectAnalysisStats.data?.tokens_count + " mots"}{" "}
                    </Badge>
                    <Badge className={"cursor-pointer"} color={"indigo"}>
                      {_ProjectAnalysisStats.data?.verbatim_count +
                        " verbatims"}
                    </Badge>
                    <Badge className={"cursor-pointer"} color={"indigo"}>
                      {" "}
                      {_ProjectAnalysisStats.data?.dimensions.length +
                        " modèles"}
                    </Badge>
                  </div>
                )}
                 <div className={"mt-1 text-purple-900 flex gap-1 flex-wrap"}>
                    {filter.conditions.filter(f => f.active).map( (f) => <Badge  id={f.dimension+f.label} className={"cursor-pointer"} color={"green"} icon={CiFilter}>
                      {f.dimension} : {f.label}
                    </Badge>)}
                     </div>
                <div className={"mt-2 flex gap-1 flex-wrap"}>
                  <YMTinyButton
                    onClick={() => setShowProjectSettings(true)}
                    icon={
                      <IoIosSettings className={"h-4 w-4 text-white mr-2"} />
                    }
                    text={"paramètres"}
                  />
                </div>
                <div className={"mt-2 flex gap-1 flex-wrap"}>
                  <ButtonProjectFilterModal />
                </div>
                <div className={"mt-2 flex gap-1 flex-wrap"}>
                  <YMTinyButton
                    onClick={() =>
                      navigate(
                        `/project/${_project.data?.id}/models`
                      )
                    }
                    icon={<IoMdAdd className={"h-4 w-4 text-white mr-2"} />}
                    text={"importer un modèle"}
                  />
                </div>
                <Sidebar.Items>
                  {_project.data?.outdatedDimensions.filter(
                    (d) => dimensionOnUpdate.indexOf(d.name) == -1
                  ).length > 0 ? (
                    <div className={"pt-2"}>
                      <h1
                        className={
                          "mt-5 text-indigo-900 text-xs font-bold uppercase mb-2"
                        }
                      >
                        {" "}
                        Mises à jour disponibles{" "}
                      </h1>
                    </div>
                  ) : null}
                  <div className={"flex flex-col gap-5 pb-4"}>
                    {_project.data?.outdatedDimensions
                      .filter((d) => dimensionOnUpdate.indexOf(d.name) == -1)
                      .map((dimension) => (
                        <div
                          key={dimension.name}
                          className={"overflow-hidden flex gap-2 items-center"}
                        >
                          <div className={"text-indigo-900 capitalize text-md"}>
                            {dimension.name}
                          </div>
                          <div>
                            <YMTinyButton
                              color="light"
                              size="xs"
                              icon={
                                <IoMdRefresh
                                  className={"h-4 w-4 text-indigo-900 mr-2"}
                                />
                              }
                              text={"mettre à jours"}
                              onClick={() => onDimensionUpdate(dimension)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Sidebar.Items>
                <div className={"mt-1 pr-4"}>
                  {_ProjectAnalysisStats.data?.error_count > 0 && (
                    <Badge color={"red"}>
                      {_ProjectAnalysisStats.data?.error_count} erreurs
                    </Badge>
                  )}

                  {_ProjectAnalysisStats.data.analyseParsingPercentage <
                    100 && (
                    <div
                      key={`${_ProjectAnalysisStats.data.analyseParsingPercentage}`}
                    >
                      <Tooltip content={_ProjectAnalysisStats.data?.textual}><div
                        className={"text-xs text-purple-400 flex gap-2 mb-2"}
                      >
                        <div> analyse du projet en cours </div>
                        <Spinner size="xs" color={"purple"} />
                      </div></Tooltip>
                      <Progress
                        size="sm"
                        color={"purple"}
                        progress={
                          _ProjectAnalysisStats.data.analyseParsingPercentage
                        }
                      />
                    </div>
                  )}

                  {_ProjectAnalysisStats.data?.dimensionAnalysisPercentage.map(
                    (p) => (
                      <div key={`${p.dimension}${p.p}`} className={"mt-1 pr-4"}>
                        <div>
                          <Tooltip content={p.textual}><div
                            className={
                              "text-xs text-purple-400 flex gap-2 mb-2"
                            }
                          >
                            <div> {p.dimension} <span className={"text-purple-400"}> {p.done}/{p.total} </span></div>
                          </div></Tooltip>
                          <Progress size="sm" color={"purple"}  progress={p.p} />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              {!shouldDisplaySidebar && (
                <div role="status" className="max-w-sm animate-pulse pl-2">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {shouldDisplaySidebar && (
                <>
                  <SiderBarItem
                    icon={<BiNetworkChart />}
                    path={
                      "/project/" +
                      _project.data.id +
                      "/graph?name=" +
                      _project.data.name
                    }
                  >
                    {" "}
                    Graphe Sémantique{" "}
                  </SiderBarItem>
                  <SiderBarItem
                    icon={<TbLayersIntersect />}
                    path={
                      "/project/" +
                      _project.data.id +
                      "/cross?name=" +
                      _project.data.name
                    }
                  >
                    {" "}
                    Croiser les données{" "}
                  </SiderBarItem>
                  {_project.data.most_recent_topic_modeling_id && (
                    <SiderBarItem
                      icon={<RiBookReadLine />}
                      path={
                        "/project/" +
                        _project.data.id +
                        "/topics?name=" +
                        _project.data.name
                      }
                    >
                      {" "}
                      Thèmes{" "}
                    </SiderBarItem>
                  )}

                  <SiderBarItem
                    path={"/project/" + _project.data.id + "/verbatims"}
                    icon={<SiCodereview />}
                  >
                    Verbatims
                  </SiderBarItem>
                  {permissions.indexOf(Permissions.INFERENCE_FULL) != -1 && (
                    <SiderBarItem
                      path={"/project/" + _project.data.id + "/few_shot_tagging"}
                      icon={<GiArmoredBoomerang />}
                    >
                      Few Shot Tagging
                    </SiderBarItem>
                  )}
                  <SideBarItemCollaps
                    label={"Lexiques, n-grammes"}
                    icon={<TbCubeUnfolded />}
                  >
                    <SiderBarItem
                      path={"/project/" + _project.data.id + "/patterns"}
                    >
                      Recherche par motif
                    </SiderBarItem>
                    {permissions.indexOf(Permissions.INFERENCE_FULL) != -1 && (
                      <SiderBarItem
                        path={
                          "/project/" + _project.data.id + "/semantic_search"
                        }
                      >
                        Recherche sémantique
                      </SiderBarItem>
                    )}
                  </SideBarItemCollaps>

                  <SiderBarItem
                    icon={<HiTag />}
                    path={"/project/" + _project.data.id + "/search_tags"}
                  >
                    Tags
                  </SiderBarItem>

                  <SideBarItemCollaps
                    icon={<HiCubeTransparent />}
                    label="Analyse par mots"
                  >
                    {_project.data?.dimensions
                      .filter((d) => d.status == "TAGGED")
                      .filter((d) => d.tag_type == TagType.TOKEN_CLASSIFICATION)
                      .map((dim) => (
                        <SiderBarItem
                          key={dim.name}
                          path={
                            "/project/" +
                            _project.data?.id +
                            "/stats/token/" +
                            dim.name +
                            "?name=" +
                            _project.data?.name +
                            "&dimensionTitle=" +
                            dim.title
                          }
                        >
                          <span className={"capitalize"}>{dim.title}</span>
                        </SiderBarItem>
                      ))}
                  </SideBarItemCollaps>

                  <SideBarItemCollaps
                    icon={<HiCubeTransparent />}
                    label="Analyse par verbatims"
                  >
                    {_project.data?.dimensions
                      .filter((d) => d.status == "TAGGED")
                      .filter((d) => d.name != "ngrammes")
                      .filter((d) => d.tag_type == TagType.TEXT_CLASSIFICATION)
                      .map((dim) => (
                        <SiderBarItem
                          key={dim.name}
                          path={
                            "/project/" +
                            _project.data.id +
                            "/stats/verbatim/" +
                            dim.name +
                            "?name=" +
                            _project.data.name +
                            "&dimensionTitle=" +
                            dim.title
                          }
                        >
                          <span className={"capitalize"}>{dim.title}</span>
                        </SiderBarItem>
                      ))}
                  </SideBarItemCollaps>

                  <SideBarItemCollaps
                    icon={<BsDatabaseAdd />}
                    label="Métadonnées"
                  >
                    {_project.data?.dimensions
                      .filter((d) => d.status == "TAGGED")
                      .filter((d) => d.tag_type == TagType.META_DATA)
                      .map((dim) => (
                        <SiderBarItem
                          key={dim.name}
                          path={
                            "/project/" +
                            _project.data?.id +
                            "/stats/token/" +
                            dim.name +
                            "?name=" +
                            _project.data?.name +
                            "&dimensionTitle=" +
                            dim.title
                          }
                        >
                          <span className={"capitalize"}>{dim.title}</span>
                        </SiderBarItem>
                      ))}
                  </SideBarItemCollaps>

                  <SiderBarItem
                    path={
                      "/project/" +
                      _project.data.id +
                      "/manual_annotations?name=" +
                      _project.data.name
                    }
                    icon={<GiNotebook />}
                  >
                    Recap' actions
                  </SiderBarItem>

                  <SiderBarItem
                    path={
                      "/project/" +
                      _project.data.id +
                      "/sources?name=" +
                      _project.data.name
                    }
                    icon={<HiOutlineDocumentText />}
                  >
                    Sources du projet
                  </SiderBarItem>

                  {isDeveloper && (
                    <SiderBarItem
                      href={_project.data?.analyseParsingDatavizUrl}
                      icon={<HiClock />}
                    >
                      Chronologie
                    </SiderBarItem>
                  )}
                </>
              )}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
        <div
          id={"panel-drawer"}
          className="flex-1 overflow-auto p-0 sm:ml-64 bg-gray-50 min-h-screen w-100"
        >
          <div className="bg-white bg-opacity-20 backdrop-blur-md p-2 rounded-lg shadow-xl min-h-screen mt-20">
            {_project.data.parsing_pause_reason == "LOW_TOKEN_BALANCE" && (
              <Alert color="warning" rounded>
                <span>
                  <p>
                    <span className="font-bold">Analyse interrompue !</span>{" "}
                    Vous n'avez plus assez de token pour poursuivre l'analyse.
                    Pensez à recharger votre compte ou bien contacter
                    l'administrateur de votre espace de travail.
                  </p>
                </span>
              </Alert>
            )}

            {shouldDisplayChildrenNode && children}

            {!shouldDisplayChildrenNode && (
              <div className="w-100 mt-10 flex justify-center items-center">
                <div className={"text-center"}>
                  <Spinner color="gray" size="xl" />
                  <div className={"mt-4 text-gray-400"}>
                    {" "}
                    L'analyse du projet va démarrer dans quelques instants.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
