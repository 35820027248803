import {
  AppCLient,
  ModelId,
  ModelType,
  NewWorkspaceModel,
  WorkspaceModel,
} from "../../app_client";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../AuthUserContext";

interface PatternMatchingModelsApi {
  getModels(): Promise<WorkspaceModel[]>;

  createModel(request: NewWorkspaceModel): Promise<ModelId>;
}

export const usePatternMatchingModelApi = (): PatternMatchingModelsApi => {
  const [api, setApi] = useState<PatternMatchingModelsApi | null>(null);
  const { token, workspaceId } = useContext(AuthUserContext) as {
    token: string;
    workspaceId: string;
  };

  useEffect(() => {
    if (token && workspaceId) {
      let client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setApi({
        getModels: () => {
          return client.workspace.getWorkspaceModelsWorkspaceWorkspaceIdModelsGet(
            workspaceId, ModelType.PATTERN_MATCHING
          );
        },
        createModel: (request: NewWorkspaceModel) => {
          return client.workspace.createPatternMatchingModelWorkspaceWorkspaceIdPatternMatchingModelPost(
            workspaceId,
            request
          );
        },
      });
    }
  }, [token, workspaceId]);

  return api as PatternMatchingModelsApi;
};
