import { Button, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { WorkspaceProject } from "../../../app_client";
import { useNavigate } from "react-router-dom";
import { YMButton, YMInput, YMLoader, YMTable } from "../../../ym-components";
import { HiTrash } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { NewProjectForm } from "./NewProjectForm";
import { formatNumber } from "../../../util";
import { useProjectApi } from "../State";

const ConfirmDeleteProject: React.FC<{
  projectName: string;
  projectId: string;
  show: boolean;
  onProjectDelete: (project_id: string) => void;
  onModalClose: () => void;
}> = ({ projectName, projectId, show, onModalClose, onProjectDelete }) => {
  return (
    <Modal show={show} onClose={onModalClose}>
      <Modal.Header>
        <div className={"text-xl font-bold text-indigo-900"}>
          Supprimer {projectName}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-indigo-900">
            Voulez vous vraiment supprimer {projectName} ?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <YMButton
          text={"Confirmer"}
          onClick={() => onProjectDelete(projectId)}
        />
        <YMButton text={"Annuler"} color={"white"} onClick={onModalClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default function ProjectList(props: any) {
  const projectApi = useProjectApi();

  const [projects, setProjects] = useState([] as WorkspaceProject[]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("" as string);

  const [deleteModalSettings, setDeleteModalSettings] = useState({
    show: false,
    projectId: "",
    projectName: "",
  });

  useEffect(() => {
    if (projectApi) {
      projectApi.getProjects().then((projects) => {
        setProjects(projects);
        setIsLoading(false);
      });
    }
  }, [projectApi]);

  const deleteProject = async (project_id: string) => {
    if (projectApi) {
      await projectApi.deleteProject(project_id);
      if (isLoading)
        setProjects(projects.filter((p) => p.project_id !== project_id));
      projectApi.getProjects().then(setProjects);
    }
    setDeleteModalSettings((prev) => ({ ...prev, show: false }));
  };

  const displayDeleteModal = (project_id: string) => {
    setDeleteModalSettings({
      show: true,
      projectId: project_id,
      projectName:
        projects.find((p) => p.project_id === project_id)?.name || "",
    });
  };

  return (
    <div>
      <NewProjectForm />
      <ConfirmDeleteProject
        {...deleteModalSettings}
        onProjectDelete={deleteProject}
        onModalClose={() =>
          setDeleteModalSettings((prev) => ({ ...prev, show: false }))
        }
      />
      <div>
        <YMLoader message={"Chargement des projects"} loading={isLoading}>
          {projects.length === 0 && (
            <div className="w-100 mt-10 text-center">
              <div className={"mt-4 text-gray-400"}>
                {" "}
                Vous n'avez pas encore de projets
              </div>
            </div>
          )}

          {projects.length > 0 && (
            <>
              <div className={"flex flex-col gap-2 mt-5 w-96"}>
                <div className={"font-bold text-indigo-900 flex "}>
                  <BiSearch className={"h-6 w-6 mr-2"} /> Recherche
                </div>
                <YMInput
                  value={search}
                  onChange={setSearch}
                  label={"Filtrer par nom"}
                />
              </div>
              <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
                <YMTable
                  header={
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Project
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Date de création
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Nombre de mots
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Nombre de verbatims
                      </th>
                      <th scope="col" className="px-6 py-3">
                        <span className="sr-only"> x </span>
                      </th>
                    </tr>
                  }
                  rows={filteredProjects(projects, search).map((p) => (
                    <ProjectRow
                      project={p}
                      onDelete={() => displayDeleteModal(p.project_id)}
                    />
                  ))}
                />
              </div>
            </>
          )}
        </YMLoader>
      </div>
    </div>
  );
}

const ProjectRow: React.FC<{
  project: WorkspaceProject;
  onDelete: () => void;
}> = ({ project, onDelete }) => {
  const navigate = useNavigate();
  return (
    <tr
      key={project.project_id}
      onClick={() =>
          navigate(
            "/project/" + project.project_id + "/graph/?name=" + project.name
          )
      }
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
    >
      <th
        scope="row"
        className="px-6 py-4 font-bold text-indigo-900 capitalize hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
      >
        {project.name}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-normal text-indigo-900 capitalize hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
      >
        {formatDate(project.created_at)}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-normal text-indigo-900 capitalize hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
      >
        {formatNumber(project.tokens_count)}
      </th>
            <th
        scope="row"
        className="px-6 py-4 font-normal text-indigo-900 capitalize hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
      >
        {formatNumber(project.verbatim_count)}
      </th>
      <td className="px-6 py-4 flex gap-2">
        <Button size="xs" color="gray" onClick={e => {
          e.stopPropagation();
          onDelete()
        }}>
          <HiTrash className="h-5 w-5"/>
        </Button>
      </td>
    </tr>
  );
};

// helpers

const formatDate = (dateString:string) => {
  const options: any = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

const filteredProjects = (projects: WorkspaceProject[], search: string) => {
  return projects.filter((p) =>
    p.name.toLowerCase().includes(search.toLowerCase())
  );
};
