// react component
import React from "react";
import { YMButton } from "../../../ym-components";
import { HiTrash } from "react-icons/hi";
import { Modal } from "flowbite-react";
import { useMutation } from "@tanstack/react-query";
import { ProjectApi, useProjectApi } from "../State";
import { useNavigate } from "react-router-dom";

export const ButtonDeleteDimension = ({
  dimensionName,
  dimensionId,
  projectId,
}: {
  dimensionName: string;
  dimensionId: string;
  projectId: string;
}) => {
  const api = useProjectApi() as ProjectApi;
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);

  const deleteDimensionMutation = useMutation({
    mutationFn: () => {
      return api.deleteDimension(projectId, dimensionId);
    },
    onSuccess: () => {
      setShowModal(false);
      navigate(`/project/${projectId}/`);
    },
  });

  return (
    <>
      <YMButton
        color="white"
        icon={<HiTrash className="h-5 w-5" />}
        text={"supprimer"}
        onClick={() => setShowModal(true)}
      />
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>Supprimer {dimensionName}</Modal.Header>
        <Modal.Body>
          <div className={"text-gray-600"}>
            Êtes-vous sûr de vouloir supprimer {dimensionName} ? Tous les tags
            associés à cette dimension seront supprimés, ainsi que toutes les
            statistiques associées.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <YMButton
            color="white"
            text={"Annuler"}
            onClick={() => setShowModal(false)}
          />
          <YMButton
            disabled={deleteDimensionMutation.isLoading}
            text={"Supprimer"}
            onClick={() => deleteDimensionMutation.mutate()}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};