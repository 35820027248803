import {Breadcrumb} from "flowbite-react";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PatternMatchingModelForm} from "./ModelForm";

export default function PatternMatchingModelPage(props: any) {
  const [searchParams, _] = useSearchParams();
  const [modelId, setModelId] = useState("" as string | undefined);
  const [modelName, setModelName] = useState("" as string);
  const navigate = useNavigate();

  // moke model name to display in breadcrumb
  useEffect(() => {
    if (searchParams.get("ModelName")) {
      setModelName(searchParams.get("ModelName") as string);
    }
    if (searchParams.get("id")) {
      setModelId(searchParams.get("id") as string);
    }
  }, [searchParams]);

  return (
    <div>
      <Breadcrumb
        aria-label="Solid background breadcrumb example"
        className="bg-gray-50 py-3 px-5 dark:bg-gray-900"
      >
        <Breadcrumb.Item>
          <a
              className={"cursor-pointer hover:text-indigo-900"}
              onClick={() => navigate("/pattern_matching_models")}
          >
            {" "}
            Modèles à champs lexicaux{" "}
          </a>
        </Breadcrumb.Item>
        {modelId && (
          <Breadcrumb.Item className={"capitalize"}>
            {modelName}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <PatternMatchingModelForm modelName={modelName} modelId={modelId} />
    </div>
  );
}
