/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TopicModelingVisualizationType {
    BARCHART = 'barchart',
    HIERARCHY = 'hierarchy',
    BUBBLECHART = 'bubblechart',
    HEATMAP = 'heatmap',
    DOCUMENTS = 'documents',
}
