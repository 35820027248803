import {
  ProjectDimension,
  TaggedToken,
  VerbatimViewWithTagTokens,
} from "../../../../app_client";
import {
  onDeleteParams,
  onTagParams,
  VerbatimToken,
} from "../../VerbatimToken";
import React, { useState } from "react";

export default function Verbatim({
  verbatim,
  onDelete,
  dimensions,
  onTag,
}: {
  verbatim: VerbatimViewWithTagTokens;
  dimensions: ProjectDimension[];
  onDelete: (params: onDeleteParams) => void;
  onTag: (params: onTagParams) => void;
}) {
  const spans: JSX.Element[] = [];
  let previous = null as null | TaggedToken;
  const [displayTag, setDisplayedTag] = useState(-1);

  verbatim.tagged_tokens.forEach((token: TaggedToken, i: number) => {
    spans.push(
      <VerbatimToken
        key={i}
        verbatim={verbatim}
        token={token}
        tokenIndex={i}
        open={displayTag == i}
        onActive={() => setDisplayedTag(i)}
        onLeave={() => setDisplayedTag(-1)}
        onDelete={(args) => {
          setDisplayedTag(-1);
          onDelete(args);
        }}
        onTag={(args) => {
          setDisplayedTag(-1);
          onTag(args);
        }}
        previous={previous}
        dimensions={dimensions}
      />
    );
    previous = token;
  });

  return (
    <div className={"flex flex-wrap max-w-screen-lg"}>
      {spans}
      <div className={"hidden"} />
    </div>
  );
}
