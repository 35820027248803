import {useApi} from "../api";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, FileInput, Label, Spinner} from "flowbite-react";
import {parse} from "papaparse";

export default function TopicModelingStopWords(props: any) {
  const { api } = useApi();
  const [words, setWords] = useState([]);
  const [csvfile, setCsvFile] = useState("" as any);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    api && api.get_topic_modeling_stop_words().then(setWords);
  }, [api]);

  const handleFileChange = (e: any) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!["csv"].includes(fileExtension)) {
        alert("Please input a csv file");
        throw Error("csv file expected");
      }
      setCsvFile(inputFile);
    }
  };

  const handleUpload = async () => {
    if (!csvfile) {
      alert("no file selected !");
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      setUploading(true);
      // @ts-ignore
      const csv = parse(event.target.result, { header: false });
      // @ts-ignore
      const parsedData = csv?.data;
      if (parsedData.length === 0) {
        alert("The filed is empty");
      } else {
        // @ts-ignore
        let csvData = parsedData.map((row: any) => row[0]);
        try {
          await api.update_topic_modeling_stop_words(csvData);
        } catch (err: any) {
          alert(err.message);
        }
        setUploading(false);
      }
    };
    reader.readAsText(csvfile);
  };

  return (
    <div>
      <Breadcrumb
        aria-label="Solid background breadcrumb example"
        className="bg-gray-50 py-3 px-5 dark:bg-gray-900"
      >
        <Breadcrumb.Item>Topic Modeling / Stop words</Breadcrumb.Item>
      </Breadcrumb>
      <div className="w-100 mt-28">
        {/* file upload */}
        <div id="fileUpload" className="mt-10">
          <div className="mb-2 block">
            <Label
              htmlFor="file"
              value="Upload a .csv file to update stop words"
            />
          </div>
          <FileInput
            id="file"
            accept=".csv"
            helperText="Upload a csv file containing a list of words"
            onChange={handleFileChange}
          />
        </div>
        {!uploading && (
          <Button
            className="mt-10"
            gradientMonochrome="info"
            onClick={handleUpload}
          >
            Update
          </Button>
        )}
        {uploading && <Spinner />}
      </div>
      <div className="w-100 mt-28">
        <p>
          {words.map((w) => (
            <>
              <span> {w} </span>
              <br />
            </>
          ))}
        </p>
      </div>
    </div>
  );
}
