import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { YMButton, YMInput } from "../../../../ym-components";
import { FewShotLearningComponentModel } from "../Model";
import { ClassificationMethod } from "../../../../app_client";
import { FewShotTaggingModelConfiguration } from "../../../fewshot-learning-models/components/ConfigureModel";

export const NewFewShotLearningModel = ({
  actions,
  state,
  dispatch,
}: Pick<FewShotLearningComponentModel, "actions" | "state" | "dispatch">) => {
  const [modelName, setModelName] = useState(state.selectedDimensionName || "");
  const [embeddingModel, setEmbeddingModel] = useState("mistral-embed");
  const [modelType, setModelType] = useState(
    ClassificationMethod.MULTI as ClassificationMethod
  );

  return (
    <Modal
      root={document.body}
      size="5xl"
      show={state.modalNewModel}
      onClose={() => dispatch({ type: "NEW_MODEL_MODAL_TOGGLED" })}
    >
      <Modal.Header>
        <div className={"font-bold text-indigo-800 title"}>
          {" "}
          Nouveau modèle de few shot learning{" "}
        </div>
        <p className={"text-indigo-800 text-sm"}>
          {" "}
          Les tags appris dans <b>{state.selectedDimensionName}</b> seront
          utilisés pour entraîner le nouveau modèle{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <h3
          className={
            "dark:text-white text-indigo-900 text-md mt-2 mb-2 font-bold"
          }
        >
          {" "}
          Information{" "}
        </h3>

        <YMInput
          value={modelName}
          label={`Nom du modèle`}
          onChange={(e) => setModelName(e)}
        />
        <FewShotTaggingModelConfiguration
          onEmbeddingModelChange={setEmbeddingModel}
          embeddingModel={embeddingModel}
          classificationType={modelType}
          onClassificationMethodChange={(method) => setModelType(method)}
        />
      </Modal.Body>
      <Modal.Footer>
        <YMButton
          text={"Créer"}
          disabled={modelName.length < 5}
          onClick={() => {
            actions.createFewShotModel(modelName, modelType, embeddingModel);
            setModelName("");
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};


