import toast, { ToastOptions } from "react-hot-toast";
import { FaInfo } from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";

const ToastClass =
  "flex w-full max-w-xs items-center rounded-lg bg-indigo-100 p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400 duration-300 animate-enter";

const ToastClassError =
  "flex w-full max-w-xs items-center rounded-lg bg-red-100 p-4 text-red-900 shadow dark:bg-gray-800 dark:text-gray-400 duration-300 animate-enter";


export const notificationInfo = (
  message: string,
  options?: ToastOptions
): string => {
  return toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } ${ToastClass}`}
      >
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-indigo-200 text-indigo-900">
          <FaInfo className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm text-indigo-900 font-normal">
          {message}
        </div>
        <button
          onClick={() => toast.dismiss(t.id)}
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-indigo-200 text-indigo-900 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-success"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    ),
    options
  );
};

export const notificationError = (
  message: string,
  options?: ToastOptions
): string => {
  return toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } ${ToastClassError}`}
      >
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-200 text-red-500">
          <AiFillAlert className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm text-red-900 font-normal pr-5">
          {message}
        </div>
        <button
          onClick={() => toast.dismiss(t.id)}
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-red-500 text-red-100 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-success"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    ),
    options
  );
};

export const notificationDismiss = (notificationId: string) => {
  toast.dismiss(notificationId);
};
