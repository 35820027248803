import { useProjectApi } from "../State";
import React, { useState } from "react";
import {
  SheetFileConfiguration,
  SheetFileConfigurationModal,
} from "./SheetFileConfigurationModal";
import { Button, Label } from "flowbite-react";
import { FileToast } from "./components";
import { HiTrash } from "react-icons/hi";

export type ConfigurationMap = {
  [key: string]: SheetFileConfiguration;
};

export type UploadState = {
  [key: string]: "uploading" | "done" | "error";
};

export const FileUploadForm = ({
  onFilesUpdated,
  uploadStates,
}: {
  onFilesUpdated: (files: File[], configurations: ConfigurationMap) => void;
  uploadStates: UploadState;
}) => {
  const projectApi = useProjectApi();
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [modalFile, setModalFile] = useState<File | undefined>(undefined);
  const onDeleteFile = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };
  const [configurations, setConfigurations] = useState<ConfigurationMap>({});

  const onNewFileSelected = async (e: any) => {
    if (!projectApi || !e.target.files.length) return;
    const newFiles = [...files];
    Array.from<File>(e.target.files).forEach((f) => {
      if (!isAllowed(f)) {
        e.target.value = "";
        alert(`.${extensionOf(f)} n'est pas un format supporté par youmean`);
        return;
      }
      newFiles.push(f);
      if (isASheet(f)) {
        setModalFile(f);
      }
    });
    setFiles(newFiles);
    onFilesUpdated(newFiles, configurations);
  };

  const onConfirmModal = (conf: SheetFileConfiguration) => {
    if (!modalFile) return;
    setModalFile(undefined);
    const newConfiguration = {
      ...configurations,
      [fileId(modalFile)]: {
        ...conf,
        ignore_header: !conf.ignore_header,
        data_column: (conf.data_column || 1) - 1,
      },
    };
    setConfigurations(newConfiguration);
    onFilesUpdated(files, newConfiguration);
  };

  const onCloseModal = () => {
    setFiles(files.filter((f) => f != modalFile));
    setModalFile(undefined);
  };

  return (
    <div id="fileUpload">
      <SheetFileConfigurationModal
        show={modalFile !== undefined}
        file={modalFile as File}
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
      />
      <div className="mb-2 block">
        <Label
          className={"text-indigo-900"}
          htmlFor="file"
          value="Sélectionnez vos documents"
        />
      </div>
      <div className="mb-5">
        {files.map((f: File) => (
          <FileToast
            key={f.name}
            file={f}
            state={uploadStates[fileId(f)]}
            onDelete={() => onDeleteFile(f)}
          >
            {f.name}
          </FileToast>
        ))}
      </div>
      <div className={"flex gap-2 items-end"}>
        <input
          multiple={true}
          ref={inputRef}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          type="file"
          onChange={onNewFileSelected}
        />
        <Button
          size="xs"
          color="gray"
          onClick={() => {
            if (inputRef !== null && inputRef.current !== null) {
              inputRef.current.value = "";
            }
          }}
        >
          <HiTrash className="h-4 w-4" />
        </Button>
      </div>
      <div className="mt-1 text-sm text-gray-500 dark:text-gray-300">
        txt, csv, xlsx, xls, docx, doc, epub, html, pdf
      </div>
    </div>
  );
};

const allowed_extensions = [
  "txt",
  "csv",
  "xlsx",
  "xls",
  "doc",
  "docs",
  "docx",
  // "pptx",
  "ppt",
  "epub",
  "html",
  "pdf",
];
const structured_extensions = ["csv", "xlsx", "xls"];

export const fileId = (f: File) => f.name + f.size;

export const extensionOf = (file: File): string => {
  return file.name.split(".").pop() as string;
};

export const isASheet = (file: File): boolean => {
  return structured_extensions.includes(extensionOf(file));
};

export const isAllowed = (file: File): boolean => {
  return allowed_extensions.includes(extensionOf(file));
};
