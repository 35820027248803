import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

export function debounce(func: any, delay: number) {
  let timeoutId: any;
  return function (...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, delay);
  };
}

export const throttle = (fn: Function, wait: number = 300) => {
  let inThrottle: boolean,
      lastFn: ReturnType<typeof setTimeout>,
      lastTime: number;
  return function (this: any) {
    const context = this,
        args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export function formatNumber(num: number): string {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    const thousands = Math.floor(num / 1000);
    const remainder = num % 1000;
    return thousands + "k";
  } else if (num < 1000000000) {
    let M = Math.floor(num / 1000000);
    return M + "M" + " " + formatNumber(num - M * 1000000);
  } else {
    const billions = Math.floor(num / 1000000000);
    const remainder = num % 1000000000;
    if (remainder >= 1000000) {
      return billions + "." + Math.floor(remainder / 1000000) + "B";
    } else {
      return billions + "B" + (remainder > 0 ? formatNumber(remainder) : "");
    }
  }
}

const useSyncMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey"
  >
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const mutationResults = useMutation(mutationFn, options);

  return {
    ...mutationResults,
    mutate: (...params: [TVariables]) => {
      if (!mutationResults.isLoading) {
        mutationResults.mutate(...params);
      }
    },
  };
};

export default useSyncMutation;

export const SingletonDimensions = [
  "publics",
  "organisations",
  "lieux",
  "personnes",
  "work_of_art",
  "time",
  "quantity",
  "product",
  "personnes",
  "percent",
  "ordinal",
  "nationalities_or_religious_or_political_groups",
  "money",
  "law",
  "language",
  "gpe",
  "event",
  "facilities",
  "date",
  "cardinal",
];

export const isSingleton = (dimension: string) => {
  return SingletonDimensions.includes(dimension);
}

export const downloadAsCsv = (data: string, filename: string) =>
  downloadAs(data, filename, "csv");
export const downloadAsXlsx = (data: string, filename: string) =>
  downloadAs(data, filename, "xlsx");
export const downloadAsJsonl = (data: string, filename: string) =>
  downloadAs(data, filename, "jsonl");
const downloadAs = (data: string, filename: string, ext: string) => {
  const url = URL.createObjectURL(new Blob([data], {type: mimeType(ext)}));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.${ext}`);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
};

const mimeType = (ext: string) => {
    switch (ext) {
        case "csv":
        return "text/csv";
        case "xlsx":
        return "application/vnd.ms-excel";
        case "jsonl":
        return "application/json";
        default:
        return "";
    }
}