/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TagType {
    TEXT_CLASSIFICATION = 'text-classification',
    TOKEN_CLASSIFICATION = 'token-classification',
    META_DATA = 'meta-data',
}
