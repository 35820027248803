/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdentityResponse } from '../models/IdentityResponse';
import type { JobMonitoringView } from '../models/JobMonitoringView';
import type { NewJob } from '../models/NewJob';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NlpJobsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Jobs
     * @param recentJobsOnly
     * @returns JobMonitoringView Successful Response
     * @throws ApiError
     */
    public listJobsJobsGet(
        recentJobsOnly: boolean = true,
    ): CancelablePromise<Array<JobMonitoringView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/jobs/',
            query: {
                'recent_jobs_only': recentJobsOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * New Job
     * @param requestBody
     * @returns IdentityResponse Successful Response
     * @throws ApiError
     */
    public newJobJobsPost(
        requestBody: NewJob,
    ): CancelablePromise<IdentityResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/jobs/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Result As Csv
     * @param jobId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getJobResultAsCsvJobsJobIdCsvGet(
        jobId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/jobs/{job_id}/csv',
            path: {
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
