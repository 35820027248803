/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ClassificationMethod {
    MONO = 'mono',
    MULTI = 'multi',
}
