/*
Clustering algotithm.
Inputs :
- nodes ['label1', 'label2', ... ]
- edges [ {source:'label1', target:'label2', value:12}, ... ]
- nbExpectedClusters : integer between 1 and 8
- initialCluster : { 'label1': 2, 'label2':1, ...}

Outputs :
- nodes [ {id:'label1', group:1}, {id:'label2', group:2}, ... ]
- nbClusters : integer. The algorith do the best to fit the requested nbExpectedClusters

Use the Louvain community detection algorithm
*/

import jLouvain from "./jLouvain";

const NB_MAX_ITERATIONS = 10;

function findBiggestCommunityId(cluster) {
  console.log("CLUSTERING -- Finding biggest community of", cluster);
  const countEachCommunity = Object.keys(cluster).reduce((count, key) => {
    const communityId = cluster[key];
    if (count[communityId]) {
      count[communityId] = count[communityId] + 1;
    } else {
      count[communityId] = 1;
    }
    return count;
  }, {});

  const biggestCommunityId = Object.keys(countEachCommunity).reduce(
    (max, key) => (countEachCommunity[key] > max ? key : max),
    0
  );
  return parseInt(biggestCommunityId);
}

function splitPartition(initialCluster, biggestCommunityId) {
  const newCommunityId = computeNbOfClusters(initialCluster);
  console.log("initialCluster size:", computeNbOfClusters(initialCluster));

  const newPartition = Object.keys(initialCluster).reduce((partition, key) => {
    if (initialCluster[key] === biggestCommunityId) {
      return {
        ...partition,
        [key]: Math.random() > 0.5 ? initialCluster[key] : newCommunityId,
      };
    } else {
      return { ...partition, [key]: initialCluster[key] };
    }
  }, {});
  console.log("Splitted partition size:", computeNbOfClusters(newPartition));
  return newPartition;
}

function buildInitialPartition(
  nodes,
  nbExpectedClusters,
  initialCluster,
  debugMode = true
) {
  debugMode && console.log("CLUSTERING -- Initial cluster", initialCluster);
  const nbOfClusters = computeNbOfClusters(initialCluster);
  if (
    initialCluster &&
    Object.keys(initialCluster) &&
    nbOfClusters === nbExpectedClusters
  ) {
    debugMode &&
      console.log("CLUSTERING -- Init partition with initial cluster");
    // Ensure consistency between the nodes and the initial cluster
    return Object.keys(initialCluster)
      .filter((k) => nodes.includes(k))
      .reduce((acc, val) => ({ ...acc, [val]: initialCluster[val] }), {});
  }
  /*
  if (
    initialCluster &&
    Object.keys(initialCluster) &&
    nbOfClusters < nbExpectedClusters
  ) {
    // Find the biggest cluster, an split it in 2 parts
    console.log("CLUSTERING -- Try to split the initial cluster in 2 parts");
    const biggestCommunityId = findBiggestCommunityId(initialCluster);
    console.log("biggestCommunityId:", biggestCommunityId);
    return splitPartition(initialCluster, biggestCommunityId);
  }
  */

  debugMode && console.log("CLUSTERING -- Init partition with random cluster");

  return nodes.reduce(
    (partition, node) => ({
      ...partition,
      [node]: Math.floor(Math.random() * nbExpectedClusters),
    }),
    {}
  );
}

function computeNbOfClusters(clustering) {
  if (!clustering) return 0;
  const clusters = Object.keys(clustering).reduce(
    (arrayOfGroups, key) =>
      arrayOfGroups.includes(clustering[key])
        ? arrayOfGroups
        : [...arrayOfGroups, clustering[key]],
    []
  );
  return clusters.length;
}

function clusterGraph({
  nodes,
  edges,
  ExpectedNbClusters,
  initialCluster,
  debugMode = false,
}) {
  var nbClusters = -1;
  var clusterGraphFunction, clusters;
  var nodesClone = [...nodes];
  var iteration = 0;
  var cluster = initialCluster;

  while (nbClusters !== ExpectedNbClusters && iteration < NB_MAX_ITERATIONS) {
    debugMode && console.log("CLUSTERING -- Iteration#", iteration);
    clusterGraphFunction = jLouvain()
      .nodes(nodesClone)
      .edges(edges)
      .partition_init(
        buildInitialPartition(
          nodesClone,
          ExpectedNbClusters,
          initialCluster,
          debugMode
        )
      );
    clusters = clusterGraphFunction();
    debugMode && console.log("CLUSTERING -- clusters :", clusters);

    // Does it match the expected nb ?
    cluster = clusters[clusters.length - 1];
    nbClusters = computeNbOfClusters(cluster);
    debugMode && console.log("CLUSTERING -- Elected cluster :", cluster);
    debugMode && console.log("CLUSTERING -- nbClusters :", nbClusters);
    iteration += 1;
  }

  nodesClone = cluster
    ? Object.keys(cluster).map((key) => ({
        id: key,
        group: 1 + cluster[key],
      }))
    : nodesClone;

  debugMode && console.log("CLUSTERING -- nodes :", nodesClone);

  return { nbClusters, cluster, nodesWithIdAndGroup: nodesClone };
}

export default clusterGraph;
