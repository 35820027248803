import React from "react";
import Github from "react-color/lib/components/github/Github";
import reactCSS from "reactcss";

const YMColors = [
  "#6775f5",
  "#3e83f8",
  "#f05152",
  "#f59e0c",
  "#0E9F6E",
  "#374151",
  "#ffffff",
];

  const colorToName = {
    "#6775f5": "indigo",
    "#3e83f8": "blue",
    "#f05152": "red",
    "#f59e0c": "amber",
    "#0e9f6e": "emerald",
    "#374151": "gray",
    "#ffffff": "white"
  }

  const safeGithubColor = (color: string) => {
    if (color == "amber") {
      return "#f59e0c";
    } if (color == "emerald") {
      return "#0e9f6e";
    } else
    return color;
  }


export const ColorPicker = ({
  color,
  handleColorPicked,
}: {
  color: string;
  handleColorPicked: (c: string) => void;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: any) => {
    // @ts-ignore
    handleColorPicked(colorToName[color.hex] || color.hex)
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "15px",
        height: "15px",
        borderRadius: "100px",
        background: `${safeGithubColor(color)}`,
      },
      swatch: {
        padding: "2px",
        background: "#fff",
        borderRadius: "100px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <Github colors={YMColors} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};
