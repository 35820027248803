/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ColorChart {
    COOL_GRAY = 'cool_gray',
    GREEN = 'green',
    RED = 'red',
    AMBER = 'amber',
    EMERALD = 'emerald',
    BLUE = 'blue',
    INDIGO = 'indigo',
    VIOLET = 'violet',
    PINK = 'pink',
}
