import { ProjectView } from "../../app_client";
import { Modal } from "flowbite-react";
import { YMButton, YMInput } from "../../ym-components";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { ProjectApi, useProjectApi } from "./State";

export const ProjectSettings: React.FC<{
  project: ProjectView;
  show: boolean;
  onClose: () => void;
}> = ({ project, show, onClose }) => {
  const [projectName, setProjectName] = React.useState<string>(project.name);

  const api = useProjectApi() as ProjectApi;
  const updateProject = useMutation({
    mutationFn: ({ newName }: { newName: string }) => {
      return api.updateProject(project.id, { newName });
    },
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <Modal root={document.body} show={show} onClose={onClose}>
      <Modal.Header> Paramètre de {project.name} </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <YMInput
            value={projectName}
            label={"Nom du project"}
            onChange={setProjectName}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <YMButton
          disabled={updateProject.isLoading}
          onClick={() => updateProject.mutate({ newName: projectName })}
          text={"Sauvegarder"}
        />
      </Modal.Footer>
    </Modal>
  );
};
