import {useEffect, useState} from "react";
import {
    AppCLient,
    JobMonitoringView,
    JobType,
    ModelPreset,
    PatternMatchingModelProjection,
    ProjectDimensionClasseDistribution,
    ProjectView,
    VerbatimPaginated,
} from "./app_client";
import {POS} from "./pages/project/utils";
import {useAuth0} from "@auth0/auth0-react";
import {Spinner} from "flowbite-react";
import {LoginPage} from "./pages/loginPage";
import {Toaster} from "react-hot-toast";
import {notificationDismiss, notificationInfo} from "./toast-notification";
import {downloadAsCsv, downloadAsJsonl} from "./util";
import {WordCloudParams} from "./pages/project/dimension/WordCloud";
import {ProjectFilter, projectFilterQueryString} from "./pages/project/State";

const endpoint_topic_modeling = `${process.env.REACT_APP_APP_API_HOST}/topic_modeling_job/`;
const endpoint_project = `${process.env.REACT_APP_APP_API_HOST}/project/`;

export const useApi = function () {
  const [api, setApi] = useState(undefined as any);
  const [token, setToken] = useState("");
  const { getAccessTokenSilently, isLoading, isAuthenticated, user } =
    useAuth0();

  useEffect(() => {
    if (!token) {
      getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }).then((new_token) => {
        if (!token) setToken(new_token);
      });
    }

    if (user && (window as any).$crisp) {
      let $crisp: any = (window as any).$crisp;
      $crisp.push(["set", "user:email", [user.email]]);
      if (user.picture) {
        $crisp.push(["set", "user:avatar", [user.picture]]);
      }
      if (user.name) {
        $crisp.push(["set", "user:nickname", [user.name]]);
      }
    }

    if (!isLoading && isAuthenticated && token) {
      const client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setTimeout(
        () =>
          setApi({
            get_inference_jobs: async (params: any): Promise<Array<any>> => {
              return client.nlpJobs.listJobsJobsGet(params.recent_jobs_only);
            },
            new_inference_job: async (
              csv_data: string[],
              filename: string,
              model: string
            ) => {
              await client.nlpJobs.newJobJobsPost({
                analyse_id: "analyse_1",
                type: JobType.TEXT_CLASSIFICATION,
                text_data: csv_data,
                model_name: model,
                name: filename,
                analyse_dimension: "unknow",
              });
            },
            download_verbatims_csv: async (
              analyse_id: string,
              filename: string
            ) => {
              client.nlpJobs
                .getJobResultAsCsvJobsJobIdCsvGet(analyse_id)
                .then((response) => {
                  downloadAsCsv(response, filename);
                })
                .catch((error) => {
                  alert(error);
                });
            },
            download_tags_csv: async (analyse_id: string, filename: string) => {
              client.nlpJobs
                .getJobResultAsCsvJobsJobIdCsvGet(analyse_id)
                .then((response) => {
                  downloadAsCsv(response, filename);
                })
                .catch((error) => {
                  alert(error);
                });
            },
            download_topic_csv: async (
              analyse_id: string,
              filename: string
            ) => {
              client.topicModelingJob
                .getTopicCsvTopicModelingJobJobIdCsvGet(analyse_id)
                .then((response) => {
                  downloadAsCsv(response, filename);
                })
                .catch((error) => {
                  alert(error);
                });
            },
            get_topic_modeling_jobs: async (): Promise<Array<any>> => {
              return client.topicModelingJob.getJobsTopicModelingJobGet();
            },
            new_topic_modeling_job: async (
              csv_data: string[],
              filename: string,
              params: object
            ) => {
              await client.topicModelingJob.newTopicModelingTopicModelingJobPost(
                {
                  sentences: csv_data,
                  name: filename,
                  params: params,
                }
              );
            },
            topic_modeling_visualisation_iframe_url: (
              aggregate_id: string,
              type: string
            ): string => {
              return `${endpoint_topic_modeling}${aggregate_id}/${type}.html`;
            },
            get_topic_modeling_stop_words: async (): Promise<Array<any>> => {
              return client.topicModelingJob.getStopwordsTopicModelingJobStopwordsGet();
            },
            update_topic_modeling_stop_words: async (
              words: string[]
            ): Promise<Array<any>> => {
              return client.topicModelingJob.updateStopwordsTopicModelingJobStopwordsPost(
                { words }
              );
            },
            get_events: async (aggregate_id: string): Promise<Array<any>> => {
              return client.kernelEndpoint.getEventsKernelEventsAggregateIdGet(
                aggregate_id
              );
            },
            get_last_events: async (): Promise<Array<any>> => {
              return client.kernelEndpoint.getLastEventsKernelEventsGet();
            },
            get_project_info: async (
              project_id: string
            ): Promise<ProjectView> => {
              return client.project.getProjectInfoProjectProjectIdGet(
                project_id
              );
            },
            add_file_to_project: async (
              project_id: string,
              file: File,
              params: any
            ): Promise<any> => {
              await client.project.addDocumentProjectProjectIdDocumentsPost(
                project_id,
                {
                  file: file,
                },
                params.ignore_header || false,
                params.data_column || 0,
                params.split_cell
              );
            },
            list_verbatims: async (
              project_id: string,
              lemmas: string | undefined,
              pos_patterns: string | undefined,
              dimension: string | undefined,
              classname: string | undefined,
              subset: string | undefined,
              graph_dimensions: string | undefined,
              graph_nodes: string | undefined,
              sort_by_class_score: string | undefined,
              random: boolean | undefined,
              non_verified_dimension: string | undefined,
              page: number | undefined,
            ): Promise<VerbatimPaginated> => {
              return client.project.getVerbatimsProjectVerbatimsProjectIdGet(
                project_id,
                lemmas,
                dimension,
                classname,
                subset,
                graph_dimensions,
                graph_nodes,
                sort_by_class_score,
                !!random,
                non_verified_dimension,
                page,
              );
            },
            verbatims_as_csv: async (
              project_id: string,
              lemmas: string | undefined,
              pos_patterns: string | undefined,
              dimension: string | undefined,
              classname: string | undefined,
              subset: string | undefined,
              graph_dimensions: string | undefined,
              graph_nodes: string | undefined,
              sort_by_class_score: string | undefined
            ) => {
              let toastId = notificationInfo(
                "Votre fichier est en cours de préparation.",
                { duration: 500000 }
              );
              const data =
                await client.project.getVerbatimsCsvProjectVerbatimsProjectIdCsvGet(
                  project_id,
                  lemmas,
                  dimension,
                  classname,
                  subset,
                  graph_dimensions,
                  graph_nodes,
                  sort_by_class_score
                );
              downloadAsCsv(data, `export`);
              notificationDismiss(toastId);
            },

            download_train_dataset: async (
              project_id: string,
              dimension: string
            ) => {
              client.project
                .getVerbatimsTrainDataProjectVerbatimsProjectIdTrainDataJsonlGet(
                  project_id,
                  dimension
                )
                .then((response) => {
                  downloadAsJsonl(response, `train_dataset_${dimension}.jsonl`);
                })
                .catch((error) => {
                  alert(error);
                });
            },

            download_dimension_distribution: async (
              project_id: string,
              dimension: string,
              group: "by_class" | "by_tag" | "raw",
              filter: ProjectFilter
            ) => {
              let notificationId = notificationInfo(
                "Votre fichier est en cours de préparation.",
                { duration: 500000 }
              );
              if (group === "by_class") {
                let data =
                  await client.project.getProjectStatCsvProjectProjectIdStatsDimensionDistributionCsvGet(
                        project_id,
                        dimension,
                        projectFilterQueryString(filter)
                  );
                downloadAsCsv(data, `distribution_${dimension}.csv`);
              } else if (group === "raw") {
                  let data =
                  await client.project.getDimensionTagsCsvProjectProjectIdStatsDimensionTagsCsvGet(
                    project_id,
                    dimension,
                      projectFilterQueryString(filter)
                  );
                downloadAsCsv(data, `tags_${dimension}.csv`);
              } else {
                let data =
                  await client.project.getDimensionMatchesDistributionCsvProjectProjectIdStatsDimensionCsvGet(
                    project_id,
                    dimension,
                      projectFilterQueryString(filter)
                  );
                downloadAsCsv(data, `distribution_${dimension}.csv`);
              }
              notificationDismiss(notificationId);
            },

            trigger_preset: async (project_id: string, preset_id: string) => {
              return client.project.runPresetProjectProjectIdRunPresetPresetIdPost(
                project_id,
                preset_id
              );
            },

            get_project_patterns: async (
              project_id: string,
              pos_patterns: POS[][] | undefined,
              term: string | undefined,
              min_occurrences: number,
              max_occurrences: number,
              page: number
            ) => {
              return client.project.getProjectPatternsProjectProjectIdPatternsGet(
                project_id,
                JSON.stringify(pos_patterns),
                term,
                min_occurrences,
                max_occurrences,
                page
              );
            },

            get_project_patterns_csv: async (
              project_id: string,
              pos_patterns: POS[][] | undefined,
              term: string | undefined
            ) => {
              let toastId = notificationInfo(
                "Votre fichier est en cours de préparation.",
                { duration: 500000 }
              );
              const data =
                await client.project.getProjectPatternsCsvProjectProjectIdPatternsCsvGet(
                  project_id,
                  JSON.stringify(pos_patterns),
                  term
                );
              downloadAsCsv(data as unknown as string, `patterns`);
              notificationDismiss(toastId);
            },

            new_pattern_matching_model: async (
              name: string
            ): Promise<string> => {
              let data =
                await client.patternMatchingModel.newPatterMatchingModelPatternMatchingModelPost(
                  {
                    name,
                    patterns: [],
                  }
                );
              return data.model_id;
            },

            delete_pattern_matching_model: async (
              model_id: string
            ): Promise<any> => {
              return client.patternMatchingModel.deleteModePatternMatchingModelModelIdDelete(
                model_id
              );
            },

            get_pattern_matching_models: async (): Promise<
              Array<PatternMatchingModelProjection>
            > => {
              return client.patternMatchingModel.getModelsPatternMatchingModelGet();
            },

            get_pattern_matching_model: async (
              model_id: string
            ): Promise<PatternMatchingModelProjection> => {
              return client.patternMatchingModel.getModelPatternMatchingModelModelIdGet(
                model_id
              );
            },

            update_pattern_matching_model: async (
              model_id: string,
              name: string,
              patterns: Array<Array<any>>
            ): Promise<any> => {
              return client.patternMatchingModel.updateModelPatternMatchingModelModelIdPost(
                model_id,
                {
                  name,
                  patterns,
                }
              );
            },

            get_model_presets: async (): Promise<Array<ModelPreset>> => {
              return client.project.getModelPresetsProjectPresetsGet();
            },

            delete_verbatim_tag: async (
              project_id: string,
              tag_id: string,
              delete_all: boolean
            ): Promise<any> => {
              return client.project.deleteTagProjectProjectIdTagTagIdDelete(
                project_id,
                tag_id,
                delete_all
              );
            },

            delete_verbatim_tag_by_classname: async (
              project_id: string,
              dimension: string,
              classname: string,
              name: string
            ): Promise<any> => {
              return client.project.deleteTagByClassProjectProjectIdTagDelete(
                project_id,
                classname,
                dimension,
                name
              );
            },
            tag_verbatim_token: async ({
              project_id,
              verbatim_id,
              token_index,
              dimension,
              label,
              generalize,
              rule,
            }: {
              project_id: string;
              token_index: number;
              verbatim_id: string;
              dimension: string;
              label: string;
              generalize: boolean;
              rule: any;
            }): Promise<any> => {
              return client.project.tagAVerbatimTokenProjectProjectIdTagTokenPost(
                {
                  project_id,
                  verbatim_id,
                  token_index,
                  dimension,
                  label,
                  generalize,
                  rule,
                }
              );
            },

            get_project_job: async (
              project_id: string
            ): Promise<Array<JobMonitoringView>> => {
              return client.project.getProjectJobsProjectProjectIdJobsGet(
                project_id
              );
            },

            project_dimension_matches_distribution: async (
              project_id: string,
              dimension: string,
                filter: ProjectFilter
            ): Promise<Array<ProjectDimensionClasseDistribution>> => {
              return client.project.getDimensionMatchesDistributionProjectProjectIdStatsDimensionGet(
                project_id,
                dimension,
                    projectFilterQueryString(filter)
              );
            },

            project_dimension_plot_iframe_url: (
              projectId: string,
              dimension: string,
              type: string,
              color: string,
              nbCategories: number,
              projectFilter: ProjectFilter
            ): string => {
              return `${endpoint_project}${projectId}/stats/${dimension}/plot_${type}.html?color=${color}&nbCategories=${nbCategories}&filter_subset=${projectFilterQueryString(projectFilter)}`;
            },

            project_dimension_wordcloud_image_url: (
              projectId: string,
              dimension: string,
              params: WordCloudParams
            ): string => {
                let classColorsQuery = Object.entries(params.classColors)
                  .map(([key, value]) => `${key}=${value}`)
                  .join('&');
                return `${endpoint_project}${projectId}/stats/${dimension}/wordcloud.svg?` +
                  `font_min=${params.wordCloudFontMin}&` +
                  `font_size_disparity=${params.wordCloudFontDisparity}&` +
                  `class_count=${params.wordcloudClassCount}&` +
                  `words_count=${params.wordcloudClassCount}&` + // Assuming words_count is the same as class_count
                  `width=${params.width}&` +
                  `height=${params.height}&` +
                  `${classColorsQuery}&` +
                    `filter_subset=${projectFilterQueryString(params.filter)}&` +
                  `default_color=${params.color}`;
            },

            project_dimension_classname_wordcloud_image_url: (
              projectId: string,
              dimension: string,
              classname: string
            ): string => {
              return `${endpoint_project}${projectId}/stats/${dimension}/${classname}/lexique/wordcloud.svg`;
            },
          }),
        0
      );
    }
  }, [token, getAccessTokenSilently, isAuthenticated, isLoading]);
  return { api, isLoading, isAuthenticated };
};

export const ApiProvider = ({ children }: { children: any }) => {
  const { isLoading, isAuthenticated } = useApi();

  if (isLoading) {
    return (
      <div className="flex bg-gradient-to-r from-gray-100 to-indigo-100 h-screen">
        <div role="status" className="m-auto flex flex-col justify-center">
          <div className="-mt-10 flex justify-center">
            <img src="/padlock.png" className="w-32 h-32" />
          </div>
          <div className="text-center text-indigo-800 font-medium flex flex-col gap-4">
            <div> Authentification</div>
            <Spinner aria-label="Default status example" color="purple" />
          </div>
        </div>
      </div>
    );
  } else {
    if (!isAuthenticated) {
      return <LoginPage />;
    }
    return (
      <div>
        {children} <Toaster position="top-center" reverseOrder={false} />
      </div>
    );
  }
};


